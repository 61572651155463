import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, Layout, message, Space, List, Typography, Avatar } from 'antd'
import { Link } from "react-router-dom";
import axios from 'axios';


import { IconDelete, IconEdit } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";



import Logged from '../../../../Hooks/Logged'
import usePermissions from '../../../../Hooks/usePermissions';
import ModalProductoAlmacen from '../ModalesSecciones/ModalProductoAlmacen';

const { Content } = Layout;
const { Text } = Typography;


export class Productos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            productos: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
            },
            producto_almacen: undefined,
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProductos()
    }


    /**
     * @memberof CardAlmacen
     * 
     * @method getProductos
     * @description Obtiene el listado de productos en el almacen
     */
    getProductos = ({
        page = this.state.productos.page,
        limit = this.state.productos.limit,
    } = this.state.productos) => {

        this.setState({ loading: true})
        axios.get('/almacenes/productos/' + this.props.almacen_id, {
            params: {
                page,
                limit,
            }
        }).then(({ data }) => {
            console.log(data)
            this.setState({
                productos: data,
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <Content className="admin-content content-bg">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Productos en Almacén " }}
                            dataSource={this.state.productos.data}
                            pagination={{
                                current: 1,
                                pageSize: 10,
                                total: 3,
                                position: 'bottom',
                                className: "flex-left",

                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" gutter={[16, 16]}>
                                <Col span={6} className="center">
                                    <Text strong>Producto</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>SKU See Saw</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Categoría</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Subcategoría</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text strong>Cantidad</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">

                                    <Card className="card-list">
                                        <Row className="width-100 " gutter={[16, 16]}>

                                            <Col span={6} className="center">
                                                <Link title="Ver detalles" to={`/admin/productos/${item?.producto_id?._id}`} >
                                                    <Text>{item?.producto_id?.nombre}</Text>
                                                </Link>

                                            </Col>
                                            <Col span={4} className="center">
                                                <Text className='ml-1'>{item?.producto_id?.sku}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>{item?.producto_id?.categoria_id?.nombre}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>{item?.producto_id?.sub_categoria_id?.nombre}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text>{`${item.cantidad} ${item?.producto_id?.unidad?.simbolo ? item?.producto_id?.unidad?.simbolo : item?.producto_id?.unidad?.nombre ?? "Unidades"}`}</Text>
                                            </Col>
                                            <Col span={2} align="right">
                                                <Space size="small" direction="horizontal">
                                                    <Button
                                                        type="primary"
                                                        disabled={false}
                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => this.setState({ modal_visible: true, producto_almacen: item._id })}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        disabled={false}
                                                        title="¿Deseas eliminar este almacen?"
                                                        onConfirm={() => axios.delete('/almacenes/productos/' + item._id,
                                                            {}).then(() => {
                                                                this.getProductos()
                                                                this.props.getAlmacen()
                                                                message.success('Producto eliminado del Almacén')
                                                            }).catch(error => {
                                                                message.error('Error al eliminar el Producto')
                                                                console.log(error)
                                                            })}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            disabled={false}
                                                            type="primary"
                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    {this.props.createInventario ? <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} /> : null}

                    <ModalProductoAlmacen
                        visible={this.state.modal_visible}
                        onClose={(flag) => { 
                            this.setState({ modal_visible: false, producto_almacen: undefined });
                            if(flag === true){
                                this.getProductos()
                                this.props.getAlmacen()
                            }
                        }}
                        almacen_id={this.props.almacen_id}
                        producto_almacen_id={this.state.producto_almacen}
                    />

                </Spin>
            </>
        )
    }
}


export default function (props) {

    let user = React.useContext(Logged)
    let permissions = usePermissions(user?.rol_id?.permisos, {
        createInventario: ["almacenes-productos", "create"],
    })


    return <Productos {...props} {...permissions}/>

}