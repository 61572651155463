import React, { Component } from "react";
import { Modal, Form, Input, message, Spin, Select, Divider, Space, Row, Col, InputNumber } from 'antd';
import TextArea from "antd/lib/input/TextArea";
const axios = require('axios').default;
const { Option } = Select

/**
 *
 *
 * @class ModalProductoAlmacen
 * @extends {Component}
 */
class ModalProductoAlmacen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            inventario: {},
            almacen_id: this.props.almacen_id,

            productos: {
                data: [],
                limit: 12,
                page: 1,

                total: 0,
                pages: 0,
                inputValue: null
            },

            almacenes: {
                data: [],
                limit: 12,
                page: 1,

                total: 0,
                pages: 0,
                inputValue: null
            },
        }
    }

    ModalProductoAlmacen = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProductos()

        if (this.props.producto_almacen_id !== undefined) {
            this.getInventario()
        }

        if (this.props.almacen_id == undefined) {
            this.getAlmacenes()
        }

        if (this.props.producto_id !== undefined) {
            this.ModalProductoAlmacen.current.setFieldsValue({
                producto_id: this.props.producto_id,
            })
        }
    }


    /**
     * @memberof ModalProductoAlmacen
     * @method getArea
     * @description Obtiene una area de la DB
     */
    getInventario = () => {
        this.setState({ loading: true })
        axios.get(`/almacenes/producto/${this.props.producto_almacen_id}`, {
            params: {
                id: this.props.producto_almacen_id
            }
        }).then(({ data }) => {
            this.setState({
                inventario: data,
            })

            this.ModalProductoAlmacen.current.setFieldsValue({
                producto_id: data.producto_id,
                cantidad: data.cantidad,
                inventario_maximo: data.inventario_maximo,
                inventario_minimo: data.inventario_minimo,
                locacion: data.locacion,

            })

        }).catch(error => {
            message.error('Error al traer informacion del inventario')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
        * @memberof ModalProductoAlmacen
        * @method addArea
        * @description Añade una area a la BD
        */
    addAlmacen = (values) => {
        axios.post('/almacenes/productos', {
            ...values,
            ...(this.props.almacen_id ? { almacen_id: this.props.almacen_id } : {})
           
        }).then(response => {


            message.success('Producto agregado al Almacén.')
            this.props.onClose(true)


        }).catch(error => {
            console.log(error)
            message.error(error?.response?.data?.message ?? 'Error al agregar producto al Almancén.')
        })
    }

    /**
     * @memberof ModalProductoAlmacen
     * @method updateArea
     * @description Actualiza la información de una area
     */
    updateAlmacen = (values) => {
        this.setState({ loading: true })
        axios.put(`/almacenes/producto/${this.props.producto_almacen_id}`, {
            ...values,

        }).then(response => {
            message.success('Inventario Actualizado')
            this.props.onClose(true)
        }).catch(error => {
            message.error('Error al actualizar inventario')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalProductoAlmacen
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => (this.props.producto_almacen_id) ? this.updateAlmacen(values) : this.addAlmacen(values)

    /**
     * @memberOf ModalProductoAlmacen
     * @method getProyectos
     * @descripcion Obtiene la lista de proyectos
     */
    getProductos = ({
        page = this.state.productos.page,
        limit = this.state.productos.limit,
        search = this.state.productos.search,
    } = this.state.productos) => {
        this.setState({ productos: { ...this.state.productos, page, limit, search, inputValue: null } })
        axios.get('/productos', {
            params: { page, limit, search }
        })
            .then(({ data }) => {
                this.setState({ productos: { ...this.state.productos, ...data } })
            })
            .catch(error => {
                message.error('Error al obtner las los productos')
            })

    }

    /**
     * @memberOf ModalProductoAlmacen
     * @method getProyectos
     * @descripcion Obtiene la lista de proyectos
     */
    getAlmacenes = ({
        page = this.state.almacenes.page,
        limit = this.state.almacenes.limit,
        search = this.state.almacenes.search,
    } = this.state.almacenes) => {
        this.setState({ almacenes: { ...this.state.almacenes, page, limit, search, inputValue: null } })
        axios.get('/almacenes', {
            params: { page, limit, search }
        })
            .then(({ data }) => {
                this.setState({ almacenes: { ...this.state.almacenes, ...data } })
            })
            .catch(error => {
                message.error('Error al obtner las los almacenes')
            })

    }



    render() {
        return (
            <Form
                layout="vertical"
                name="form-areas"
                ref={this.ModalProductoAlmacen}
                onFinish={this.onFinish}
                className="pd-1"
            >
                <Spin spinning={this.state.loading}>
                    {(this.props.almacen_id || this.props.producto_almacen_id) ? null :
                        <Form.Item
                            label="Almacen"
                            name="almacen_id"
                        >
                            <Select
                                placeholder="Almacen"
                                className="form-select select-actions"
                                onSearch={search => this.getAlmacenes({ search })}
                                showSearch
                                filterOption={false}

                                optionLabelProp="label"
                            >
                                {this.state.almacenes.data?.map((almacen, index) => {


                                    return <Option
                                        className="option-actions "
                                        key={almacen._id}
                                        value={almacen._id}
                                        label={almacen.nombre}

                                    >
                                        {almacen?.nombre}
                                    </Option>
                                })}
                            </Select>
                        </Form.Item>
                    }
                    <Form.Item
                        label="Producto"
                        name="producto_id"
                    >
                        <Select
                            placeholder="Producto"
                            className="form-select select-actions"

                            disabled={this.props.producto_id ? true : false}
                            onSearch={search => this.getProductos({ search })}
                            showSearch
                            filterOption={false}

                            optionLabelProp="label"
                        >
                            {this.state.productos.data?.map((producto, index) => {


                                return <Option
                                    className="option-actions "
                                    key={producto._id}
                                    value={producto._id}
                                    label={producto.nombre}

                                >
                                    {producto?.nombre}
                                </Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Cantidad en el almacén"
                        name="cantidad"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese la cantidad disponible"
                        }]}
                    >
                        <InputNumber className="width-100" placeholder="Cantidad" controls={false}></InputNumber>
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item
                                label="Cantidad Mínima"
                                name="inventario_minimo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la cantidad mínima"
                                }]}
                            >
                                <InputNumber className="width-100" placeholder="Cantidad Minima" controls={false}></InputNumber>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Cantidad Máxima"
                                name="inventario_maximo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la cantidad máxima"
                                }]}
                            >
                                <InputNumber className="width-100" placeholder="Cantidad Máxima" controls={false}></InputNumber>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label="Ubicación"
                        name="locacion"
                        rules={[{
                            required: true,
                            message: "Por favor, escriba donde se encuentra el producto"
                        }]}
                    >
                        <TextArea />
                    </Form.Item>

                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, producto_almacen_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={producto_almacen_id ? "Editar Producto en Almacén" : "Agregar Producto al Almacén"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-areas', key: 'submit', htmlType: 'submit' }}
    >
        <ModalProductoAlmacen {...props} />
    </Modal>

}