import React from 'react'
import { Route, Routes } from "react-router-dom";

import Empleados from '../components/Admin/Empleados/Empleados'
import DetallesEmpleados from '../components/Admin/Empleados/DetallesEmpleados'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterEmpleados(props) {
    return (
        <Routes>
            <Route path="" element={<Empleados {...props} />} />
            <Route path="/:id" element={<DetallesEmpleados {...props} />} />
        </Routes>
    )
}

export default RouterEmpleados;
