import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Breadcrumb, Typography, Space, List } from 'antd'
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconArrowBack, IconDelete, IconEdit } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalCategorias from './ModalSubCategorias';

import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Rubros
 * @extends {Component}
 * @description Vista de Rubros
 */
class SubCategorias extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,

            categoria_id: this.props.params.categoria_id,
            subcategoria_id: undefined,
            subcategorias: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0
            },
            categoria: undefined,

            page: 1,
            total: 0,
        }
    }

    componentDidMount() {

        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        //this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCategoria();
        this.getSubCategorias();
    }

    /**
         *
         * @memberof Cuentas
         * @method componentDidUpdate
         * @description Si se realiza una busqueda, filtra las Cuentas
         */
    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search)
            this.getSubCategorias()
    }

    /**
     * @memberof SubCategorias
     * @method getCategoria
     * @description Obtiene una categoria de la DB
     */
    getCategoria = () => {
        axios.get(`/categorias/${this.state.categoria_id}`, {
            params: {
                id: this.state.categoria_id
            }
        }).then(response => {

            let categoria = response.data
            this.setState({
                categoria
            })


        }).catch(error => {
            message.error('Error al traer el categoria')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof SubCategorias
     * @method getSubCategorias
     * @description Obtiene las subcategorias relacionados a la categoria
     */
    getSubCategorias = ({
        page = this.state.subcategorias.page,
        limit = this.state.subcategorias.limit,
        search = this.props.search
    } = this.state.subcategorias) => {

        axios.get('/sub-categorias', {
            params: {
                page: page,
                limit: limit,
                search: search,
                categoria_id: this.state.categoria_id,
            }
        }).then(({ data }) => {
            this.setState({
                subcategorias: data
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }


    render() {

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        backIcon={<IconArrowBack />}
                        onBack={(e) => this.props.navigate(-1)}
                        title={`Subcategorias de ${this.state.categoria?.nombre ?? "-"}`}
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Sub Categorias " }}
                            dataSource={this.state.subcategorias.data}
                            pagination={{
                                current: this.state.subcategorias.page,
                                pageSize: this.state.subcategorias.limit,
                                total: this.state.subcategorias.total,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page) => this.getSubCategorias({ page })
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" gutter={[16, 16]} >

                                <Col xs={24} lg={10} className="center">
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col xs={24} lg={10} className="center">
                                    <Text strong>Código</Text>
                                </Col>
                                <Col xs={24} lg={4} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 " gutter={[16, 16]}>

                                            <Col xs={24} lg={10} className="center">
                                                <Text>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={24} lg={10} className="center">
                                                <Text>{item.codigo}</Text>
                                            </Col>
                                            <Col xs={24} lg={4} className="center">
                                                <Space size="small" direction="horizontal">
                                                    <Button
                                                        type="primary"

                                                        icon={<IconEdit />}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modal_visible: true, subcategoria_id: item._id }) }}
                                                    />
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar esta sub categoria?"

                                                        onConfirm={() => axios.delete(`/sub-categorias/${item._id}`,
                                                        ).then(() => {
                                                            this.getSubCategorias()
                                                            message.success('Sub categoría eliminado')
                                                        }).catch(error => message.error(error?.response?.data?.message ?? "Error al eliminar"))}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="primary"

                                                            icon={<IconDelete />}
                                                            title="Eliminar"
                                                            danger
                                                        />
                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>
                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />

                    <ModalCategorias
                        visible={this.state.modal_visible}
                        onClose={() => {
                            this.setState({ modal_visible: false, subcategoria_id: undefined })
                            this.getSubCategorias()
                        }}
                        subcategoria_id={this.state.subcategoria_id}
                        categoria_id={this.state.categoria_id}
                    />
                </Spin>
            </>
        )
    }

}

export default function Vista(props) {

    /* let user = React.useContext(Logged) */


    /* let permissions = usePermissions(user?.rol_id?.permisos, {
        editRubros: ["areas-rubros", "edit"],
        createRubros: ["areas-rubros", "create"],
        deleteRubros: ["areas-rubros", "delete"],

    }) */

    return <SubCategorias {...props} params={useParams()} navigate={useNavigate()} />

}