import React, { Component } from "react";
import { Modal, Form, Input, message, Spin } from 'antd';
const axios = require('axios').default;


/**
 *
 *
 * @class ModalAreas
 * @extends {Component}
 */
class ModalAreas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    ModalAreas = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.area_id !== undefined) {
            this.getArea()
        }
    }


    /**
     * @memberof ModalAreas
     * @method getArea
     * @description Obtiene una area de la DB
     */
    getArea = () => {
        this.setState({ loading: true })
        axios.get(`/areas/${this.props.area_id}`, {
            params: {
                id: this.props.area_id
            }
        }).then(({ data }) => {
            let area = data.area;
            this.setState({
                area: data.area,
            })

            this.ModalAreas.current.setFieldsValue({
                nombre: area.nombre,
                descripcion: area.descripcion,
            })

        }).catch(error => {
            message.error('Error al traer área')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
        * @memberof ModalAreas
        * @method addArea
        * @description Añade una area a la BD
        */
    addArea = (values) => {
        axios.post('/areas', {
            ...values
        }).then(response => {

            if (response.data.success) {
                message.success('Área creada')
                this.props.onClose()
            }

        }).catch(error => {
            console.log(error)
            message.error('Error al crear Área.')
        })
    }

    /**
     * @memberof ModalAreas
     * @method updateArea
     * @description Actualiza la información de una area
     */
    updateArea = (values) => {
        this.setState({ loading: true })
        axios.put('/areas', {
            ...values,
            id: this.props.area_id,
        }).then(response => {
            message.success('Área Actualizada')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar Área')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalAreas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => (this.props.area_id) ? this.updateArea(values) : this.addArea(values)



    render() {
        return (
            <Form
                layout="vertical"
                name="form-areas"
                ref={this.ModalAreas}
                onFinish={this.onFinish}
                className="pd-1"
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                    >
                        <Input placeholder="Nombre" ></Input>
                    </Form.Item>
                    <Form.Item
                        label="Descripción"
                        name="descripcion"
                    // rules={[{
                    //     required: true,
                    //     message: "Por favor, ingrese una descipción"
                    // }]}
                    >
                        <Input placeholder="Descripción" ></Input>

                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, area_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={area_id ? "Editar Área" : "Crear Área"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-areas', key: 'submit', htmlType: 'submit' }}
    >
        <ModalAreas {...props} />
    </Modal>

}