import { Statistic } from 'antd'
import React from 'react'

import '../../Styles/Modules/Widgets/multiprogress.scss'

class MultiProgress extends React.Component{

    render(){

        const {
            elements = [],
            max = 100
        } = this.props

        return <div className="progress">
            {elements.map((el, idx) => (
                <div className={`progress-element ${el.value !== 0 ? 'activo' : ''}`} style={{width: `${Math.floor(el.value / max * 100)}%`}}>
                    {
                        (el.value) > (max / 20) && (
                            <Statistic
                                className='progress-value'
                                value={el.value}
                                prefix={"$"}
                                precision={0}
                            />
                        )
                    }
                    <div className={`progress-bar`}>
                    </div>
                </div>
            ))}
        </div>
    }
}

export default MultiProgress