import React, { useState, useEffect } from 'react';
import { Form, Row, Col, InputNumber, Typography, Upload, Button, Popover, Switch } from 'antd';
import { UploadOutlined, CheckOutlined } from '@ant-design/icons';
import { BsCheckLg } from 'react-icons/bs';

import axios from 'axios';
const { Text } = Typography;
/**
 * @const FormRequisicionOpcionAceptada
 * @description Componente que contiene el formualario para editar las requisiciones opciones de una requisicion, que ya ha sido aceptada
 * para los estatus de (Cotizado, Pausado y Cancelado)
 */
const FormRequisicionOpcionAceptada = (props) => {

	let [data, setData] = useState({
        requisicion_opcion_id: props._id,
        monto_total: props.monto_total,
        precio_unitario: props.precio_unitario,
        volumen_final: props.volumen_final,
        dias_entrega: props.dias_entrega,
        costo_envio: props.costo_envio,
        proveedor_id: props.proveedor_id,
        aceptada: props.aceptada,
        archivos: props.archivos?.length > 0 ? props.archivos : [],
    })

    const handleChange = (values) => {


        let monto_total = 0,
            costo_envio = data.costo_envio ?? 0

        monto_total = (values.precio_unitario * values.volumen_final) + costo_envio

        setData({
            ...data,
            ...values,
            monto_total,
        })

        props.update({
            ...data,
            ...values,
            monto_total,
        })

    }


	return (
		<Form
            className="form-requisicion-opcion"
            initialValues={{...data}}
            onValuesChange={(value, allValues)=>{
                handleChange(allValues)
            }}                                          
        >
            <Row>
                <Col span={1} className="center">
                    {data.aceptada ? <CheckOutlined style={{color: "green"}}/> : null}
                </Col>
                <Col span={4} className="center">
                    <Text>{data.proveedor_id?.alias}</Text>
                </Col>
                <Col span={3} className="">
                    <Form.Item 
                        name="precio_unitario"
                        rules={[
                            {
                                min: 1,
                                type: "number", 
                                message: 'Valor no valido'
                            }
                        ]}
                    >
                        <InputNumber
                            bordered={false}
                            placeholder="Precio Unitario"
                            prefix="$"
                            precision={2}
                            min={1}
                        />
                    </Form.Item>
                </Col>
                <Col span={3} className="">
                    <Form.Item name="dias_entrega">
                        <InputNumber
                            className="w100"
                            bordered={false}
                            placeholder="Dias Entrega"
                            min={0}
                        />
                    </Form.Item>
                </Col>
                <Col span={3} className="">
                    <Form.Item name="volumen_final">
                        <InputNumber
                            className="w100"
                            bordered={false}
                            placeholder="Volumen"
                            precision={0}
                            min={1}
                        />
                    </Form.Item>
                </Col>
                <Col span={3} className="center">
                    <Text>$ {data.monto_total.toMoney(true)} MXN</Text>
                </Col>
                <Col span={3} className="center">
                    <Popover placement="topRight"
                        content={<div className="flex-left-column">
                            {data.archivos.length === 0 ? <Text className="text-gray">Sin Archivos</Text> : null}
                            {data.archivos.map(arch => <a target="_blank" 
                                href={axios.defaults.baseURL + "/requisicion-opcion/" + props._id + "?archivo=" + arch.file +"&Authorization=" + sessionStorage.getItem("token")}>{arch.name}
                            </a>)}
                        </div>}
                        trigger="click"
                    >
                        <Text className="text-blue hover">{data.archivos.length === 0 ? "Sin Archivos" : "Ver Archivos"}</Text>
                    </Popover>
                </Col>
                <Col span={2} className="">
                    <Form.Item 
                    	name="credito"
                    	valuePropName="checked"
                    >
          				<Switch/>
        			</Form.Item>
                </Col>

                <Col span={2} className="center">
                    
                    <Button 
                        className="center" 
                        size='small' 
                        type="primary" 
                        icon={<BsCheckLg />} 
                        onClick={()=>props.openModalCotizacion({requisicion_opcion_id: data.requisicion_opcion_id})}
                    />

                </Col>
            </Row>
        </Form>
	)

}


export default FormRequisicionOpcionAceptada;