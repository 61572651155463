import { CheckCircleOutlined, ClockCircleOutlined, CloseOutlined, DeleteOutlined, DollarCircleFilled, DollarCircleOutlined, EditOutlined, EyeOutlined, InboxOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Col, Empty, Popconfirm, Result, Row, Space, Spin, Tag, Typography, message, Tabs, Tooltip } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useContext } from 'react'
import { BiEnvelope } from 'react-icons/bi'
import { BsReceipt } from 'react-icons/bs'
import TransaccionesTab from './Secciones/TransaccionesTab'
import usePermissions from '../../../../Hooks/usePermissions'
import User from '../../../../Hooks/Logged'
import { useLocation } from 'react-router-dom'
import Decimal from 'decimal.js'
import TransaccionesProgramadasTab from './Secciones/TransaccionesProgramadasTab'
import { ChildButton, FloatingMenu, MainButton } from 'react-floating-button-menu'
import { FaRegHandshake } from 'react-icons/fa'
import ModalPropiedades from './ModalPropiedades'
import ModalTransaccion from '../../Finanzas/Transacciones/ModalTransaccion'
import ModalTransaccionProgramada from '../../Finanzas/Transacciones/ModalTransaccionProgramada'
import ModalTransaccionDetalle from '../../Finanzas/Transacciones/ModalTransaccionDetalle'

const { Paragraph, Title } = Typography
const { TabPane } = Tabs;

class DetallesPropiedad extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            propiedad: {},
            activeKey: "transacciones",
            loading: false
        }
    }

    componentDidMount = () => {
        this.get()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.propiedad_id !== this.props.propiedad_id) {
            this.get()
        }
    }

    get = ({
        propiedad_id
    } = this.props) => {
        this.setState({ loading: true })
        axios.get('/propiedades/' + propiedad_id)
            .then(({ data }) => {
                this.setState({
                    propiedad: data
                })
            })
            .catch(error => {
                message.error(error?.response.data ?? "No se pudo obtener la propiedad")
            })
            .finally(() => this.setState({ loading: false }))

    }

    removePropiedad = propiedad_id => {
        this.setState({ loading: true })
        axios.delete('/propiedades/' + propiedad_id)
            .then(({ data }) => {
                this.props.updateAll({ propiedad_id })
            })
            .catch(error => {
                message.error(error?.response.data ?? "No se pudo obtener la propiedad")
            })
            .finally(() => this.setState({ loading: false }))

    }

    renderEstatus = (tipo) => {
        switch (tipo) {
            case 0:
                return <Tag icon={<CloseOutlined style={{ color: "currentColor" }} />} color="#F50">Cancelada</Tag>
            case 1:
                return <Tag icon={<ClockCircleOutlined style={{ color: "currentColor" }} />} color="#FBBC30">Apartada</Tag>
            case 2:
                return <Tag icon={<DollarCircleOutlined style={{ color: "currentColor" }} />} color="#2db7f5">En Proceso de Pago</Tag>
            case 3:
                return <Tag icon={<CheckCircleOutlined style={{ color: "currentColor" }} />} color="#87d068">Pagada</Tag>
            default:
                return null
        }
    }

    render() {
        const {
            _id,
            proyecto_id,
            inmueble_id,
            area_id,
            rubro_id,
            fecha_venta,
            asesor_id,
            monto_venta,
            monto_total,
            monto_pagado,
            monto_pendiente,
            monto_pendiente_transaccion_programada,
            adicionales,
            estatus
        } = this.state.propiedad

        return <>
            <Spin
                style={{ width: "100%" }}
                size={"large"}
                indicator={<LoadingOutlined spin />}
                spinning={this.state.loading}
            >
                <Col span={24}>
                    <Card bordered>
                        <Row align="middle" className='mb-1' gutter={[12, 12]} justify="space-between">
                            <Col>
                                <Title level={4} >Información de la Propiedad</Title>
                            </Col>
                            <Col >
                                <Space>
                                    <Tooltip title="Enviar el estado de cuenta">
                                        <Button
                                            className='ant-btn-primary-green'
                                            type='primary'
                                            icon={<BiEnvelope style={{ color: "currentcolor" }} />}
                                            onClick={() => window.open(axios.defaults.baseURL + "/propiedades/estado-cuenta/" + this.props.propiedad_id + "?Authorization=" + sessionStorage.getItem("token"))}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Ver el estado de cuenta">
                                        <Button
                                            className='ant-btn-primary-purple'
                                            type='primary'
                                            icon={<BsReceipt style={{ color: "currentcolor" }} />}
                                            onClick={() => window.open(axios.defaults.baseURL + "/propiedades/estado-cuenta/" + this.props.propiedad_id + "?Authorization=" + sessionStorage.getItem("token"))}
                                        />
                                    </Tooltip>
                                    <Tooltip  title="Editar la propiedad">
                                        <Button disabled={!this.props.editarPropiedades} type='primary' icon={<EditOutlined style={{ color: "currentcolor" }} />} onClick={() => this.setState({ visibleModalPropiedades: true })} />
                                    </Tooltip>
                                    <Popconfirm
                                        placement="topRight"
                                        title="¿Deseas eliminar esta Propiedad?"
                                        onConfirm={() => this.removePropiedad(_id)}
                                        okText="Si"
                                        cancelText="No"
                                        disabled={!this.props.eliminarPropiedades}
                                    >
                                        <Tooltip  title="Eliminar la propiedad">
                                            <Button disabled={!this.props.eliminarPropiedades} danger type='primary' icon={<DeleteOutlined style={{ color: "currentcolor" }} />} />
                                        </Tooltip>
                                    </Popconfirm>

                                </Space>
                            </Col>
                        </Row>
                        <Row gutter={[16, 28]}>
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Proyecto</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{proyecto_id?.nombre}</Paragraph>
                            </Col>
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Inmueble</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{inmueble_id?.nombre}</Paragraph>
                            </Col>
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Estatus</Paragraph>
                                {this.renderEstatus(estatus)}
                            </Col>
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Fecha de Venta</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{moment(fecha_venta).format("LLL")}</Paragraph>
                            </Col>
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Area</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{area_id?.nombre}</Paragraph>
                            </Col>
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Rubro</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{rubro_id?.nombre}</Paragraph>
                            </Col>
                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Asesor</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{asesor_id?.nombre}</Paragraph>
                            </Col>

                            <Col xs={24} lg={12} xl={6}>
                                <Paragraph>Documentos</Paragraph>
                                <Button type="link" style={{ padding: 0, fontSize: 16, height: 'auto' }} icon={<EyeOutlined />} onClick={() => this.setState({ visibleModalPropiedades: true })}>Ver detalles</Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card bordered className='mt-1'>
                        <Row gutter={[16, 28]}>
                            <Col xs={24} lg={12} xl={4}>
                                <Paragraph>Monto de Venta</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>{monto_venta?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Paragraph>
                            </Col>

                            <Col xs={24} lg={12} xl={4}>
                                <Paragraph>Adicionales</Paragraph>
                                <Paragraph strong style={{ fontSize: 16 }}>
                                    {Array.isArray(adicionales) ? adicionales.reduce((previousValue, currentValue) => previousValue.add(Decimal(currentValue.costo ?? 0)), Decimal(0)).toDecimalPlaces(2).toNumber().toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : "$0.00"}
                                    <Button type="link" style={{ padding: 0, fontSize: 16, height: 'auto' }} icon={<EyeOutlined />} onClick={() => this.setState({ visibleModalPropiedades: true })}></Button>
                                </Paragraph>
                            </Col>

                            <Col xs={24} lg={12} xl={4}>
                                <Paragraph>Total de la Venta</Paragraph>
                                <Paragraph strong style={{ fontSize: 16, color: "blue" }}>{monto_total?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Paragraph>
                            </Col>

                            <Col xs={24} lg={12} xl={4}>
                                <Paragraph>Monto Pagado</Paragraph>
                                <Paragraph strong style={{ fontSize: 16, color: "lightgreen" }}>{monto_pagado?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Paragraph>
                            </Col>

                            <Col xs={24} lg={12} xl={4}>
                                <Paragraph>Monto Pendiente</Paragraph>
                                <Paragraph strong style={{ fontSize: 16, color: "red" }}>{monto_pendiente?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Paragraph>
                            </Col>
                            <Col xs={24} lg={12} xl={4}>
                                <Paragraph>Pendiete por Pagar</Paragraph>
                                <Paragraph strong style={{ fontSize: 16, color: "red" }}>{this.state.propiedad?.total_vencido?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Paragraph>
                            </Col>

                        </Row>
                    </Card>
                </Col>



            </Spin>

            <ModalPropiedades
                visible={this.state.visibleModalPropiedades}
                onClose={(flag) => {
                    this.setState({ visibleModalPropiedades: false })
                    this.get()
                    this.props.updateAll()
                }}
                propiedad_id={this.props.propiedad_id}
            />

        </>
    }
}

export default React.forwardRef((props, ref) => {

    const location = useLocation()
    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarPropiedades: ["clientes", "propiedades", "edit"],
        eliminarPropiedades: ["clientes", "propiedades", "delete"]
    });


    return <DetallesPropiedad ref={ref} {...props} location={location} user={user} {...permisos} />
})