import React from 'react';
import { Row, Col, Button, Popconfirm, PageHeader, message, Space, List, Card, Typography, Collapse, Modal, Empty } from 'antd'
import axios from 'axios';
import { Link, useSearchParams } from "react-router-dom"
import { Content } from 'antd/lib/layout/layout';

// Componentes
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { IconDelete, IconEdit, IconDetails } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import Logged from '../../../../Hooks/Logged'
import usePermissions from '../../../../Hooks/usePermissions';

// Css
import '../../../../Styles/Modules/CRM/Clientes.scss';
import ModalClientes from './ModalClientes';

// import usePermissions from '../../../../Hooks/usePermissions';
// import Logged from '../../../../Hooks/Logged';
import { EyeOutlined, MinusOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons';
import Propiedades from './Propiedades';

const { Text } = Typography;
const { Panel } = Collapse

/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Componente del listado de transacciones
 */
class Clientes extends React.Component {

	constructor(props) {
		super(props)

		// console.log("asda", this.props.searchParams.get("search"))
		// console.log("page", this.props.searchParams.get("page"))
		// console.log("limit", this.props.searchParams.get("limit"))
		this.state = {
			modalVisible: false,

			cliente_id: undefined,

			clientes: {
				data: [],

				limit: this.props.searchParams.get("limit") ?? 10,

				total: 0,
				pages: 0,

				page: this.props.searchParams.get("page") ?? 1,
				search: this.props.searchParams.get("search") ?? null,
			},

			minHeight: 0
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getClientes();
	}

	/**
	 *
	 *
	 * @memberof Clientes
	 * 
	 * @method getClientes
	 * @description Obtiene los Clientes
	 */
	getClientes = ({
		page = this.state.clientes.page,
		limit = this.state.clientes.limit,
		search = this.props.search,
	} = this.state.clientes
	) => {
		this.setState({ clientes: { ...this.state.clientes, page, limit, search } })
		axios.get('/clientes', {
			params: { page, limit, search }
		}).then(({ data }) => {

			this.props.setSearchParams({ page, limit })
			this.setState(state => {
				state.clientes.data = data.data
				state.clientes.total = data.total
				state.clientes.page = data.page
				return state;
			})
		}).catch(error => {
			console.log(error)
			message.error('Error al traer la información')
		}).finally(() => this.setState({ loading: false }))
	}

	render() {

		return (
			<>
				<PageHeader
					className="site-page-header custom-page-header"
					title="Clientes"
				/>
				<Content className="admin-content content-bg pd-1">
					<Row className='parent clientes' gutter={20} >
						<Col
							style={{ minHeight: this.state.minHeight }}
							className='custom-colum'
							span={24}
						>
							<List
								itemLayout="horizontal"
								dataSource={this.state.clientes.data}
								key={(item => item._id)}
								className='component-list w-100'
								locale={{ emptyText: "Sin Cliente" }}
								pagination={{
									current: this.state.clientes.page,
									pageSize: this.state.clientes.limit,
									total: this.state.clientes.total,
									showSizeChanger: true,
									// hideOnSinglePage: true,

									position: 'bottom',
									className: "flex-left",
									onChange: (page, limit) => this.getClientes({ page, limit })

								}}
								header={
									<Row className='header-list width-100' style={{ padding: "0 1rem 0 3rem " }} gutter={[16, 16]}>
										<Col xs={24} lg={10} >
											<Text strong>Nombre</Text>
										</Col>
										<Col xs={24} lg={10} >
											<Text strong>RFC</Text>
										</Col>
										<Col xs={24} lg={4} className='center'>

										</Col>
									</Row>
								}
								renderItem={item => (
									<Collapse
										bordered={false}
										expandIcon={({ isActive }) => isActive ? <MinusOutlined style={{ marginTop: 8, fontSize: 16 }} /> :
											<PlusOutlined style={{ marginTop: 8, fontSize: 16 }} />}
										className=""
										// activeKey={}
									>
										<Panel
											// collapsible={(!this.props.verPropiedades) ? "disabled": undefined}
											className="collapse-list"
											extra={
												<Row className='width-100' align='middle' gutter={[16, 16]}>
													<Col xs={24} lg={10}>
														<Space size={12}>
															<CustomAvatar

																height={42}
																width={107}
																placeholder={true}
																url={item.logo ? {
																	url: axios.defaults.baseURL + '/clientes/'+item._id,
																	logo: item.logo?.name
																} : null	}
																name={`${item.nombre} ${item.apellido ?? ''}`}
															/>
															<Text ellipsis>{item.nombre} {item.apellido ? item.apellido : ''}</Text>
														</Space>
													</Col>
													<Col xs={24} lg={10} >
														<Text ellipsis>{item.rfc}</Text>
													</Col>
													<Col xs={24} lg={4} className='flex-right'>
														<Space>
															<Link title="Detalles" to={`/admin/crm/clientes/${item._id}`}>
																<Button
																	disabled={!this.props.puedeVerDetalles}
																	className="ant-btn-primary-lime" name={item.nombre} id={item._id}
																	title="Ver Detalles" icon={<EyeOutlined style={{ color: "#FFF" }} />}>
																</Button>
															</Link>


															<Button
																type="primary"
																disabled={!this.props.editClientes}
																icon={<IconEdit />}
																title="Editar"
																onClick={() => { this.setState({ modalVisible: true, cliente_id: item._id }) }}
															/>
															<Button
																type="primary"
																disabled={!this.props.deleteClientes}
																icon={<IconDelete />}
																title="Eliminar"
																onClick={() => {
																	Modal.confirm({
																		title: "¿Desea eliminar al Cliente?",
																		icon: <WarningOutlined />,
																		content: "Todo lo relacionado al cliente será eliminado.",
																		okText: "Eliminar",
																		okButtonProps: {
																			danger: true
																		},
																		cancelText: "Cancelar",
																		onOk: () => axios.delete(`/clientes`, {
																			params: { id: item._id }
																		}).then(() => {
																			this.getClientes()
																			message.success('Cliente eliminado')
																		}).catch(error => console.log(error))
																	})
																}}
																danger
															/>
														</Space>

													</Col>
												</Row>
											}
										>
											{this.props.verPropiedades ? <Propiedades
												cliente_id={item._id}
												{...this.props}
											/> :<Empty description="No tiene permiso para ver propiedades del cliente" />}
											
										</Panel>
									</Collapse>
								)}
							/>
						</Col>

					</Row>
					{this.props.createClientes ? <FloatingButton title="Nuevo registro" onClick={() => {
						this.setState({ modalVisible: true })
					}} /> : null}

				</Content>
				<ModalClientes
					visible={this.state.modalVisible}
					onClose={(flag) => {
						this.setState({ modalVisible: false, cliente_id: undefined })
						this.getClientes()
					}}
					cliente_id={this.state.cliente_id}
				/>
			</>
		)
	}
}

export default function (props) {

	let user = React.useContext(Logged)

	let permissions = usePermissions(user?.rol_id?.permisos, {
		createClientes: ["clientes", "create"],
		editClientes: ["clientes", "edit"],
		deleteClientes: ["clientes", "delete"],

		verPropiedades: ["clientes", "propiedades","read"],
		crearPropiedades: ["clientes", "propiedades","create"],
		editarPropiedades: ["clientes", "propiedades","edit"],
		eliminarPropiedades: ["clientes", "propiedades","delete"],
		
		puedeVerDetalles: ["clientes", "propiedades", "access"],


	})

	let [searchParams, setSearchParams] = useSearchParams();

	return <Clientes
		{...props}
		{...permissions}

		searchParams={searchParams}
		setSearchParams={setSearchParams}

	/>

}