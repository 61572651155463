import React, { Component, useContext } from 'react';
import { Avatar, Spin, PageHeader, Tooltip, Layout, message, Row, Col, Card, Typography, Tabs, Menu, Space, Button, Result, Popconfirm, Tag, Empty, Badge } from 'antd'
import axios from 'axios';

import { FaRegHandshake } from 'react-icons/fa'
import { useParams, useNavigate, useLocation, matchRoutes } from "react-router-dom";


//Componentes
import Logged from '../../../../Hooks/Logged';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import usePermissions from '../../../../Hooks/usePermissions';
import { IconArrowBack } from '../../../Widgets/Iconos';

//Modales






//modal


const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;
const { TabPane } = Tabs;


const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class DetalleClientes
 * @extends {Component}
 * @description Vista de Detalle Clientes
 */
class InformacionGeneral extends Component {

    constructor(props) {
        super(props)
        this.state = {
            empleado: {},

        }
    }



    componentDidMount() {
        this.get()
    }



    /**
     * @memberof DetallesClientes
     * @method get
     * @description Obtiene los detalles y propiedades del cliente
     *
     */
    get = () => {
        this.setState({ loading: true })
        axios.get('/empleados/' + this.props.id)
            .then(({ data }) => {
                this.setState({ empleado: data })
            }).catch(error => {
                message.error('Error al obtener el empleado')
            }).finally(() => this.setState({ loading: false }))
    }


    render() {
        const { empleado, cliente } = this.state


        return (
            <>
                <Spin spinning={false}>


                    <Col span={24}>
                        <Card bordered>
                            <Row align="middle" className='mb-1' gutter={[12, 12]}>
                                <Col>
                                    <Title level={4} >Contacto de Emergencia</Title>
                                </Col>
                            </Row>



                            <Col xs={24} lg={24}>
                                <Row>
                                    {
                                        empleado?.contacto_emergencia_nombre ?
                                            <Col xs={24} lg={12} xl={12}>
                                                <Paragraph>Contacto de Emergencia</Paragraph>
                                                <Paragraph strong style={{ fontSize: 16 }}>{empleado?.contacto_emergencia_nombre}</Paragraph>
                                            </Col> : null
                                    }

                                    {
                                        empleado?.contacto_emergencia_parentesco ?
                                            <Col xs={24} lg={12} xl={12}>
                                                <Paragraph>Relación o Parentesco</Paragraph>
                                                <Paragraph strong style={{ fontSize: 16 }}>{empleado?.contacto_emergencia_parentesco}</Paragraph>
                                            </Col> : null
                                    }
                                    {
                                        empleado?.contacto_emergencia_telefono ?
                                            <Col xs={24} lg={12} xl={12}>
                                                <Paragraph>Número de Contacto de Emergencia</Paragraph>
                                                <Paragraph strong style={{ fontSize: 16 }}>{empleado?.contacto_emergencia_telefono}</Paragraph>
                                            </Col> : null
                                    }
                                    
                                
                                </Row>
                            </Col>



                        </Card>
                    </Col>

                </Spin>
            </>
        )
    }
}

export default function (props) {


    const user = useContext(Logged);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        crearPropiedades: ["clientes", "propiedades", "create"],

        verTransaccionesPropiedad: ["clientes", "propiedades", "transacciones", "access"],
        crearTransaccionesPropiedad: ["clientes", "propiedades", "transacciones", "create"],

        verTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "access"],
        crearTransaccionesProgramadasPropiedad: ["clientes", "propiedades", "transacciones-programadas", "create"],
    });

    return <InformacionGeneral

        {...permisos}
        {...props}
    />
}