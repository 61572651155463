import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, DatePicker, InputNumber, Select, message, Spin, Checkbox } from 'antd';


const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');

class ModalClasificarTransacciones extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            areas: [],
            rubros: [],
            cuentas: [],
            tags: [],

            clientes: {
				data: [],
				
				page: 1, 
				limit: 10,

				total: 0,
				pages: 0, 

				search: null
			},

            proveedores: {
				data: [],
				
				page: 1, 
				limit: 10,

				total: 0,
				pages: 0, 

				search: null
			},

            tipo_cambio: 19.5,
            cuentaSeleccionada: '',
            countSeleccionadas: 0,

            cliente_id: undefined,
            orden_id: undefined,
            orden_compra_id: undefined
        }
    }

    ModalTransacciones = React.createRef();

    /**
    * @memberof ModalClasificarTransacciones
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {

        this.getClientes();
        this.getProveedores();
        this.setState({ countSeleccionadas: Object.keys(this.props.seleccionadas).length })

    }

    /**
     * @memberof ModalTransacciones
     * @method getClientes
     * @description Trae los negocios
     * 
     */
     getClientes = (search) => {
        axios.get('/clientes', {
            params: { page: 1, limit: 10, search }
        }).then(({ data }) => {
            this.setState(state => {
                state.clientes.data = data.data
                state.clientes.total = data.total
                state.clientes.page = data.page

                return state;
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalTransacciones
     * @method getClientes
     * @description Trae los negocios
     * 
     */
     getProveedores = (search) => {
        axios.get('/proveedores', {
            params: { page: 1, limit: 10, search }
        }).then(({ data }) => {
            this.setState(state => {
                state.proveedores.data = data.data
                state.proveedores.total = data.total
                state.proveedores.page = data.page

                return state;
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberof ModalTransacciones
    * @method getAreas
    * @description Trae las areasm las pone en el formato adecuado para el cascader
    * 
    */
    getAreas = (search) => {
        return axios.get('/areas/list', {
            params: {
                search,
                paginate: false
            }
        }).then(response => {
            this.setState({
                areas: response.data.data.map(area => ({
                    value: area._id, label: area.nombre, isLeaf: false
                }))
            })
        }).catch(error => {
            console.log(error)
        })
    }

    /**
    * @memberof ModalTransacciones
    * @method getTipoCambio
    * @description Trae el ulitmo tipo de cambio utilizado 
    * 
    */
    getTipoCambio = () => {
        axios.get('/transacciones/tipo-cambio').then(response => {
            this.ModalTransacciones.current?.setFieldsValue({
                tipo_cambio: response.data.data.toMoney()
            })

        }).catch(error => {
            console.log(error);
            message.error('Error al traer tipo de cambio')
        })
    }

    /**
     * @memberof ModalTransacciones
     * @method onChangeTipo
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares, usando el nuevo tipo de cambio
     */
    onChangeTipo = (value) => {
        this.setState({ tipo_cambio: value?.toMoney() })
    }

    /**
     * @memberof ModalTransacciones
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    /**
     * @memberof ModalClasificarTransacciones
     * @method onFinish
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    onFinish = (values) => {
        if (this.state.loading === true) return
        this.state.loading = true


        this.setState({ loading: true })
        const { seleccionadas } = this.props;


        console.log('values', values)

        axios.post('/syncfy/facturas/add', {
            proveedor_id: values.proveedor_id,
            orden_id: values.orden_id,
            cliente_id: values.cliente_id,
            orden_compra_id: values.orden_compra_id,
            credito: values.credito,
            fecha_vigencia: values.fecha_vigencia,
            empresa_id: this.props.empresa_id,
            tipo_cambio: values.tipo_cambio.toMoney(),
            facturas: Object.values(seleccionadas)
        })
            .then(({ data }) => {
                this.setState({ loading: false })
                this.props.onClose()
                this.props.onClear()
            })
            .catch(() => {
                message.error("No fue posible guardar, por favor contacte a soporte.")
            }).finally(()=>this.setState({loading: false}))
    }


    /**
    * @memberof ModalClasificarTransacciones
    *
    * @method   onPopupScrollCliente
    * @description  Carga mas clientes al hacer scroll
    *
    **/
    onPopupScrollCliente = event => {
        if ((event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight)){
            this.setState({pageCliente: this.state.pageCliente + 1},()=>this.getClientes(null, true))
        }
    }

    /**
    * @memberof ModalClasificarTransacciones
    *
    * @method   onPopupScrollProveedores
    * @description  Carga mas proveedores al hacer scroll
    *
    **/
    onPopupScrollProveedores = event => {
        if ((event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight)){
            this.setState({pageProveedor: this.state.pageProveedor + 1},()=>this.getProveedores(null, true))
        }
    }

    /**
    * @memberof ModalClasificarTransacciones
    *
    * @method   onPopupScrollOrdenes
    * @description  Carga mas proveedores al hacer scroll
    *
    **/
    onPopupScrollOrdenes = event => {
        if ((event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight)){
            this.setState({pageOrdenes: this.state.pageOrdenes + 1},()=>this.getOrdenes(null, true))
        }
    }

    /**
    * @memberof ModalClasificarTransacciones
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElement) => {

        let arr = [];
        if (array) {
            array.forEach(function ({ _id, nombre, apellido,razon_social, folio }, index) {
                arr.push(<Option value={_id}>{nombre} {apellido} {razon_social} {folio}</Option>)
            })
        }

        if (array && addElement) {
            if (!array.some(element => element._id === addElement._id)) {
                arr.push(<Option value={addElement._id}> {addElement.nombre}{addElement.apellido || ""}{addElement.razon_social} {addElement.folio}</Option>)
            }
        }
        return arr
    }


    render() {


        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalTransacciones}
                onFinish={this.onFinish}
                initialValues={{
                    fecha: moment(),
                    tipo_cambio: this.state.tipo_cambio
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                                rules={[{
                                    required: false,
                                    message: "Por favor, seleccione el Cliente"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione el Cliente"
                                    showSearch
                                    onSearch={this.getClientes}
                                    allowClear
                                    filterOption={false}
                                >
                                    {this.selectOptions(this.state.clientes.data)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                                rules={[{
                                    required: false,
                                    message: "Por favor, seleccione el Proveedor"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione el Proveedor"
                                    showSearch
                                    onSearch={this.getProveedores}
                                    allowClear
                                    filterOption={false}
                                >
                                    {this.selectOptions(this.state.proveedores.data)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Fecha de vigencia"
                                name="fecha_vigencia"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>
                        </Col>
                        
{/*                         <Col xs={24} lg={12}>
                            <Form.Item
                                valuePropName="checked"
                                label="Linea de Credito"
                                name="credito"
                            >
                                <Checkbox onChange={e => this.setState({ uso_credito: e.target.checked })}></Checkbox>
                            </Form.Item>
                        </Col>
                        {!this.state.uso_credito ? <Col  xs={24} lg={12}>
                            <Form.Item
                            label="Fecha de Vencimiento"
                            name="fecha_vigencia" //<--------------------------------------- fecha_vigencia
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese la fecha de monto"
                            }]}
                            style={{ width: '100%' }}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                        </Col> : null} */}

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Tipo Cambio"
                                name="tipo_cambio"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber min={1} defaultValue={0} className="width-100" onChange={this.onChangeTipo} />
                            </Form.Item>
                        </Col>
                    </Row>


                    
                </Spin>
            </Form>

        )
    }
}




export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={"Clasificar facturas"}
        /* footer={null} */
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-materiales', key: 'submit', htmlType: 'submit' }}
    >
        <ModalClasificarTransacciones {...props} />
    </Modal>

}



