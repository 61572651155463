import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography, Collapse, Dropdown, Menu, Upload, Switch } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

import { IconCheckBtn, IconCheckMedal, IconPlusGroup } from '../../Widgets/Iconos'
import { BsFillFlagFill, BsCheckLg } from 'react-icons/bs';

import CustomAvatar from '../../Widgets/Avatar/Avatar';

import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
//componentes
import ListaRequisiciones from './Listas/ListaRequisiciones'

//Modales
import ModalRequisiciones from './ModalRequisiciones'
import ModalRequisicionDetalle from './ModalRequisicionDetalle'


import "./Requisiciones.scss"

const { Text } = Typography;
const moment = require('moment')

const { Content } = Layout;
const { Panel } = Collapse;

/**
 * @export
 * @class Areas
 * @extends {Component}
 * @description Vista de Areas
 */
export class Areas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,


            panels: [1,2,3,0,4,5,6],
            searching: true,
            filtroSearch: '',

        }
    }


    referencias = {
        1: React.createRef(),
        2: React.createRef(),
        3: React.createRef(),
        0: React.createRef(),
        4: React.createRef(),
        5: React.createRef(),
        6: React.createRef(),
    }
    

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        // this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        window.onscroll = this.handleScroll

    }


    getRequisiciones = (array_estatus) => {

        for(const estatus of array_estatus){
            this.referencias[estatus].current.getRequisiciones({page: 1})
        }
    }

    panelChange = (values) => {
        let panels = this.state.panels;
        let index = panels.indexOf(values);
        if(index !== -1){
            panels.splice(index,1)
        }else{
            panels.push(values);
        }

        this.setState({ panels })
    }

    handleScroll = () => {

        if ((window.innerHeight + Math.round(window.scrollY)) ===  document.body.scrollHeight) {
            for(const estatus of this.state.panels){
                this.referencias[estatus].current.next()
            }
        }
    }

    render() {

        let props = {
            getRequisiciones: (array) => this.getRequisiciones(array),
            panelChange: (value)=>this.panelChange(value),
            openModalDetalle: (requisicion_id) => this.setState({requisicion_id, modal_visible_detalle: true})
        }

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Requisiciones"
                    />
                    <Content className="admin-content requisiciones content-bg pd-1">


                        <ListaRequisiciones 
                            ref={this.referencias[1]}
                            label={"Requerido"}
                            name={"requerido"}
                            estatus={1}
                            {...props}
                        />

                        <ListaRequisiciones 
                            ref={this.referencias[2]}
                            label={"Cotizado"}
                            name={"cotizado"}
                            estatus={2}
                            {...props}
                        />

                        <ListaRequisiciones 
                            ref={this.referencias[3]}
                            label={"Pausado"}
                            name={"pausado"}
                            estatus={3}
                            {...props}
                        />

                        <ListaRequisiciones 
                            ref={this.referencias[0]}
                            label={"Cancelado"}
                            name={"cancelado"}
                            estatus={0}
                            {...props}
                        />

                        <ListaRequisiciones 
                            ref={this.referencias[4]}
                            label={"Entrega"}
                            name={"entrega"}
                            estatus={4}
                            {...props}
                        />

                        <ListaRequisiciones 
                            ref={this.referencias[5]}
                            label={"Recolección"}
                            name={"recoleccion"}
                            estatus={5}
                            {...props}
                        />

                        <ListaRequisiciones 
                            ref={this.referencias[6]}
                            label={"Entregado"}
                            name={"entregado"}
                            estatus={6}
                            {...props}
                        />

                    </Content>


                
                <FloatingButton title="Nueva Requisición" onClick={()=>this.setState({modal_visible: true})}/>




                </Spin>
                <ModalRequisiciones
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({modal_visible:  false})
                        if(flag === true){
                            this.getRequisiciones([1])
                        }
                    }}
                />

                <ModalRequisicionDetalle
                    visible={this.state.modal_visible_detalle}
                    onClose={(flag)=>{
                        this.setState({modal_visible_detalle:  false})
                    }}
                    requisicion_id={this.state.requisicion_id}
                />

            </>
        )
    }
}

export default function (props) {

    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {


    })

    return <Areas {...props} {...permissions} navigate={useNavigate()} />

}