import React, { Component, useEffect } from "react";
import { Typography, Button, Modal, Form, Input, message, Spin, Row, Col, Divider, Select, Space, Tooltip, InputNumber, Tag } from 'antd'


import { DeleteOutlined, EditOutlined, PlusCircleTwoTone, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import moment from "moment";
import Avatar from "../../../Widgets/Avatar/Avatar";
import './Modales.scss'

const { Text } = Typography

const { Option } = Select

const axios = require('axios').default;




/**
 *
 *
 * @class ModalProductos
 * @extends {Component}
 */
class ModalBitacoraInventario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            bitacora_id: this.props.bitacora_id,
            bitacora: {},
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.bitacora_id !== undefined) {
            this.getBitacora()
        }

    }


    renderUrl = (item) => {
        const urlParams = new URL(axios.defaults.baseURL + "/proyectos/logo/" + item._id)
        urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))
        urlParams.searchParams.set("name", item.logo?.name)

        console.log(urlParams.href)

        return urlParams.href
    }





    /**
     * @memberof ModalProductos
     * @method getProducto
     * @description Obtiene una producto de la DB
     */
    getBitacora = () => {
        this.setState({ loading: true })
        axios.get(`/almacenes-bitacoras/${this.state.bitacora_id}`).then(({ data }) => {

            console.log("bitacora", data)
            this.setState({
                bitacora: data,
            })
        }).catch(error => {
            message.error('Error al traer bitacora')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>

                <Row gutter={[16, 16]} className="w-100">
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Row>
                            <Text className='sub-title'>Producto</Text>
                        </Row>
                        <Row className="mt-1">
                            <Text className='title-modal'>{this.state.bitacora?.producto_id?.nombre}</Text>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Row>
                            <Text className='sub-title'>SKU</Text>
                        </Row>
                        <Row className="mt-1">
                            <Text className='title-modal'>{this.state.bitacora?.producto_id?.sku}</Text>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Row>
                            <Text className='sub-title'>Categoria</Text>
                        </Row>
                        <Row className="mt-1">
                            <Text className='title-modal'>{this.state.bitacora?.producto_id?.categoria_id?.nombre}</Text>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Row>
                            <Text className='sub-title'>Proyecto</Text>
                        </Row>
                        <Row className="mt-1" align="middle">
                            <Avatar
                                color={this.state.bitacora?.proyecto_id?.color}
                                {...(this.state.bitacora?.proyecto_id?.logo ? {
                                    image: this.renderUrl(this.state.bitacora?.proyecto_id)
                                } : {
                                    name: this.state.bitacora?.proyecto_id?.nombre
                                })}


                            // image={}
                            /><Text className='title-modal ml-1'>{this.state.bitacora?.proyecto_id?.nombre}</Text>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Row>
                            <Text className='sub-title'>Contratista</Text>
                        </Row>
                        <Row className="mt-1" align="middle">
                            <Avatar></Avatar><Text className='title-modal ml-1'>{this.state.bitacora?.contratista_id?.nombre}</Text>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Row>
                            <Text className='sub-title'>Inmueble</Text>
                        </Row>
                        <Row className="mt-1">
                            <Tag className="tag-inmueble">{this.state.bitacora?.inmueble_id?.nombre}</Tag>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Row>
                            <Text className='sub-title'>Volumen</Text>
                        </Row>
                        <Row className="mt-1" align="middle">
                            <Text className='title-modal'>{this.state.bitacora?.volumen} {this.state.bitacora?.producto_id?.unidad?.simbolo ? this.state.bitacora.producto_id?.unidad?.simbolo : this.state.bitacora.producto_id?.unidad?.nombre}{(this.state.bitacora.volumen > 1) ? "s" : null}</Text>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Row>
                            <Text className='sub-title'>Usuario</Text>
                        </Row>
                        <Row className="mt-1" align="middle">
                            <Avatar></Avatar><Text className='title-modal ml-1'>{this.state.bitacora?.usuario_id?.nombre}</Text>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Row>
                            <Text className='sub-title'>Fecha & Hora</Text>
                        </Row>
                        <Row className="mt-1">
                            <Text className='title-modal'>{moment(this.state.bitacora?.fecha).format("lll")}</Text>
                        </Row>
                    </Col>
                </Row>

            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, producto_id, proveedor_id, bitacora_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        width={"50%"}
        title={"Detalles de Bitacora de Inventario"}
        closable={true}
        destroyOnClose={true}
        footer={<Button 
            onClick={onClose}
            type="primary"
        >
            Cerrar
        </Button>}
    >
        <ModalBitacoraInventario {...props} />
    </Modal>

}