import React, { Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Button, Spin, PageHeader, Layout, message, List, Card, Typography} from "antd";
import axios from "axios";
import { IconArrowBack } from "../../Widgets/Iconos";
import { PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined, FileSearchOutlined } from '@ant-design/icons'
import { CardConcepto, CardProyecto, CardEgresado } from '../../Widgets/Cards';
import { color_transaccion_tipo } from "../../Utils"
//Modal
import ModalTransaccionDetalle from '../Finanzas/Transacciones/ModalTransaccionDetalle'
//css
import '../../../Styles/Modules/budgeting.css'

const { Content } = Layout;
const { Text, Title } = Typography;
const moment = require('moment')
/**
 * @export
 * @class ConceptoDetalle
 * @extends {Component}
 * @description Vista de ConceptoDetalle
 */
class ConceptoDetalle extends Component {
    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            proyecto:{},
            concepto: '',
            subconcepto: '',
            ejercido: 0,
            presupuesto: 0,
            transacciones: {
                data: [],
                limit: 20,
                page: 1,
                total: 0,
                pages: 0,
            },
            
        };
    }

    proyectoRef = React.createRef()

    componentDidMount() {
        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");

        this.getTransacciones()
        this.getSubconcepto()
    }

    /**
     *
     * @memberof Proyectos
     * @method getSubconcepto
     * @description Obtiene la informacion de los proyectos y actualiza los valores locales de la lista.
     */
    getSubconcepto = () => {
        this.setState({loading: true})
        axios.get(`subconcepto/${this.props.params.subconcepto_id}`, { 
        })
            .then(({ data }) => {
                this.setState({
                    proyecto: data.proyecto_id,
                    concepto: data.concepto_id.nombre,
                    subconcepto: data.nombre,
                    ejercido: data.ejercido,
                    presupuesto: data.presupuesto,
                })

            })
            .catch((error) => {
                console.log("error", error);
                message.error("Error al obtener los proyectos")
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     *
     * @memberof Proyectos
     * @method getTransacciones
     * @description Obtiene la informacion de los proyectos y actualiza los valores locales de la lista.
     */
    getTransacciones = (
        {
            page = this.state.transacciones.page,
            limit = this.state.transacciones.limit,
        } = this.state.transacciones
    ) => {

        axios.get("/transacciones", { 
            params: { 
                limit, 
                page, 
                subconcepto_id: this.props.params.subconcepto_id,
                unwind_conceptos: true
            } 
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({transacciones: data})

            })
            .catch((error) => {
                console.log("error", error);
                message.error("Error al obtener las transacciones (CD)")
            })
            .finally(() => this.setState({ loading: false }))
    }

        /**
 *
 *
 * @param {*} tipo
 * @memberof Transacciones
 * @description Renderiza el icono según el estatus
 */
    renderIconTransaccion = (tipo) => {
        switch (tipo) {
            case 1:
                return <PlusOutlined style={{ color: "currentColor" }} />
            case 2:
                return <MinusOutlined style={{ color: "currentColor" }} />
            case 3:
                return <SwapOutlined style={{ color: "currentColor" }} />
            case 4:
                return <StopOutlined style={{ color: "currentColor" }} />
            default:
                return '-'
        }
    }



    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row>
                                <Col span={18}>
                                    <Title level={4} style={{margin: '0', color: 'white'}}>Transacciones</Title>
                                </Col>
                            </Row>
                        }
                        backIcon={<IconArrowBack/>}
                        onBack={(e) => this.props.navigate(-1)}
                    />

                    <Content className="admin-content content-bg pd-1 budgeting">
                        <Row gutter={[16, 16]} className="mb-1">
                            <Col span={8} className="grid">
                                <CardProyecto {...this.state.proyecto}/>
                            </Col>
                            <Col span={8} className="grid">
                                <CardConcepto 
                                    concepto={this.state.concepto}
                                    subconcepto={this.state.subconcepto}
                                />
                            </Col>
                            <Col span={8} className="grid">
                                <CardEgresado
                                    ejercido={this.state.ejercido}
                                    presupuesto={this.state.presupuesto}
                                />
                            </Col>
                        </Row>
                        <List
                        loading={this.state.loading}
                        className="component-list "
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin Transacciones" }}
                        dataSource={this.state.transacciones?.data}
                        pagination={{
                            current: this.state.transacciones.page,
                            pageSize: this.state.transacciones.limit,
                            total: this.state.transacciones.total,
                            position: 'bottom',
                            className: "flex-left",
                            onChange: (page, limit) => this.getTransacciones({ page, limit })
                        }}

                        renderItem={item => (
                            <List.Item className="component-list-item ">
                                <Card className="card-list" bordered={false}>
                                    <div className={`badge-card tipo-${item.tipo}`}>
                                    </div>
                                    <Row className="width-100" >
                                        <Col xs={2} className="center">
                                            <Text strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                        </Col>
                                        <Col xs={4} className="center">
                                            <Text ellipsis className="text-gray-dark">{item.concepto}</Text>
                                        </Col>
                                        <Col xs={4} className="center">
                                            <Text ellipsis className="text-gray-dark">{item.area_id?.nombre}</Text>
                                        </Col>
                                        <Col xs={4} className="center">
                                            <Text ellipsis className="text-gray-dark">{item.rubro_id?.nombre}</Text>
                                        </Col>
                                        <Col xs={3} className=" center">
                                            <Text className="text-gray-dark">{item.cuenta_id?.nombre}</Text>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Text style={{ color: color_transaccion_tipo(item.tipo), fontWeight: "bold" }} >
                                                {this.renderIconTransaccion(item.tipo)} $ {item.monto.toMoney(true)} MXN</Text>
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Text > $ {item.conceptos?.monto?.toMoney(true)} MXN</Text>
                                        </Col>
                                        <Col xs={1} className="center">
                                            <Button
                                                icon={<FileSearchOutlined style={{ color: "currentcolor" }} />}
                                                type="secondary"
                                                title="Ver Detalle"
                                                onClick={() => this.setState({ modalTransacDetalleVisible: true, transaccion_id: item._id })}>
                                            </Button>
                                        </Col>
                                        
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
                    </Content>


                </Spin>
                <ModalTransaccionDetalle
                    visible={this.state.modalTransacDetalleVisible}
                    onClose={() => this.setState({
                        modalTransacDetalleVisible: false,
                        transaccion_id: null
                    })}
                    id={this.state.transaccion_id}
                />
            </>
        );
    }
}


export default function Vista(props) {

    return <ConceptoDetalle {...props} params={useParams()} navigate={useNavigate()} />

}