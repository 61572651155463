import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Card, Spin, Divider, Typography, Image, Alert, Layout } from 'antd';
import { Redirect, Link } from 'react-router-dom';
import '../../Styles/Modules/Landing/landing.css';

// Components
import { PublicNavbar } from '../Navbar/Navbar';

const { Title } = Typography

/**
 * 
 * @class Landing
 * @extends {Component}
 * @description Componente Landing page
 */
class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  render() {
    return(
      <Layout className="layout-landing" style={{ backgroundImage: "url(/img/background/bg-landing.png)" }}>
        <PublicNavbar />
        <div className='image-container'>
          <div>
            <Button type='primary' size='large' className='btn-erp'>Enterprise Resource Planning</Button>
          </div>
          <div className='logo-container'>
            <img src="/img/logo-black.png" alt="Logo " />
          </div>
        </div>
      </Layout>
    )
  }
}

export default function (props) {

  return <Landing {...props} />
}