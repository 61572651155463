import React, { Component } from "react";
import { Modal, Form, Input, message, Spin, Select, Divider, Space } from 'antd';
import TextArea from "antd/lib/input/TextArea";
const axios = require('axios').default;
const { Option } = Select

/**
 *
 *
 * @class ModalAreas
 * @extends {Component}
 */
class ModalAreas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proyectos: {
                data: [],
                limit: 12,
                page: 1,

                total: 0,
                pages: 0,
                inputValue: null
            },
        }
    }

    ModalAreas = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        
        this.getProyectos()
        
        if (this.props.almacen_id !== undefined) {
            this.getAlmacen()
        }
    }


    /**
     * @memberof ModalAreas
     * @method getArea
     * @description Obtiene una area de la DB
     */
    getAlmacen = () => {
        this.setState({ loading: true })
        axios.get(`/almacenes/${this.props.almacen_id}`, {
            params: {
                id: this.props.almacen_id
            }
        }).then(({ data }) => {
            console.log(data)
            let almacen = data;
            this.setState({
                almacen: data,
            })

            this.ModalAreas.current.setFieldsValue({
                nombre: almacen.nombre,
                proyecto_id: almacen.proyecto_id._id,
                descripcion: almacen.descripcion,
            })

        }).catch(error => {
            message.error('Error al traer almacen')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
        * @memberof ModalAreas
        * @method addArea
        * @description Añade una area a la BD
        */
    addAlmacen = (values) => {
        axios.post('/almacenes', {
            ...values
        }).then(response => {

            
                message.success('Almacén creado')
                this.props.onClose()
            

        }).catch(error => {
            console.log(error)
            message.error(error?.response?.data?.message ?? 'Error al crear Almancén.')
        })
    }

    /**
     * @memberof ModalAreas
     * @method updateArea
     * @description Actualiza la información de una area
     */
    updateAlmacen = (values) => {
        this.setState({ loading: true })
        axios.put('/almacenes/'+ this.props.almacen_id, {
            ...values,
            id: this.props.almacen_id,
        }).then(response => {
            message.success('Almacen Actualizado')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar Almacen')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalAreas
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => (this.props.almacen_id) ? this.updateAlmacen(values) : this.addAlmacen(values)

    /**
     * @memberOf ModalAlmacen
     * @method getProyectos
     * @descripcion Obtiene la lista de proyectos
     */
    getProyectos = ({
        page = this.state.proyectos.page,
        limit = this.state.proyectos.limit,
        search = this.state.proyectos.search,
    } = this.state.proyectos) => {
        this.setState({ categorias: { ...this.state.categorias, page, limit, search, inputValue: null } })
        axios.get('/proyectos', {
            params: { page, limit, search }
        })
            .then(({ data }) => {
                this.setState({ proyectos: { ...this.state.proyectos, ...data } })
            })
            .catch(error => {
                message.error('Error al obtner las los proyectos')
            })

    }

    

    render() {
        return (
            <Form
                layout="vertical"
                name="form-areas"
                ref={this.ModalAreas}
                onFinish={this.onFinish}
                className="pd-1"
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Nombre del Almacén"
                        name="nombre"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                    >
                        <Input placeholder="Nombre" ></Input>
                    </Form.Item>
                    <Form.Item
                        label="Proyecto Relacionado"
                        name="proyecto_id"
                    >
                        <Select
                            placeholder="Proyecto"
                            className="form-select select-actions"
                            
                            disabled={!this.props.id && this.state.sku_cargado}
                            onSearch={search => this.getProyectos({ search })}
                            showSearch
                            filterOption={false}
                            
                            optionLabelProp="label"
                        >
                            {this.state.proyectos.data?.map((categoria, index) => {


                                return <Option
                                    className="option-actions "
                                    key={categoria._id}
                                    value={categoria._id}
                                    label={categoria.nombre}
                                    codigo={categoria.codigo}
                                >
                                    {categoria?.nombre}
                                </Option>
                            })}
                        </Select>
                    </Form.Item>
                    
                    <Form.Item
                        label="Descripción"
                        name="descripcion"
                        rules={[{
                            required: true,
                            message: "Por favor, escriba una descripción breve"
                        }]}
                    >
                        <TextArea />
                    </Form.Item>

                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, almacen_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={almacen_id ? "Editar Almacén" : "Crear Almacén"}
        
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-areas', key: 'submit', htmlType: 'submit' }}
    >
        <ModalAreas {...props} />
    </Modal>

}