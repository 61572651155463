import React, { Component } from "react";
import { Row, Col, Space, Modal, List, Typography, Form, DatePicker, Input, InputNumber, Select, message, Spin, AutoComplete, Divider, Button } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';

//componentes
import { Uploader } from "../../../Widgets/Uploaders";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import BudgetingInputs from "../../../Widgets/Inputs/BudgetingInputs";
import {
    SelectInversion, SelectTransaccionProgramada, SelectAcreedor, SelectPropiedad, SelectCliente, SelectArea,
    SelectProveedor, SelectRubro, SelectCuenta, SelectEmpresa, SelectProyecto
} from "../../../Widgets/Inputs/Selects"
import Paragraph from "antd/lib/skeleton/Paragraph";


const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');

const Decimal = require('decimal.js');

class ModalTransacciones extends Component {


    static defaultProps = {
        concepto: true,
        fecha: true,
        descripcion: true,
        area: true,
        rubro: true,
        cliente: true,
        propiedad: true,
        factura: true,
        cuenta: true,
        tipo: true,
        monto: true,
        empresa: true,
        proyecto: true,
        conceptos: true,
        transaccion_programada: true,
        acreedor: true,
        inversion: true,
        proveedor: true,
        initialValues: {
            fecha: moment()
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            facturas: {
                data: [],

                page: 1,
                limit: 15,

                pages: 0,
                total: 0,
                search: null
            },
            filters: {},
            cuentaSeleccionada: '',
            disabled: false,
            transaccion: {},
            cliente_id: undefined,
            disabled_campos: null,


            concepto: this.props.concepto,
            fecha: this.props.fecha,
            descripcion: this.props.descripcion,
            area: this.props.area,
            rubro: this.props.rubro,
            cliente: this.props.cliente,
            propiedad: this.props.propiedad,
            factura: this.props.factura,
            cuenta: this.props.cuenta,
            tipo: this.props.tipo,
            monto: this.props.monto,
            empresa: this.props.empresa,
            proyecto: this.props.proyecto,
            conceptos: this.props.conceptos,
            inversion: this.props.inversion,
            transaccion_programada: this.props.transaccion_programada,
            acreedor: this.props.acreedor,
            proveedor: this.props.proveedor,

            initialValues: this.props.initialValues


        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


        if (this.props.initialValues) {
            this.modalRef.current?.setFieldsValue({
                ...this.props.initialValues,
                fecha: moment()
            })
        }

        if (this.props.transaccion_id !== undefined || this.props.transaccion_id != null) {
            this.getTransaccion();
        }

        this.setState({
            inversion_id: this.props.inversion_id ?? undefined,
            acreedor_id: this.props.acreedor_id ?? undefined,
            propiedad_id: this.props.propiedad_id ?? undefined,
            proveedor_id: this.props.proveedor_id ?? undefined,
        })

    }


    /**
     * @memberof ModalTransacciones
     * @method getTransaccion
     * @description 
     * 
     */
    getTransaccion = () => {
        this.setState({ loading: true })

        axios.get(`/transacciones/${this.props.transaccion_id}`, {
            params: {
                id: this.props.transaccion_id
            }
        })
            .then(({ data }) => {
                this.setState({
                    transaccion: data,
                    area_id: data.area_id?._id,
                    cliente_id: data.cliente_id?._id,
                    disabled_campos: data.propiedad_id || data.inversion_id || data.acreedor_id || data.proveedor_id || data.transaccion_programada_id
                })


                this.modalRef.current?.setFieldsValue({
                    ...data,
                    fecha: moment(data.fecha),
                    area_id: data?.area_id ? {
                        value: data?.area_id?._id,
                        label: data?.area_id?.nombre
                    } : null,
                    rubro_id: data?.rubro_id ? {
                        value: data?.rubro_id?._id,
                        label: data?.rubro_id?.nombre
                    } : null,
                    cliente_id: data?.cliente_id?._id ? {
                        value: data?.cliente_id?._id,
                        label: data?.cliente_id?.nombre
                    } : null,
                    propiedad_id: data?.propiedad_id?._id ? {
                        value: data?.propiedad_id?._id,
                        label: data?.propiedad_id?.nombre
                    } : null,
                    cuenta_id: {
                        value: data?.cuenta_id?._id,
                        label: data?.cuenta_id?.nombre
                    },
                    empresa_id: {
                        value: data?.empresa_id?._id,
                        label: data?.empresa_id?.nombre
                    },
                    proveedor_id: data?.proveedor_id?._id ? {
                        value: data?.proveedor_id?._id,
                        label: data?.proveedor_id?.alias
                    } : null,
                    inversion_id: data?.inversion_id?._id ? {
                        value: data?.inversion_id?._id,
                        label: data?.inversion_id?.nombre
                    } : null,
                    acreedor_id: data?.acreedor_id?._id ? {
                        value: data?.acreedor_id?._id,
                        label: data?.acreedor_id?.acreedor
                    } : null,
                    transaccion_programada_id: data?.transaccion_programada_id?._id ? {
                        value: data?.transaccion_programada_id?._id,
                        label: data?.transaccion_programada_id?.concepto
                    } : null,
                    conceptos: data.conceptos.length > 0 ? data.conceptos.map(e => {
                        return {
                            proyecto_id: {
                                value: e?.proyecto_id?._id,
                                label: e?.proyecto_id?.nombre
                            },
                            concepto_id: {
                                value: e?.concepto_id?._id,
                                label: e?.concepto_id?.nombre
                            },
                            subconcepto_id: {
                                value: e?.subconcepto_id?._id,
                                label: e?.subconcepto_id?.nombre
                            },
                            monto: e.monto
                        }
                    }) : [],
                    comprobantes: data.comprobantes.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/transacciones/" + this.props.transaccion_id + "?comprobante=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })),

                })
                this.setState({ loading: false })
            })
            .catch(error => {

                console.log('error al traer transaccon', error)
                this.setState({ loading: false })

            }).finally(() => {

                this.setState({ loading: false, disabled: true })
            })
    }




    /**
    * @memberof ModalTransacciones
    * @method getCuentas
    * @description Trae las facturas 
    * 
    */
    getFacturas = ({

        page = this.state.facturas.page,
        limit = this.state.facturas.limit,
        search = this.state.facturas.search,

    } = this.state.facturas) => {
        this.setState({ facturas: { ...this.state.facturas, loading: true, page, limit, search } })
        axios.get('/facturas', {
            params: {
                page,
                limit,
                search
            }
        })
            .then(({ data }) => {
                this.setState(state => {
                    state.facturas.data = data.data;
                    state.facturas.total = data.total;
                    state.facturas.pages = data.pages;
                    return state;
                })
            })
            .catch(error => {
                console.log(error)
                message.error('No es posible traer las facturas')
            })
    }



    /**
     * @memberof ModalTransacciones
     * @method clasificarTransacciones
     * @description Envia las transacciones a clasificar. Las clasifica. 
     * 
     */
    clasificarTransacciones = (values) => {
        this.setState({ loading: true })
        axios.post('/syncfy/transacciones/add', {
            ...values,
            cuenta_id: this.props.cuenta_id,
            transacciones: Object.values(this.props.seleccionadas)
        }).then(response => {
            message.success('Transacciones clasificadas')
            this.props.onClose()
            this.props.clearSeleccionadas()
        }).catch(error => {
            console.log(error)
            message.error('Error al clasificar transacciones')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalTransacciones
    * @method addTransaccion
    * @description Trae las cuentas 
    * 
    */
    addTransaccion = (values) => {
        this.setState({ loading: true })
        axios.post('/transacciones', values)
            .then(response => {
                message.success('Transacción creada')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error(error?.response?.data?.message ?? 'Error al crear la transacción')
                this.setState({ loading: false })
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalTransacciones
    * @method updateTransaccion
    * @description Trae las cuentas 
    * 
    */
    updateTransaccion = (values) => {
        this.setState({ loading: true })
        axios.put('/transacciones', values)
            .then(response => {
                message.success('Transacción Actualizada')
                this.props.onClose(true)
            }).catch(error => {
                console.log(error)
                message.error('Error al guardar la información')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalTransacciones
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        if (this.props.tipo_pago)
            values.tipo_pago = this.props.tipo_pago

        for (const key of Object.keys(values)) {
            if (values[key]?.value)
                values[key] = values[key]?.value

        }

        const formData = new FormData()
        formData.appendMultiple({
            inversion_id: this.props?.inversion_id,
            propiedad_id: this.props?.propiedad_id,
            acreedor_id: this.props?.acreedor_id,
            cliente_id: this.props?.cliente_id,
            id: this.props.transaccion_id,
            ...values,
            comprobantes: undefined,
            conceptos: undefined
        })

        if (Array.isArray(values.conceptos) && values.conceptos.length > 0)
            values.conceptos.forEach(e => (

                formData.append("conceptos", JSON.stringify({
                    proyecto_id: e.proyecto_id.value,
                    concepto_id: e.concepto_id.value,
                    subconcepto_id: e.subconcepto_id.value,
                    monto: e.monto
                }))
            ))

        values.comprobantes?.forEach(e => {
            if (!e.fromDB)
                formData.append("comprobantes", e, e.name)
        })

        if (this.props.clasificacion) {

            console.log("a", values);
            console.log("a", formData);
            this.clasificarTransacciones(values)

        } else
            if (this.props.transaccion_id)
                this.updateTransaccion(formData)
            else
                this.addTransaccion(formData)
    }

    render() {

        const {
            concepto,
            fecha,
            descripcion,
            area,
            rubro,
            cliente,
            propiedad,
            factura,
            cuenta,
            tipo,
            empresa,
            proyecto,
            transaccion_programada,
            inversion,
            acreedor,
            proveedor,
            disabled_campos,
        } = this.state;

        const {
            clasificacion,
            seleccionadas,
        } = this.props;


        let tipoFormItem = <Col xs={24} lg={12}>
            <Form.Item
                label="Tipo"
                name="tipo"

                rules={[{
                    required: true,
                    message: "Por favor, seleccione el tipo"
                }]}
            >
                <Select
                    disabled={this.state.transaccion["tipo"]}
                    onSelect={value => {
                        this.setState({ tipoTransaccion: value })
                    }}
                    placeholder="Seleccione tipo">
                    <Option value={1}>Ingreso</Option>
                    <Option value={2}>Egreso</Option>
                    {
                        !this.props.cliente_id && (
                            <>
                                <Option value={3}>Traspaso</Option>
                                <Option value={4}>Nulo <Text type="secondary" size="small">(No afecta saldos)</Text></Option>
                            </>
                        )
                    }
                </Select>
            </Form.Item>
        </Col>


        return (
            <Form
                layout="vertical"
                name="form-transaccion"
                id="form-transaccion"
                ref={this.modalRef}
                onFinish={this.onFinish}
                initialValues={{
                    ...this.state.initialValues,
                    fecha: moment()
                }}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 0]}>
                        {(concepto) ? <Col xs={24} lg={clasificacion ? 12 : 24}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"
                                rules={!clasificacion && [{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]}
                                extra={"Si no hay un concepto, se indicará la descripción del banco"}
                            >
                                <Input disabled={this.state.transaccion["concepto"]} placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col> : null}

                        {clasificacion && tipoFormItem}
                        {!clasificacion ? <>
                            {(fecha) ? <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Fecha"
                                    name="fecha"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese la fecha"
                                    }]}
                                >
                                    <DatePicker disabled={this.state.transaccion["fecha"]} className="width-100" />
                                </Form.Item>
                            </Col> : null}

                            {(descripcion) ? <Col xs={24} lg={24}>
                                <Form.Item
                                    label="Descripción"
                                    name="descripcion"
                                >
                                    <Input.TextArea disabled={this.state.transaccion["descripcion"]} placeholder="Descripción" />
                                </Form.Item>
                            </Col> : null}

                        </> : null}


                        {(area) ? <Col xs={24} lg={12}>
                            <Form.Item
                                label="Área"
                                name="area_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el area"
                                }]}
                            >
                                <SelectArea
                                    disabled={this.state.transaccion["area_id"]}
                                    onSelect={area_id => {
                                        this.setState({ area_id: area_id })
                                        this.modalRef.current.setFieldsValue({ rubro_id: undefined })
                                    }}
                                />
                            </Form.Item>
                        </Col> : null}

                        {(rubro) ?
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Rubro"
                                    name="rubro_id"
                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione el rubro"
                                    }]}
                                >
                                    <SelectRubro
                                        className="width-100"
                                        disabled={this.state.transaccion["rubro_id"]}
                                        area_id={this.state.area_id}

                                    />
                                </Form.Item>
                            </Col> : null}


                        {(cliente) ? <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                            >
                                <SelectCliente
                                    disabled={this.state.transaccion["cliente_id"]}
                                    onSelect={(cliente_id) => {
                                        this.setState({ cliente_id })
                                        this.modalRef.current.setFieldsValue({ propiedad_id: undefined })
                                    }}
                                />
                            </Form.Item>
                        </Col> : null}

                        {propiedad && <Col xs={24} lg={12}>
                            <Form.Item
                                label="Propiedad"
                                name="propiedad_id"
                            >
                                <SelectPropiedad
                                    disabled={this.state.transaccion["propiedad_id"] || disabled_campos}
                                    cliente_id={this.state.cliente_id}
                                    onSelect={(propiedad_id) => {
                                        this.modalRef.current.setFieldsValue({ acreedor_id: undefined, proveedor_id: undefined, inversion_id: undefined, transaccion_programada_id: undefined })
                                        this.setState({ propiedad_id, acreedor_id: undefined, proveedor_id: undefined, inversion_id: undefined })
                                    }
                                    }
                                />
                            </Form.Item>
                        </Col>}

                        {inversion && <Col xs={24} lg={12}>
                            <Form.Item
                                label="Inversión"
                                name="inversion_id"
                            >
                                <SelectInversion
                                    disabled={this.props.inversion_id || this.state.transaccion["inversion_id"] || disabled_campos}
                                    onSelect={(inversion_id) => {
                                        this.modalRef.current.setFieldsValue({ acreedor_id: undefined, proveedor_id: undefined, propiedad_id: undefined, transaccion_programada_id: undefined })
                                        this.setState({ inversion_id, acreedor_id: undefined, proveedor_id: undefined, propiedad_id: undefined })
                                    }
                                    }
                                />
                            </Form.Item>
                        </Col>}

                        {acreedor && <Col xs={24} lg={12}>
                            <Form.Item
                                label="Acreedor"
                                name="acreedor_id"
                            >
                                <SelectAcreedor
                                    disabled={this.props.acreedor_id || this.state.transaccion["acreedor_id"] || disabled_campos}
                                    onSelect={(acreedor_id) => {
                                        this.modalRef.current.setFieldsValue({ inversion_id: undefined, proveedor_id: undefined, propiedad_id: undefined, transaccion_programada_id: undefined })
                                        this.setState({ acreedor_id, inversion_id: undefined, proveedor_id: undefined, propiedad_id: undefined })
                                    }
                                    }
                                />
                            </Form.Item>
                        </Col>}

                        {(proveedor) ? <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                            >
                                <SelectProveedor
                                    disabled={this.props.proveedor_id || disabled_campos}
                                    onSelect={(acreedor_id) => {
                                        this.modalRef.current.setFieldsValue({ inversion_id: undefined, propiedad_id: undefined, acreedor_id: undefined, transaccion_programada_id: undefined })
                                        this.setState({ acreedor_id, inversion_id: undefined, propiedad_id: undefined, acreedor_id: undefined })
                                    }
                                    }
                                />
                            </Form.Item>
                        </Col> : null}

                        {transaccion_programada && <Col xs={24}>
                            <Form.Item
                                label="Transacción Programada"
                                name="transaccion_programada_id"
                            >
                                <SelectTransaccionProgramada
                                    disabled={this.state.transaccion["transaccion_programada_id"] || disabled_campos}
                                    inversion_id={this.state.inversion_id}
                                    acreedor_id={this.state.acreedor_id}
                                    propiedad_id={this.state.propiedad_id}
                                    proveedor_id={this.state.proveedor_id}
                                />
                            </Form.Item>
                        </Col>}


                        {(factura) ? <Col xs={24} lg={24}>
                            <Form.Item
                                label="Facturas (UUID)"
                                name="uuid"
                            >
                                <AutoComplete
                                    disabled={this.state.transaccion["uuid"]}
                                    className="width-100"
                                    options={this.state.facturas.data.map(item => {
                                        const { uuid, serie, folio, cliente, proveedor, concepto, monto, monto_restante, _id } = item
                                        return (
                                            {
                                                option: item,
                                                value: _id,
                                                label: <List.Item className="width-100" style={{ borderBottom: "1px solid lightgray" }}>
                                                    <List.Item.Meta
                                                        title={<>
                                                            {_id}
                                                            <small>{concepto}</small>
                                                            <small>{(cliente || proveedor) ? (cliente ? cliente.razon_social : proveedor.nombre_comercial) : null}</small>
                                                        </>}
                                                        description={<>
                                                            {monto.toLocaleString('en-US', { style: 'currency', currency: "USD" })}<br />
                                                            <small><strong>{monto_restante.toLocaleString('en-US', { style: 'currency', currency: "USD" })}</strong></small>
                                                        </>}
                                                    />
                                                </List.Item>,
                                                title: uuid
                                            }
                                        )
                                    })}
                                    onSearch={search => this.getFacturas({ search })}
                                    onSelect={(value, option) => {

                                        if (this.ModalTransacciones.current.getFieldValue("monto_pesos") > option.option.monto_restante) {
                                            this.ModalTransacciones.current.setFieldsValue({
                                                uuid: null
                                            })
                                            return message.error("El monto es mayor al monto restante")
                                        }



                                        this.ModalTransacciones.current.setFieldsValue({
                                            uuid: value
                                        })

                                    }}
                                >
                                    <Input placeholder="UUID" disabled={this.state.transaccion["uuid"]} />
                                </AutoComplete>
                            </Form.Item>
                        </Col> : null
                        }

                        {!clasificacion ? [
                            (cuenta) ? <Col xs={24} lg={12}>
                                <Form.Item
                                    label="Cuenta"
                                    name="cuenta_id"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, seleccione la cuenta"
                                    }]}
                                >
                                    <SelectCuenta
                                        disabled={this.state.transaccion["cuenta_id"]}
                                        onSelect={value => {
                                            this.setState({ cuentaSeleccionada: value })
                                            let cuenta_detino = this.modalRef.current?.getFieldValue('cuenta_destino_id')
                                            if (cuenta_detino === value)
                                                this.modalRef.current?.setFieldsValue({
                                                    cuenta_destino_id: null
                                                })
                                        }}
                                    />
                                </Form.Item>
                            </Col> : null,
                            (tipo) ? tipoFormItem : null
                        ] : null}
                        {!clasificacion ? [
                            <Col xs={24} lg={24} >
                                <Form.Item
                                    label="Monto"
                                    name="monto"

                                    rules={[{
                                        required: true,
                                        message: "Por favor, ingrese monto"
                                    }]}
                                >
                                    <InputNumber min={0.01} defaultValue={0} className="width-100"
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                    //disabled={this.state.transaccion["monto"]}
                                    />
                                </Form.Item>
                            </Col>
                        ] : null}

                        {(empresa) ? <Col xs={24} lg={12}>
                            <Form.Item
                                label="Empresa"
                                name="empresa_id"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la empresa"
                                }]}

                            >
                                <SelectEmpresa
                                    disabled={this.state.transaccion["empresa_id"]}
                                />

                            </Form.Item>
                        </Col> : null}
                        {(proyecto) ? <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proyectos"
                                name="proyecto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el proyecto"
                                }]}
                            >
                                <SelectProyecto
                                    disabled={this.state.transaccion["proyecto_id"]}

                                />
                            </Form.Item>
                        </Col> : null}

                    </Row>

                    {this.state.tipoTransaccion === 3 ? <Row>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cuenta"
                                name="cuenta_destino_id"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la cuenta"
                                }]}
                            >
                                <SelectCuenta
                                    placeholder="Seleccione cuenta"
                                    disabled={this.state.transaccion["cuenta_destino_id"]}
                                />
                            </Form.Item>
                        </Col>
                    </Row> : null}

                    {clasificacion ? <Row>

                        <List
                            header={<Title level={5}>Transacciones a clasificar</Title>}
                            className="width-100"
                            itemLayout="horizontal"
                            dataSource={Object.values(seleccionadas)}
                            renderItem={item => (
                                <List.Item className="width-100">
                                    <List.Item.Meta
                                        title={<a href="https://ant.design">{item.description}</a>}
                                        description={<>
                                            {item.reference ?? <p>{item.reference} </p>} <br />
                                            <strong>{moment.unix(item.dt_transaction).format("LLLL")}</strong>
                                        </>}
                                    />
                                    <div style={{ textAlign: "right" }}>{(
                                        item.currency === "MXN"
                                    ) ? <>
                                        <p>{Decimal(item.amount).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> MXN</strong></small></p>

                                    </> : <>

                                        <p>{Decimal(item.amount).toNumber().toLocaleString('en-US', { style: 'currency', currency: "USD" })}<small><strong> USD</strong></small></p>
                                    </>
                                    }</div>
                                </List.Item>
                            )}
                        />
                    </Row> : null}
                    <Divider className="modal-divider">Budgeting</Divider>
                    <Form.List name="conceptos">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <BudgetingInputs key={key} name={name} restField={{ ...restField }} add={add} remove={remove} />
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add field
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    {!clasificacion && <>
                        <Divider className="modal-divider">Comprobantes</Divider>
                        <Form.Item
                            label="Archivos e imagenes"
                            name="comprobantes"
                            valuePropName="fileList"
                            getValueFromEvent={e => {
                                if (Array.isArray(e)) {
                                    return e;
                                }
                                return e?.fileList;
                            }}>
                            <Uploader
                                {...this.props.transaccion_id ? {
                                    method: "PUT",
                                    name: "comprobante",
                                    headers: {
                                        authorization: sessionStorage.getItem("token")
                                    },
                                    action: axios.defaults.baseURL + '/transacciones',
                                    onRemove: e => {
                                        axios.put('/transacciones', {
                                            id: this.props.transaccion_id,
                                            comprobante: e.uid,
                                        })
                                    }
                                } : {}}>
                                <Button icon={<UploadOutlined />} block size="small">Subir Documentos </Button>
                            </Uploader>
                        </Form.Item>
                    </>}

                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, transaccion_id, clasificacion } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={transaccion_id ? "Editar Transacción" : "Nueva Transacción"}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-transaccion', key: 'submit', htmlType: 'submit' }}
        style={{ top: '10px' }}
        width={650}
    >
        <ModalTransacciones {...props} />
    </Modal>

}