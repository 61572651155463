import React, { Component } from "react";
import { Tree, Space, Button, message, Modal, Empty, Row, Col, Pagination, Typography} from "antd";
import { DeleteOutlined, EditOutlined, BuildOutlined, PlusOutlined, ExclamationCircleOutlined} from "@ant-design/icons"
//componentes
import Logged from '../../../../Hooks/Logged'
import usePermissions from "../../../../Hooks/usePermissions";
//Modales
import ModalInmuebles from './ModalInmuebles'
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import '../../../../Styles/Modules/Proyectos/Propiedades.scss';

const axios = require("axios").default;
const { Text } = Typography

const { confirm } = Modal;
/**
 * @class InmueblesTree
 * @description Modal para el CRUD de proyectos
 */
class InmueblesTree extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inmuebles: {
                data: [],
                limit: 20,
                page: 1,
                total: 0
            },
            expandedKeys: [],
            loading: false
        };
    }

    componentDidMount() {
       this.getInmuebles()
    }

    /**
     * @method addUser
     * @description Añade un nuevo registro de usuario
     */
    getInmuebles = ({
        limit = this.state.inmuebles.limit,
        page = this.state.inmuebles.page
    } = this.state.inmuebles) => {
        this.setState({loading: true, inmuebles: {...this.state.inmuebles, limit, page}});
        axios.get("/inmuebles",{
            params:{
                limit,
                page,
                proyecto_id: this.props.proyecto_id
            }
        })
            .then(({ data }) => {
                this.setState(state => {
                    state.inmuebles = {...data}
                })
            })
            .catch((error) => {
                console.log("error", error);
                message.error("Error al obtener el proyecto");
            })
            .finally(() => this.setState({ loading: false }));
    };

    /**
     * @method addUser
     * @description Elimina un inmueble
     */
    deleteInmueble = ({inmueble_id}) => {
        confirm({
            title: '¿Seguro de eliminar el Inmueble?',
            icon: <ExclamationCircleOutlined />,
            content: 'Se eliminaran los inmuebles inferiores',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk:() => {
                axios.delete(`/inmuebles/${inmueble_id}`, {
                    params: {
                        id: inmueble_id
                    }
                })
                .then(response => {
                    message.success('Inmueble Eliminado')
                    this.getInmuebles()
                    this.props.updateData()
                })
                .catch(error => {
                    message.error(error?.response?.data ?? "No fue posible eliminar el inmueble")
                })
            },
          });
    }

    onInmuebleDrop = ({dragNode, node}) => {
        if(this.state.loading) return

        this.setState({loading: true}, () => {
            axios.put('/inmuebles', {
                id: dragNode._id,
                inmueble_padre_id: node.dragOver ? node._id : undefined,
                mover: true
            }).then(response => {
                message.success('Inmueble Actualizado')
                this.getInmuebles()
                this.props.updateData()
            }).catch(error => {
                message.error(error?.response?.data ?? "No fue posible actualizar")
            })
        })
    }

    onInmuebleExpand = (expandedKeys, {expanded, node}) => {
        this.setState({expandedKeys})
    }

    render() {

        return (
            <>
                <Space direction="vertical" className="width-100" size={24}>
                {
                    this.state.inmuebles.total === 0 ? (
                        <Empty
                            description={'No hay inmuebles'}
                        />
                    ) : (
                        <Tree
                            className="draggable-tree"
                            draggable
                            blockNode
                            expandedKeys={this.state.expandedKeys}
                            treeData={this.state.inmuebles.data}
                            onDrop={this.onInmuebleDrop}
                            onExpand={this.onInmuebleExpand}
                            titleRender={(values)=>{
                                if(!values._id) return
                                
                                return <Row className="pt-05 pb-05">
                                    <Col flex="auto">
                                        <Text>
                                            {values.nombre}
                                        </Text>
                                    </Col>
                                    <Col flex="none">
                                        <Space style={{ float: "right" }}>
                                            <Button
                                                type="link"
                                                disabled = {!this.props.createPropiedades} 
                                                size="small" 
                                                icon={<PlusOutlined style={{ color: "currentcolor" }} />}
                                                onClick={()=>this.setState({ inmueble_padre_id: values._id, modal_visible: true })} 
                                            />
                                            <Button 
                                                type="link" 
                                                disabled = {!this.props.editPropiedades}
                                                size="small" 
                                                icon={<EditOutlined style={{ color: "currentcolor" }} />} 
                                                onClick={()=>this.setState({ inmueble_id: values._id, modal_visible: true })} 
                                            />
                                            <Button 
                                                type="link" 
                                                disabled = {!this.props.deletePropiedades}
                                                size="small" 
                                                icon={<DeleteOutlined style={{ color: "currentcolor" }} />} 
                                                onClick={()=>this.deleteInmueble({inmueble_id: values._id})}
                                            />
                                        </Space>
                                    </Col>
                                </Row>
                            }}
                        />
                    )
                }
                    <Pagination 
                        current={this.state.inmuebles.page} 
                        onChange={(page, limit) => this.getInmuebles({page, limit})} 
                        total={this.state.inmuebles.total} 
                    />

                </Space>
                
                {this.props.createPropiedades ? <FloatingButton
                    title="Nuevo inmueble"
                    onClick={() => this.setState({ modal_visible: true })}
                /> : null}
                <ModalInmuebles
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({modal_visible: false, inmueble_padre_id: undefined, inmueble_id: undefined})
                        if(flag === true)
                            this.getInmuebles()
                    }}
                    proyecto_id={this.props.proyecto_id}
                    inmueble_padre_id={this.state.inmueble_padre_id}
                    inmueble_id={this.state.inmueble_id}
                />
            </>
        );
    }
}

export default function Vista(props) {
    let user = React.useContext(Logged)

    
	let permissions = usePermissions(user?.rol_id?.permisos, {
		createPropiedades: ["proyectos","propiedades","create"],
		editPropiedades: ["proyectos","propiedades","edit"],		
		deletePropiedades: ["proyectos","propiedades" ,"delete"],
	})

    console.log(permissions)

    return <InmueblesTree {...props} {...permissions} />
}
