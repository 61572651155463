import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Statistic, Tag, Spin, Divider, Space, List } from 'antd';
//componentes
import { PaperClipOutlined } from "@ant-design/icons"
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { Link, NavLink } from "react-router-dom";

const { Text } = Typography;
const axios = require('axios').default;
const moment = require('moment');



/**
 *
 *
 * @class ModalTransaccionProgramadaDetalle
 * @extends {Component}
 */
class ModalTransaccionProgramadaDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            transaccion: {
                _id: 0,
                concepto: '',
                proyecto_id: {
                    nombre: ''
                },
                cuenta_id: {
                    nombre: ''
                },
                cuenta_destino_id: {
                    nombre: ''
                },
                monto: 0,
                fecha: new Date(),

                tipo: 0,

                usuario_id: {
                    nombre: ''
                },
                area_id: {
                    nombre: ''
                },
                rubro_id: {
                    nombre: ''
                },
                descripcion: '',
                comprobantes: [],
                actualiza_saldo: false,
                facturas: [{
                    factura_id: {

                    },
                    monto_abonado: 0
                }],

                conceptos: []
            },
        }

    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.get()
    }


    /**
    * @memberof ModalTran
    * @method get
    * @description Obtiene la informacion de la transacciones para el modal de detalle. Actualiza el state
    * con informacion obtenida.
    */
    get = () => {
        this.setState({ loading: true })
        axios.get(`/transacciones-programadas/${this.props.transaccion_programada_id}`, {
            params: {
                id: this.props.transaccion_programada_id
            }

        }).then(({ data }) => {
            this.setState({ transaccion: data });
        })
            .catch(error => {
                console.log('error', error)
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    renderEstatus = (abono) => {
        if (abono.monto == abono.monto_pagado)
            return <Tag color="#87d068">Pagada</Tag>

        if (moment(abono.fecha_corte).isBefore(moment()))
            return <Tag color="#F50">Vencida</Tag>
        else if (moment().isBetween(moment(abono.fecha_corte).subtract(abono.dias_limite, "days"), moment(abono.fecha_corte)))
            return <Tag color="#FBBC30">Proxima</Tag>
        else
            return <Tag color="#87d068">Pendiente</Tag>
    }

    render() {
        const { transaccion } = this.state;

        console.log("transaccion", transaccion?.abonos)
        return (
            <Spin spinning={false}>
                <Form layout="vertical"   >
                    <Row justify="center">
                        <Col span={12} >
                            <Form.Item label="Folio" >
                                <Text type="secondary" >{transaccion.folio}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Concepto" >
                                <Text type="secondary" >{transaccion.concepto}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Fecha de Vigencia" >
                                <Text type="secondary" >{moment(transaccion.fecha).format('LL')}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Area" >
                                <Text type="secondary" >{transaccion.area_id?.nombre}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Rubro" >
                                <Text type="secondary" >{transaccion.rubro_id?.nombre}</Text>
                            </Form.Item>
                        </Col>

                        <Col span={12} >
                            <Form.Item label="Cuenta" >
                                <Space direction='horizontal' align='center' size={0} >
                                    <CustomAvatar
                                        image={`${axios.defaults.baseURL}/upload/${transaccion.cuenta_id?.logo}`}
                                        color={transaccion.cuenta_id?.color}
                                        name={transaccion.cuenta_id?.nombre}
                                    />
                                    <Text ellipsis className="text-gray-dark">{transaccion.cuenta_id.nombre ? transaccion.cuenta_id.nombre : 'No Asignado'}</Text>
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col span={12} >
                            <Form.Item label="Proyecto" >
                                <Space direction='horizontal' align='center' size={0} >
                                    <CustomAvatar
                                        image={`${axios.defaults.baseURL}/upload/${transaccion.proyecto_id?.logo}`}
                                        color={transaccion.proyecto_id?.color}
                                        name={transaccion.proyecto_id?.nombre}
                                    />
                                    <Text ellipsis className="text-gray-dark">{transaccion.proyecto_id.nombre ? transaccion.proyecto_id.nombre : 'No Asignado'}</Text>
                                </Space>
                            </Form.Item>
                        </Col>
                        {
                            transaccion.propiedad_id && (
                                <Col span={12} >
                                    <Form.Item label="Propiedad" >
                                        <NavLink to={'/admin/crm/clientes/' + transaccion.propiedad_id?.cliente_id + '/propiedad/' + transaccion.propiedad_id?._id}>

                                            <Space direction='horizontal' align='center' size={0} >
                                                <CustomAvatar
                                                    image={`${axios.defaults.baseURL}/upload/${transaccion.propiedad_id?.logo}`}
                                                    name={transaccion.propiedad_id?.inmueble_id?.nombre}
                                                />
                                                <Text ellipsis className="text-gray-dark">{transaccion.propiedad_id?.inmueble_id?.nombre || 'No Asignado'}</Text>
                                            </Space>

                                        </NavLink>
                                    </Form.Item>
                                </Col>
                            )
                        }
                        {
                            transaccion.inversion_id && (
                                <Col span={12} >
                                    <NavLink to={'/admin/financial-health/inversion/detalle/' + transaccion.inversion_id?._id}>
                                        <Form.Item label="Inversión" >
                                            <Space direction='horizontal' align='center' size={0} >
                                                <CustomAvatar
                                                
                                                    image={`${axios.defaults.baseURL}/upload/${transaccion.inversion_id?.logo}`}
                                                    color={transaccion.inversion_id?.color}
                                                    name={transaccion.inversion_id?.nombre}
                                                />
                                                <Text ellipsis className="text-gray-dark">{transaccion.inversion_id?.nombre ? transaccion.inversion_id.nombre : 'No Asignado'}</Text>
                                            </Space>
                                        </Form.Item>
                                    </NavLink>
                                </Col>
                            )
                        }
                        {
                            transaccion.acreedor_id && (
                                <Col span={12} >
                                    <NavLink to={'/admin/acreedores/detalle/' + transaccion.acreedor_id?._id}>
                                        <Form.Item label="Acreedor" >
                                            <Text type="secondary" >{transaccion.acreedor_id?.acreedor ? transaccion.acreedor_id.acreedor : 'No Asignado'}</Text>
                                        </Form.Item>
                                    </NavLink>
                                </Col>
                            )
                        }
                        {
                            transaccion.inversion_id && (
                                <Col span={12} >
                                    <NavLink to={'/admin/financial-health/inversion/detalle/' + transaccion.inversion_id?._id}>
                                        <Form.Item label="Acreedor" >
                                            <Text type="secondary" >{transaccion.inversion_id?.nombre ? transaccion.inversion_id?.acreedor : 'No Asignado'}</Text>
                                        </Form.Item>
                                    </NavLink>
                                </Col>
                            )
                        }
                        <Col span={24} >
                            <Form.Item label="Periodo" >
                                <Text type="secondary" >{
                                    transaccion.periodo === 1 ? "Pago único" :
                                        transaccion.periodo === 2 ? "Semanal" :
                                            transaccion.periodo === 3 ? "Quincenal" : "Mensual"
                                }</Text>
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item label="Monto Total" >
                                <Statistic
                                    valueStyle={{ fontSize: 16 }}
                                    value={transaccion.monto}
                                    precision={2}
                                    prefix={'$'} suffix={"MXN"} />
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item label="Monto Pagado" >
                                <Statistic
                                    valueStyle={{ fontSize: 16, color: '#00FF19' }}
                                    value={transaccion.monto_pagado}
                                    precision={2}
                                    prefix={'$'} suffix={"MXN"} />
                            </Form.Item>
                        </Col>
                        <Col span={8} >
                            <Form.Item label="Monto Pendiente" >
                                <Statistic
                                    valueStyle={{ fontSize: 16, color: '#FF0000' }}
                                    value={transaccion.monto_pendiente}
                                    precision={2}
                                    prefix={'$'} suffix={"MXN"} />
                            </Form.Item>
                        </Col>
                        {(transaccion?.abonos?.length > 0) && <List
                            className="abonos-list"
                            size="small"
                            bordered={false}
                            dataSource={transaccion?.abonos}
                            locale={{ emptyText: null, }}
                            style={{ width: "100%", maxHidth: 300 }}
                            renderItem={(abono) => (
                                <List.Item style={{ flex: 1, justifyContent: "space-between" }}>
                                    <div>
                                        {moment(abono.fecha_corte).format("LL")} <br />
                                        {this.renderEstatus(abono)}
                                    </div>
                                    <Typography.Text>{abono.monto_pagado?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / {abono.monto?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Typography.Text>
                                </List.Item>
                            )}
                        />}
                    </Row>
                </Form>
            </Spin >

        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title="Detalle Transacción"
        closable={true}
        destroyOnClose={true}
        footer={<Button
            onClick={onClose}
            type="primary"
        >
            Cerrar
        </Button>}

    >
        <ModalTransaccionProgramadaDetalle {...props} />
    </Modal>

}