import React, { Component } from 'react';
import { Row, Col, Card, Button, Spin, Tag, Layout, Space, List, Typography, Avatar, message } from 'antd'

import axios from 'axios';

//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { renderEstatusRequisicion } from "../../../Utils";
import { IconFlag, IconEye } from '../../../Widgets/Iconos';
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

//Modal
import ModalRequisicionDetalle from "../../Requisiciones/ModalRequisicionDetalle"


const { Content } = Layout;
const { Text } = Typography;
const moment = require("moment");

let colors_flag = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}

export class Requisiciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            requisiciones:{
                data: [],
                page: 1,
                limit: 10,
                total: 0
            }            
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getRequisiciones()
        
    }


     /**
     * @methodOf Requisiciones
     * @function getRequisiciones
     * @description Otiene el listado de requeridos
     */
    getRequisiciones = ({
        page,
        limit,
        search,
    } = this.state.requisiciones) => {

        this.setState({loading: true})
        axios.get("/requisiciones",{
            params:{
                page,
                limit,
                search,
                entrega: true,
                almacen_id:this.props.almacen_id
            }
        }).then(({data}) => {
            this.setState({
                requisiciones: {
                    data: data.data,
                    page: data.page,
                    total: data.total,
                    limit: data.limit
                }
            })

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener las requisiciones")

        }).finally(()=>this.setState({loading: false}))

    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <Content className="admin-content content-bg">
                        <List
                            loading={false}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Requisiciones " }}
                            dataSource={this.state.requisiciones.data}
                            pagination={{
                                current: this.state.requisiciones.page,
                                pageSize: this.state.requisiciones.limit,
                                total: this.state.requisiciones.total,
                                position: 'bottom',
                                className: "flex-left",
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" gutter={[16, 16]}>
                                <Col span={6} className="center ">
                                    <Text strong>Producto</Text>
                                </Col>
                                <Col span={4} className="center ">
                                    <Text strong>Estatus</Text>
                                </Col>
                                <Col span={3} className="center ">
                                    <Text strong>Volumen</Text>
                                </Col>
                                <Col span={4} className="center ">
                                    <Text strong>Proyecto</Text>
                                </Col>
                                <Col span={2} className="center ">
                                    <Text strong>Prioridad</Text>
                                </Col>
                                <Col span={4} className="center ">
                                    <Text strong>Fecha</Text>
                                </Col>
                            </Row>}
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">

                                            <Col span={6} className="center ">
                                                <Text>{item.producto_id.nombre}</Text>
                                            </Col>
                                            <Col span={4} className="center ">
                                                {renderEstatusRequisicion(item.estatus)}
                                            </Col>
                                            <Col span={3} className="center ">
                                                <Text>{item.volumen} {item.unidad_id?.nombre ?? "Unidades"}</Text>
                                            </Col>
                                            <Col span={4} className="center ">
                                                <CustomAvatar
                                                    name={item.proyecto_id?.nombre}
                                                    color={item.proyecto_id?.color}
                                                />
                                                <Text ellipsis>{item.proyecto_id?.nombre}</Text>
                                            </Col>
                                            <Col span={2} className="center ">
                                                
                                                <IconFlag fill={colors_flag[item.prioridad] ? colors_flag[item.prioridad] : '#A4A4A4'}/>
                                            </Col>
                                            <Col span={4} className="center ">
                                            <Text>{moment().format("DD/MM/YYYY")}</Text>
                                            </Col>
                                            <Col span={1} className="center">
                                                    
                                                <Button
                                                    type="primary"
                                                    icon={<IconEye/>}
                                                    className="center"
                                                    onClick={()=>this.setState({modal_visible_detalle: true, requisicion_id: item._id})}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />
                    <ModalRequisicionDetalle
                        visible={this.state.modal_visible_detalle}
                        onClose={(flag)=>{
                            this.setState({modal_visible_detalle: false, requisicion_id: undefined})
                            
                        }}
                        requisicion_id={this.state.requisicion_id}
                    />


                    
                </Spin>
            </>
        )
    }
}


export default function (props) {

    
    

    return <Requisiciones {...props} />

}