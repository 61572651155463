import React from 'react'
import { Layout, Col, Row, PageHeader, message, Space, Select, Pagination } from "antd";
// import '../../Styles/Modules/Dashboard/dashboard.css';

import { CardSaldosGraph, CardGastoMensual, CardGastoAnual, CardDashboardGraph } from '../Widgets/Cards';

const { Content } = Layout;
const axios = require('axios')
export default class Dashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      proyectos: {
        data: [],
        limit: 10,
        page: 1,
        total: 0,
        search: undefined
      },
      proyecto_id: undefined
    }
  }

  componentDidMount() {
    this.getProyectos()
  }


  getProyectos = ({
    page = this.state.proyectos.page,
    limit = this.state.proyectos.limit,
    search = this.state.proyectos.search
  } = this.state.proyectos) => {
    this.setState({loading: true})
    axios.get('/proyectos'
    ).then(({ data }) => {
      this.setState({
        proyectos: {...data, page, limit, search}
      })
    }).catch(error => {
      console.log(error)
      message.error("Error al obtener los proyectos")
    }).finally(() => {
      this.setState({loading: false})
    })
  }

  render() {
    return (
      <>
        <PageHeader
          className="site-page-header custom-page-header"
          title=" Dashboard"
          extra={
            <Select
                style={{width: 250}}
                placeholder="Proyecto"
                allowClear
                showSearch
                onSearch={search => this.getProyectos({search})}
                onClear={() => this.setState({proyecto_id: undefined})}
                onSelect={proyecto_id => this.setState({proyecto_id})}
            >
                {this.state.proyectos.data.map(proyecto => {
                    return <Select.Option value={proyecto._id}>{proyecto.nombre}</Select.Option>
                })}
            </Select>
          }
        />
        <Content className="pd-1 dashboard-content-bg">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Row gutter={[16, 16]} className='mb-1'>
                <Col span={8} lg={8} md={24} sm={24} xs={24}>
                  <CardSaldosGraph proyecto_id={this.state.proyecto_id}/>
                </Col>
                <Col span={8} lg={8} md={24} sm={24} xs={24}>
                  <CardGastoMensual proyecto_id={this.state.proyecto_id}/>
                </Col>
                <Col span={8} lg={8} md={24} sm={24} xs={24}>
                  <CardGastoAnual proyecto_id={this.state.proyecto_id}/>
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify='center'>
                <Col span={24}>
                  <CardDashboardGraph 
                    proyecto_id={this.state.proyecto_id}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </>
    )
  }
}