import React, { Component, useEffect } from "react";
import { Typography, Button, Modal, Form, Input, message, Spin, Row, Col, Divider, Select, Space, Tooltip, InputNumber } from 'antd'

import { SelectProveedor } from "../../../Widgets/Inputs/Selects"

const { Option } = Select
const axios = require('axios').default;


/**
 * @class ModalProductoProveedor
 * @extends {Component}
 */
class ModalProductoProveedor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    ModalProductos = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

    }


    /**
     *
     * @memberof ModalProductoProveedor
     *
     * @method onFinish
     * @description Se ejecuta al ahcer submit al formulario
     */
    onFinish = (values) => {
        this.addRequisiscionOpcion(values)
    }


    /**
     *
     * @memberof ModalProductoProveedor
     *
     * @method addRequisiscionOpcion
     * @description añade una nueva requissicion opcion a la requisiscion
     */
    addRequisiscionOpcion = (values) => {
        this.setState({loading: true})
        axios.post('requisicion-opcion',{
            ...values,
            requisicion_id: this.props.requisicion_id
        }).then(response => {
            console.log("response", response.data);
            this.props.addOpcion({...response.data})

        }).catch((error)=>{
            message.error(error?.response?.data?.message ?? "Error al añadir el nuevo proveedor")
        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    name="form-productos"
                    ref={this.ModalProductos}
                    onFinish={this.onFinish}
                    className="pd-1"
                >
                    <Row gutter={[16, 16]} className="w-100">
                        <Col span={24}>
                            <Form.Item
                                label="SKU Proveedor"
                                name="sku_proveedor"

                                rules={[
                                    {
                                        required: true,
                                        message: "Indique el sku del proveedor"
                                    }
                                ]}
                            >
                                <Input placeholder="Nombre" ></Input>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Nombre" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Costo"
                                name="costo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el costo"
                                }]}
                            >
                                <InputNumber
                                    disabled={false}
                                    min={0.01}
                                    className="width-100"
                                    decimalSeparator=","
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Proveedor"
                                }]}
                            >
                                <SelectProveedor/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, producto_id, proveedor_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={"Añadir Proveedor al Producto"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-productos', key: 'submit', htmlType: 'submit' }}
    >
        <ModalProductoProveedor {...props} />
    </Modal>

}