import React, { Component } from 'react';
import { Card, Layout, Modal, Form, Input, message, Spin, Row, Col, Typography, Tag, Space, Tooltip, InputNumber } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";



import './Cards.scss'

const { Content } = Layout;
const { Title, Text } = Typography;

export class CardProducto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            producto: {}
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        

        this.getProducto()


    }

    getProducto = () => {
        axios.get(`/productos/${this.props.producto_id}`)
            .then(res => {
                
                this.setState({
                    producto: res.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }


    render() {
        return (
            <>
                <Card className={`card-shadow card-producto ${this.props.className}`}>
                    <Row align='middle' className='mb-2'>
                        <Col span={9}>
                            <Text className='card-title'>Producto</Text>
                        </Col>
                        <Col span={15}>
                            <Row justify='end'>
                                <Tag className='tag-categ'>{this.state.producto?.categoria_id?.nombre}</Tag>
                                <Tag className='tag-subcateg'>{this.state.producto?.sub_categoria_id?.nombre}</Tag>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Text className='sub-title'> SKU - See Saw</Text>
                        </Col>
                        <Col span={24}>
                            <Text className='title'>{this.state.producto?.sku}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="mt-1">
                            <Text className='sub-title'>Nombre</Text>
                        </Col>
                        <Col span={24}>
                            <Text className='title'>{this.state.producto?.nombre}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="mt-1">
                            <Text className='sub-title'> Fabricante / SKU</Text>
                        </Col>
                        <Col span={24}>
                            <Text className='title'>{this.state.producto?.sku_fabricante}</Text>
                        </Col>
                    </Row>
                </Card>
            </>
        )
    }
}



export default function (props) {



    return <CardProducto {...props} />
}