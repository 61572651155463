import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Card, Typography, Collapse, Divider, Progress, Select, Form, Modal} from "antd";
import axios from "axios";
import { IconDelete, IconEdit } from "../../Widgets/Iconos";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { CardPrespuesto, CardProyecto, CardTransaccionesClasificacion } from '../../Widgets/Cards';

//css
import '../../../Styles/Modules/budgeting.css'

const { Content } = Layout;
const { Text, Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

/**
 * @export
 * @class Conceptos
 * @extends {Component}
 * @description Vista de Conceptos
 */
export default class Conceptos extends Component {
    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            modalVisible: false,
            concepto_id: undefined,
            subconcepto_id: undefined,
            conceptos: [],
            suma_presupuesto: 0,
            suma_ejercido: 0,
            promedio: 0,
            proyectos: {
                data: [],
                limit: 12,
                page: 1,

                total: 0,
                pages: 0,
            },
            proyecto: {},
            presupuesto: {presupuesto: 0},
            ejercido: {ejercido: 0},
            presupuesto_ingresos: {presupuesto_ingresos: 0},
            ultimo_registro:{}
        };
    }

    proyectoRef = React.createRef()

    componentDidMount() {
        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");

        this.getProyectos()
    }

    /**
     * @memberof Conceptos
     * @method getConceptos
     * @description Obtiene la informacion de los Usuarios y actualiza los valores locales de la lista.
     */
    getConceptos = async (proyecto_id) => {

        this.setState({ loading: true})

        axios.get('/conceptos/budgeting', {
            params: {
                proyecto_id
            }
        })
            .then(({ data }) => {
                console.log("respoddd", data);

                let suma_presupuesto = 0, suma_ejercido = 0, promedio = 0

                for( const val of data.conceptos ){
                    suma_presupuesto += val.presupuesto
                    suma_ejercido += val.ejercido
                    promedio += val.avance
                }

                if(data.conceptos.length > 0){
                    promedio = promedio / data.conceptos.length 
                }

                this.setState({ 
                    conceptos: data.conceptos,
                    suma_presupuesto,
                    suma_ejercido,
                    promedio,
                    proyecto: data.proyecto,
                    presupuesto: data.presupuesto,
                    ejercido: data.ejercido,
                    presupuesto_ingresos: data.presupuesto_ingresos,
                    ultimo_registro: data.ultimo_registro,
                    porcentaje_clasificadas: data.porcentaje_clasificadas,
                    no_clasificadas: data.no_clasificadas
                })
            })
            .catch(error => {
                console.log("error", error);
                if(error.response.status === 401)
                    message.error(error.response.data.message)
                else
                    message.error('Error al obtener la información')
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     *
     * @memberof Proyectos
     * @method getProyectos
     * @description Obtiene la informacion de los proyectos y actualiza los valores locales de la lista.
     */
    getProyectos = async (
        {
            page = this.state.proyectos.page,
            limit = this.state.proyectos.limit,
            search = this.props.search,
        } = this.state.proyectos
    ) => {

        axios.get("/proyectos", { 
            params: { 
                limit, 
                page, 
                search 
            } 
        })
            .then(({ data }) => {
                this.setState({
                    proyectos: data,
                })

                if(data.data.length > 0){
                    this.getConceptos(data.data[0]._id)
                    this.proyectoRef.current.setFieldsValue({
                        proyecto_id: data.data[0]._id
                    })
                }else{
                    Modal.warning({
                        title: 'No hay ningún proyecto registrado'
                    })
                }

            })
            .catch((error) => {
                console.log("error", error);
                message.error("Error al obtener los proyectos")
            })
            .finally(() => this.setState({ loading: false }))
    }



    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row>
                                <Col span={18}>
                                    <Title level={4} style={{margin: '0', color: 'white'}}>Budgeting</Title>
                                </Col>
                                <Col span={6}>
                                    <Form
                                        ref={this.proyectoRef}
                                    >
                                        <Form.Item
                                            name="proyecto_id"
                                            style={{margin: '0'}}
                                        >
                                            <Select
                                                className="select-header"
                                                placeholder="proyecto"
                                                onSelect={(proyecto_id)=>this.getConceptos(proyecto_id)}
                                            >
                                                {this.state.proyectos.data.map(proyecto => {
                                                    return <Option value={proyecto._id}>{proyecto.nombre}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                        }
                    />

                    <Content className="admin-content content-bg pd-1 budgeting">
                        <Row gutter={[16, 16]} className="mb-1">
                            <Col span={8} className="grid">
                                <CardProyecto {...this.state.proyecto}/>
                            </Col>
                            <Col span={8} className="grid">
                                <CardPrespuesto 
                                    presupuesto={this.state.presupuesto.presupuesto}
                                    ejercido={this.state.ejercido.ejercido}
                                    presupuesto_ingresos={this.state.presupuesto_ingresos.presupuesto_ingresos}
                                    fecha_ultima_transaccion={this.state.ultimo_registro?.fecha}
                                    monto_ultima_transaccion={this.state.ultimo_registro?.monto}
                                />
                            </Col>
                            <Col span={8} className="grid">
                                <CardTransaccionesClasificacion
                                    porcentaje={this.state.porcentaje_clasificadas}
                                    no_clasificadas={this.state.no_clasificadas}
                                />
                            </Col>
                        </Row>
                        <Row className="row-blue">
                            <Col span={6} className="center">
                                <Text>Concepto</Text>
                            </Col>
                            <Col span={6} className="center">
                                <Text>Presupuesto</Text>
                            </Col>
                            <Col span={6} className="center">
                                <Text>Ejercido</Text>
                            </Col>
                            <Col span={6} className="center">
                                <Text>Avance</Text>
                            </Col>
                        </Row>
                        <List
                            loading={this.state.loading}
                            dataSource={this.state.conceptos}
                            locale={{ emptyText: "Sin Conceptos" }}
                            renderItem={item => {

                                return <Collapse
                                    bordered={false}
                                    defaultActiveKey={['1']}
                                    expandIcon={({ isActive }) => isActive ? <MinusOutlined style={{ marginTop: 8, color: '#ffffff', fontSize: 16 }} /> :
                                        <PlusOutlined style={{ marginTop: 8, color: '#ffffff', fontSize: 16 }} />}
                                    className=""
                                >
                                    <Panel
                                        className="collapse-list"
                                        extra={
                                            <Row className="header-collapse">

                                                <Col span={6} className="flex-left">
                                                    <Text ellipsis>{item.nombre}</Text>
                                                </Col>

                                                <Col span={6} className="center">
                                                    <Text ellipsis>$ {item.presupuesto ? item.presupuesto.toMoney(true) : 0.00} MXN</Text>
                                                </Col>

                                                <Col span={6} className="center">
                                                    <Text ellipsis>$ {item.ejercido ? item.ejercido.toMoney(true) : 0.00} MXN</Text>
                                                </Col>

                                                <Col span={6} className="center" style={{ paddingRight: '1.5rem' }}>
                                                    <Progress 
                                                        percent={item.avance.toMoney()}
                                                        style={{margin: '4px 0'}} 
                                                    />
                                                </Col>

                                            </Row>
                                        }
                                    >

                                        {
                                            item.subconceptos?.map((subconcepto) => {

                                                const { nombre, presupuesto, concepto_id, _id, ejercido, avance } = subconcepto;

                                                return (
                                                    <Link to={`/admin/proyectos/transacciones/` + subconcepto._id}>
                                                        <Row className="mt-1 border-bottom">
                                                            <Col span={6} className="flex-left">
                                                                <Text ellipsis>{nombre}</Text>
                                                            </Col>

                                                            <Col span={6} className="center">
                                                                <Text ellipsis>$ {presupuesto ? presupuesto.toMoney(true) : 0.00} MXN</Text>
                                                            </Col>

                                                            <Col span={6} className="center">
                                                                <Text ellipsis>$ {ejercido ? ejercido.toMoney(true) : 0.00} MXN</Text>
                                                            </Col>

                                                            <Col span={6} className="center" style={{ paddingRight: '1.5rem' }}>
                                                                <Progress 
                                                                    percent={avance ? avance.toMoney() : 0}
                                                                    style={{margin: '4px 0'}}
                                                                    size="small"
                                                                />
                                                                    
                                                            </Col>

                                                        </Row>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </Panel>
                                </Collapse>

                            }}
                        />
                        <Row className="row-blue-divider">
                            <Col span={6} className="center">
                                
                            </Col>
                            <Col span={6} className="center">
                                <Text>$ {this.state.suma_presupuesto.toMoney(true)} MXN</Text>
                            </Col>
                            <Col span={6} className="center">
                                <Text>$ {this.state.suma_ejercido.toMoney(true)} MXN</Text>
                            </Col>
                            <Col span={6} className="center">
                                <Text>{this.state.promedio.toMoney(true)} %</Text>
                            </Col>
                        </Row>
                    </Content>


                </Spin>
            </>
        );
    }
}
