import React, { Component, useContext } from "react";
import { Button, Col, Row, Typography, Card, List, Space, message, Popconfirm, Modal, Avatar, Statistic, Tooltip } from "antd";
import { ClockCircleFilled, CloseOutlined, DeleteOutlined, DollarCircleFilled, EditOutlined, EyeOutlined, FilePdfOutlined, LinkOutlined, PlusOutlined } from "@ant-design/icons"
import axios from 'axios'


import { MdOutlineEmail, MdOutlineMarkEmailRead } from "react-icons/md"


//compoenentes
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { FloatingMenu, MainButton, ChildButton, } from 'react-floating-button-menu';
import ModalTransaccion from "../../Finanzas/Transacciones/ModalTransaccion";
import ModalTransaccionDetalle from "../../Finanzas/Transacciones/ModalTransaccionDetalle";
import usePermissions from "../../../../Hooks/usePermissions";
import User from "../../../../Hooks/Logged";


const { Text } = Typography;
const moment = require('moment');

/**
 * @export
 * @class TransaccionesTab
 * @extends {Component}
 * @description Lista de transacciones del cliente
 */
class TransaccionesTab extends Component {

	constructor(props) {
		super(props)
		this.state = {
			transacciones: {
				data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
			},
			acreedor_id: this.props.acreedor_id,
			transaccion_id: undefined
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
		this.get()
	}

	componentDidUpdate(prevProps){
		if(prevProps.acreedor_id !== this.props.acreedor_id) this.get()
	}

	/**
	 * @memberof TransaccionesTab
	 * @method get
	 * @description Obtiene los transacciones del cliente
	 *
	 */
	get = ({

		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,

		acreedor_id = this.props.acreedor_id,


	} = this.state.transacciones) => {

		this.setState({ loading: true })
		axios.get('/transacciones', {
			params: {
				acreedor_id,
				page,
				limit
				// tipo_pago
			}
		})
			.then(({ data }) => {
				// console.log("data", data);
				this.setState({ transacciones: { ...this.state.transacciones, ...data } });
			})
			.catch(error => {
				message.error('Error al traer los transacciones')
				console.log(error.response)
			}
			).finally(() => this.setState({ loading: false }))
	}

	/**
	 * @memberof TransaccionesTab
	 * @method desvincular
	 * @description Obtiene los transacciones del cliente
	 *
	 */
	desvincular = (transaccion_id) => {
		Modal.confirm({
			title: '¿Seguro de desvincular la transaccion?',
			okText: 'Si',
			cancelText: 'Cancelar',
			onOk: () => {
				return axios.put('/crm/desvincular', {
					transaccion_id
				}).then(response => {
					message.success('Transacción desvinculada')
					this.get()
				}).catch(error => {
					console.log("error", error);
					message.error('Error al desvincular')
				}).finally(e => this.props.updateAll())
			}
		})
	}


	render() {

		const { data } = this.state.transacciones

		return (
			<>
				<Row gutter={[8, 8]}>
					<List
						itemLayout="horizontal"
						dataSource={data}
						loading={this.state.loading}
						key={(item => item._id)}
						className="component-list width-100"
						pagination={{
							current: this.state.transacciones.page,
							pageSize: this.state.transacciones.limit,
							total: this.state.transacciones.total,
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.get({ page, limit })
						}}
						renderItem={item => (
							<List.Item className="component-list-item transacciones">
								<Card className="ant-card-list width-100">
									<Row className="width-100" gutter={[16, 16]}>
										<Col xs={24} lg={3} className="center">
											<Text className="text-date-format fecha">{moment(item.fecha).format('DD-MM-YYYY')}</Text>
										</Col>
										<Col xs={24} lg={6} className="center">
											<Text className="concepto">{item.concepto}</Text>
										</Col>
										<Col xs={24} lg={4} className="center">
											<Text className="concepto">{item.area_id?.nombre}</Text>
										</Col>
										<Col xs={24} lg={3} className="center">
											<Text className="concepto">{item.cuenta_id?.nombre ?? ((item.tipo_pago == 2) ? "Especie" : null)}</Text>
										</Col>
										<Col xs={24} lg={4} className="center">
											{(item.tipo == 2) ?
												<Statistic
													value={item.monto}
													valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#FF0000' }}
													prefix={'$'}
													suffix={'MXN'}
												/> :
												<Statistic
													value={item.monto}
													valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF19' }}
													prefix={'$'}
													suffix={'MXN'}
												/>
											}
										</Col>
										<Col xs={24} lg={4} align="right">
											<Space direction="horizontal" size={5}>
												<Tooltip mouseEnterDelay={0} title="Enviarle el Recibo de Pago al Cliente">
													<Button
														size="small"
														className={(item?.mensaje?.enviado) ? "ant-btn-primary-lime" : "ant-btn-primary-cyan"}//"ant-btn-primary-cyan"
														type="primary"
														//disabled={!this.props.enviarTransaccionesAcreedores}
														icon={(item?.mensaje?.enviado) ? <MdOutlineMarkEmailRead style={{ color: "currentcolor", marginTop: 3, fontSize: 16 }} /> : <MdOutlineEmail style={{ color: "currentcolor", marginTop: 3, fontSize: 16 }} />}
														onClick={() => Modal.confirm({

															title: "¿Deseas envíar el comprobante de pago al cliente?",
															content: "Se enviará un correo al cliente con el comprobante adjunto",
															onOk: () => axios.get('/transaccion/recibo/' + item._id, { params: { send: true } }).then(() => {
																this.get()
																message.success("Se ha enviado correctamente")
															}).catch(() => {
																message.error("No fue posible envíar el correo")
															})
																.finally(e => {})

														})}
													/>
												</Tooltip>
												<Tooltip mouseEnterDelay={0} title="Ver el recibo de pago de esta transacción">
													<Button
														size="small"
														className="ant-btn-primary-gold"
														type="primary"
														//disabled={!this.props.verTransaccionesAcreedores}
														icon={<FilePdfOutlined style={{ color: "currentcolor" }} />}
														onClick={() => window.open(axios.defaults.baseURL + "/transaccion/recibo/" + item._id + "?Authorization=" + sessionStorage.getItem("token"))}
													/>
												</Tooltip>
												<Tooltip mouseEnterDelay={0} title="Ver la transacción">
													<Button
														size="small"
														className="ant-btn-primary-green"
														type="primary"
														//disabled={!this.props.verTransaccionesAcreedores}
														icon={<EyeOutlined style={{ color: "currentcolor" }} />}
														onClick={() => this.setState({modalDetalleVisible: true, transaccion_id: item._id, tipo_pago: item.tipo_pago})}
													/>
												</Tooltip>
												<Tooltip mouseEnterDelay={0} title="Desvincular la transacción de la propiedad y el cliente">
													<Button
														size="small"
														className="ant-btn-primary-yellow"
														type="primary"
														//disabled={!this.props.desvincularTransaccionesAcreedores}
														icon={<LinkOutlined style={{ color: "currentcolor" }} />}
														onClick={() => this.desvincular(item._id)}
													/>
												</Tooltip>
												<Tooltip mouseEnterDelay={0} title="Editar la transacción">
													<Button
														//disabled={!this.props.editarTransaccionesAcreedores}
														size="small"
														type="primary"
														icon={<EditOutlined style={{ color: "currentcolor" }} />}
														onClick={() => this.setState({modalVisible: true, transaccion_id: item._id})}
													/>
												</Tooltip>
												<Popconfirm
													placement="topRight"
													title="¿Deseas eliminar esta transaccion?"
													onConfirm={() => axios.delete(`/transacciones`,
														{ params: { id: item._id } }).then(() => {
															this.get()
															message.success('Transaccion eliminada')
														}).catch(error => console.log(error))
														.finally(e => this.props.updateAll())
													}
													okText="Si"
													cancelText="No"
												>
													<Button
														//disabled={!this.props.eliminarTransaccionesAcreedores}
														size="small"
														type="primary"
														icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
														title="Eliminar"
														danger
													/>
												</Popconfirm>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Row>
				<ModalTransaccion
					visible={this.state.modalVisible}
					onClose={() => {
						this.setState({modalVisible: false, transaccion_id: undefined})
						this.props.updateAll()
					}}
					transaccion_id={this.state.transaccion_id}
					acreedor_id={this.state.acreedor_id}
					tipo_pago={this.state.tipo_pago}
					area={false}
					rubro={false}
					cliente={false}
					propiedad={false}
					factura={null}
					cuenta={((this.state.tipo_pago === 2) ? null : true)}
					proyecto={false}
					tipo={false}
					inversion={false}
					acreedor={false}
					transaccion_programada={false}
				/>
				<ModalTransaccionDetalle
					visible={this.state.modalDetalleVisible}
					onClose={() => this.setState({modalDetalleVisible: false, transaccion_id: undefined})}
					transaccion_id={this.state.transaccion_id}
				/>
			</>
		)
	}
}

export default React.forwardRef((props, ref) => {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
		verTransaccionesAcreedores: ["acreedores", "transacciones", "read"],
		enviarTransaccionesAcreedores: ["acreedores", "transacciones", "send"],
		desvincularTransaccionesAcreedores: ["acreedores", "transacciones", "unlink"],
        editarTransaccionesAcreedores: ["acreedores", "transacciones", "edit"],
        eliminarTransaccionesAcreedores: ["acreedores", "transacciones", "delete"]
    });

	return <TransaccionesTab ref={ref} {...props} user={user} {...permisos}/>
})