import React, { Component } from 'react';
import { PageHeader, Layout, Modal, Form, Input, message, Spin, Row, Col, Tabs, Select, Space, Tooltip, InputNumber, List } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";

import Producto from './Cards/Producto';
import CardProveedores from './Cards/Proveedores';
import Precios from './Cards/Precios';

import Almacenes from './Sections/Almacenes';
import Proveedores from './Sections/Proveedores';
import Requisiciones from './Sections/Requisiciones';
import BitacoraInventario from './Sections/BitacoraInventario';
import HistorialPrecios from './Sections/HistorialPrecios';

import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions'; 


const { Content } = Layout;

export class DetalleProducto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            producto: {},
            seccion : 0
        }
    }

    

    ModalProductos = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        console.log(this.props.params)

        this.getProducto()
        
    }

    getProducto = () => {
        axios.get(`/productos/${this.props.params.producto_id}`)
            .then(res => {
                
                this.setState({
                    producto: res.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }


    render() {

        let items = [
            { label: 'Almacenes', key: 0, children: this.props.accessInventario ? <Almacenes producto_id={this.props.params.producto_id}/> : "No tiene acceso a este modulo"}, // remember to pass the key prop
            { label: 'Proveedores', key: 1, children: <Proveedores producto_id={this.props.params.producto_id}/> },
            { label: 'Requisiciones', key: 2, children: <Requisiciones/> },
            { label: 'Bitacora de Inventario', key: 3, children: this.props.accessBitacoras ? <BitacoraInventario producto_id={this.props.params.producto_id}/> : "No tiene acceso a este modulo" },
            { label: 'Historial de Precios', key: 4, children: <HistorialPrecios producto_id={this.props.params.producto_id}/> },
            
          ];

        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={"Productos / " + this.state.producto.nombre + " / " + items[this.state.seccion].label}
                />
                <Content className="admin-content content-bg pd-1">
                    <Row gutter={[16,16]}>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Producto producto_id={this.props.params.producto_id}/>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <CardProveedores producto_id={this.props.params.producto_id}/>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Precios producto_id={this.props.params.producto_id}/>
                        </Col>
                    </Row>
                    <Row>
                        <Tabs items={items} destroyInactiveTabPane className="width-100" onChange={(e) =>  this.setState({seccion: e})}/>
                    </Row>
                </Content>
                
            </>
        )
    }
}



export default function (props) {

    const params = useParams()

    let user = React.useContext(Logged)


    let permissions = usePermissions(user?.rol_id?.permisos, {
        
        accessInventario: ["almacenes-productos", "access"],
        accessBitacoras: ["almacenes-bitacoras", "access"],

    })

    return <DetalleProducto {...props} {...permissions} params={params} navigate={useNavigate()}/>
}