import React, { Component } from 'react';
import { Avatar, Spin, PageHeader, Layout, Row, Col, Typography, message, Space, } from 'antd'
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";

import {
    IconArrowBack, IconWalletBG,
    IconMoneyBG,
    IconHistorialBG,
    IconBilleteBG,
} from '../../Widgets/Iconos';
import { MoreOutlined, RightOutlined, } from "@ant-design/icons"
import { CardGraphMontosComparacion, CardIconoMoney, CardListaMontos, CardPagosPendientesPorcentajes } from '../../Widgets/Cards';
import axios from 'axios';

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;
const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class TransaccionesProgramadasDashboard
 * @extends {Component}
 * @description Vista de dashboard de transacciones programadas
 */
class TransaccionesProgramadasDashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            crm: {
                data: []
            },
            inversiones: {
                data: []
            },
            acreedores: {
                data: []
            },
            validaciones: {
                data: []
            },
            data_montos: [
                {
                    _id: 1,
                    fecha: new Date(),
                    monto: '12321.98',
                    concepto: 'Concepto'
                },
                {
                    _id: 1,
                    fecha: new Date(),
                    monto: '12321.98',
                    concepto: 'Concepto'
                },
                {
                    _id: 1,
                    fecha: new Date(),
                    monto: '12321.98',
                    concepto: 'Concepto'
                },
                {
                    _id: 1,
                    fecha: new Date(),
                    monto: '12321.98',
                    concepto: 'Concepto'
                },
                {
                    _id: 1,
                    fecha: new Date(),
                    monto: '12321.98',
                    concepto: 'Concepto'
                },

            ],
            por_recibir: 0,
            proximo: 0,
            recibido: 0,
            vencido: 0,
            ingresos: [],
            egresos:[]
        }
    }



    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.get()
        this.getInfo()
    }


    getInfo = () => {
        console.log("getInfo");
        axios.get("/transacciones-programada/dashboard/data",{
            params:{}
        })
        .then(response => {
            console.log("response", response.data);
            this.setState({
                ...response.data
            })

        }).catch(error => {
            console.log("error", error);

        })
    }

    get = () => {
		this.setState({ loading: true })
		axios.get('/transacciones-programadas/dashboard')
			.then(({ data }) => {
				console.log("data", data);
				this.setState({ ...data });
			})
			.catch(error => {
				message.error('Error al traer los transacciones programadas')
				console.log(error.response)
			}
			).finally(() => this.setState({ loading: false }))
	}



    render() {
        return (
            <Spin spinning={false}>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Cash Flow"
                />
                <Content className="admin-content content-bg pd-1">
                    <Row gutter={[8, 8]}>
                        
                    <Col span={6}>
                            <CardIconoMoney
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                title="Vencidos"
                                icon={<IconWalletBG />}

                                // ingreso_vencido: vencidos ? vencidos.ingreso : 0,
                                // egreso_vencido: vencidos ? vencidos.egreso : 0,
                                first_subtitle="Pagos Vencidos"
                                second_subtitle="Cobros Vencidos"
                                first={this.state.egreso_vencido}
                                second={this.state.ingreso_vencido}
                            />
                        </Col>
                        <Col span={6}>
                            <CardIconoMoney
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                title="Por Vencer"
                                // monto={1254.25}
                                first_subtitle="Pagos por Vencer"
                                second_subtitle="Cobros por Vencer"
                                first={this.state.egreso_por_vencer}
                                second={this.state.ingreso_por_vencer}

                                icon={<IconMoneyBG />}
                            />

                        </Col>
                        <Col span={6}>
                            <CardIconoMoney
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                // title="Pagos Recibidos"
                                // // monto={1254.25}
                                // monto={this.state.recibido?.toMoney()}

                                title="Recibidos"
                                // monto={1254.25}
                                first_subtitle="Pagos Recibidos"
                                second_subtitle="Cobros Recibidos"
                                first={this.state.ingreso_recibido}
                                second={this.state.egreso_recibido}


                                icon={<IconHistorialBG />}
                            />

                        </Col>
                        <Col span={6}>
                            <CardIconoMoney
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                
                                title="Por Recibir"
                                first_subtitle="Pagos por Recibir"
                                second_subtitle="Cobros por Recibir"
                                first={this.state.ingreso_por_recibir}
                                second={this.state.egreso_por_recibir}
                                icon={<IconBilleteBG />}
                            />
                        </Col>
                        <Row gutter={[8, 8]} className=' width-100'>
                            <Col span={24}>
                                <CardGraphMontosComparacion  ingresos={this.state.ingresos} egresos={this.state.egresos}/>
                            </Col>

                            <Col lg={6} md={12} xs={24}>
                                <CardListaMontos
                                    title='Validación'
                                    data={this.state.validaciones?.data}
                                    extra={<Link to={'#'}>Ver Todos<RightOutlined /></Link>} />
                            </Col>
                            <Col lg={6} md={12} xs={24}>
                                <CardPagosPendientesPorcentajes
                                    header='Acreedores'
                                    title={(item) => <Title level={5}>{item.acreedor_id?.acreedor}</Title>}
                                    data={this.state.acreedores?.data}
                                    extra={<Link to={'/admin/acreedores'}>Ver Todos<RightOutlined /></Link>}
                                />
                            </Col>
                            <Col lg={6} md={12} xs={24}>
                                <CardPagosPendientesPorcentajes
                                    header='CRM'
                                    title={(item) => <Space align='center' wrap={true}>
                                        <Title level={5} >{item.propiedad_id?.cliente_id?.nombre}</Title>
                                        <Text style={{marginBottom: '0.5em', display: 'block'}}>{item.propiedad_id?.inmueble_id?.nombre}</Text>
                                    </Space>}
                                    data={this.state.crm?.data}
                                    extra={<Link to={'/admin/crm/clientes'}>Ver Todos<RightOutlined /></Link>}
                                />
                            </Col>
                            <Col lg={6} md={12} xs={24}>
                                <CardPagosPendientesPorcentajes
                                    header='Inversiones'
                                    title={(item) => <Title level={5}>{item.inversion_id?.nombre}</Title>}
                                    data={this.state.inversiones?.data}
                                    extra={<Link to={'/admin/financial-health/inversiones'}>Ver Todos<RightOutlined /></Link>}
                                />
                            </Col>

                        </Row>
                    </Row>


                </Content>
            </Spin>
        )
    }
}

export default function () {
    const location = useLocation()

    return <TransaccionesProgramadasDashboard
        params={useParams()}
        navigate={useNavigate()}
        location={location}
    />
}