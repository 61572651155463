import React, { Component } from "react";
import { Row, Col, Button, Modal, Form, DatePicker, Input, InputNumber, Select, message, Spin } from 'antd';

import { SelectProyecto, SelectEmpresa } from "../../../Widgets/Inputs/Selects"

const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');

/**
 * @class ModalCotizacion
 * @description Modal que se utiliza para crear cotizaciones (facturas estatus de -1) relacionadas a un proveedor
 */
class ModalCotizacion extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proveedor: undefined,
            disabled: false,
        }
    }

    ModalCotizacion = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.factura_id) {
            this.getFactura();
        }
    }





    /**
     * @memberof ModalCotizacion
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {
        if (this.props.factura_id) {
            this.updateFactura(values)
        } else {
            this.addFactura(values)
        }
    }


    /**
     * @memberof ModalCotizacion
     * @method getFactura
     * @description Se ejecuta al si el modal tiene factura_id en las propiedades y obtiene los datos para
     * llenar el formulario y editar
     * 
     */
    getFactura = () => {
        this.setState({ loading: true })

        axios.get(`/facturas/${this.props.factura_id}`, {
            params: { 
                id: this.props.factura_id,
            },
        }).then(async response => {
            let factura = response.data.data
            this.ModalCotizacion.current?.setFieldsValue({
                concepto: factura.concepto,
                fecha: moment(factura.fecha),
                descripcion: factura.descripcion,
                tipo: factura.tipo,
                monto: factura.monto,
                empresa_id: factura.empresa_id?._id ? {
                    value: factura.empresa_id?._id,
                    key: factura.empresa_id?._id,
                    label: factura.empresa_id?.alias,
                } : null,
                proyecto_id: factura.proyecto_id?._id ? {
                    value: factura.proyecto_id?._id,
                    key: factura.proyecto_id?._id,
                    label: factura.proyecto_id?.nombre,
                } : null,
            })

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la transaccion')
        }).finally(() => {

            this.setState({ loading: false, disabled: true })
        })
    }


    /**
     * @memberof ModalCotizacion
     * @method addFactura
     * @description Agrega la factura a la base de datos
     */
    addFactura = (values) => {        
        this.setState({ loading: true }, () => {
            axios.post('/facturas', {
                ...values,
                estatus: -1,
                proveedor_id: this.props.proveedor_id,
                monto_pesos: parseFloat(values.monto_pesos).toMoney(),
            }).then(response => {
                message.success('Factura creada')
                this.props.onClose()
            }).catch(error => {
                console.log(error)
                message.error(error.response?.data?.message ?? 'Error al generar la factura')
            }).finally(() => this.setState({ loading: false }))
        })
        
    }

    /**
    * @memberof ModalCotizacion
    * @method updateFactura
    * @description Actualiza la informacion de una factura
    */
    updateFactura = (values) => {
        this.setState({ loading: true })
        axios.put('/facturas', {
            ...values,
            monto: values.monto.toMoney(),
            id: this.props.factura_id
        }).then(response => {
            message.success('Factura Actualizada')
            this.props.onClose()
        }).catch(error => {
            console.log(error)
            message.error('Error al guardar')
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        const { disabled } = this.state;
        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalCotizacion}
                onFinish={this.onFinish}
                initialValues={{
                    fecha: moment(),
                    tipo: 2,
                }}

            >
                <Spin spinning={this.state.loading}>

                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]} 
                            >
                                <Input placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input placeholder="Descripción" ></Input>

                            </Form.Item>
                        </Col>
                        

                        <Col  xs={24} lg={11}>
                            <Form.Item 
                                label="Tipo"
                                name="tipo"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo"
                                }]}
                            >
                                <Select
                                    disabled={disabled}
                                    placeholder="Seleccione tipo">
                                    {!this.props.cliente_id && <Option value={0}>Sin Cambios</Option>}
                                    <Option value={1}>Ingreso</Option>
                                    <Option value={2}>Egreso</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2  }} >
                            <Form.Item
                                label="Monto (Pesos)"
                                name="monto"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber disabled={false} min={0.01} defaultValue={0} className="width-100" decimalSeparator="," onChange={this.onChangePesos}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                    />
                                
                            </Form.Item>
                        </Col>

                        <Col  xs={24} lg={11}>
                            <Form.Item 
                                label="Proyecto"
                                name="proyecto_id"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el proyecto"
                                }]}
                            >
                                <SelectProyecto/>
                            </Form.Item>
                        </Col>
                        <Col  xs={24}  lg={{ span: 11, push: 2  }}>
                            <Form.Item 
                                label="Empresa"
                                name="empresa_id"
                            >
                                <SelectEmpresa/>
                            </Form.Item>
                        </Col>
                    </Row>

                    

                    <Row>
                    </Row>
                    
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, factura_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={`${factura_id != null ? "Editar " : "Nueva "} Cotización`}
        /* footer={null} */
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-materiales', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCotizacion {...props} />
    </Modal>

}