import React, { Component, useEffect } from "react";
import { Typography, Button, Modal, Form, Input, message, Spin, Row, Col, Divider, Select, Space, Tooltip, InputNumber, Radio } from 'antd'


import { DeleteOutlined, EditOutlined, PlusCircleTwoTone, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import ModalProductoAlmacen from "./ModalProductoAlmacen";

const { Text } = Typography

const { Option } = Select

const axios = require('axios').default;




/**
 *
 *
 * @class ModalProductos
 * @extends {Component}
 */
class ModalBitacoraInventario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            almacen_id: this.props.almacen_id,
            producto_id: undefined,
            productoExiste: true,
            almacen: {},
            producto: {},
            proveedores: {},

            proyectos: {
                data: [],
                limit: 12,
                page: 1,

                total: 0,
                pages: 0,
                inputValue: null
            },

            inmuebles: {
                data: [],
                limit: 12,
                page: 1,

                total: 0,
                pages: 0,
                inputValue: null
            },

            almacenes: {
                data: [],
                limit: 12,
                page: 1,

                total: 0,
                pages: 0,
                inputValue: null
            },

            productos: {
                data: [],
                limit: 12,
                page: 1,

                total: 0,
                pages: 0,
                inputValue: null
            },

            usuarios: {
                data: [],
                limit: 12,
                page: 1,

                total: 0,
                pages: 0,
                inputValue: null
            },

            categoria: null,
            subcategoria: null
        }
    }

    ModalProductos = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProductos()
        this.getProyectos()
        this.getAlmacenes()
        this.getUsuarios()
        if (this.props.almacen_id !== undefined) {
            this.getAlmacen()
        }
    }



    /**
     * @memberof ModalBitacoraInventario
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {

        console.log(values)

        if (this.props.bitacora_id !== undefined) {
            this.updateProducto(values)
        }
        else {
            this.addBitacora(values)
        }

    }





    /**
     * @memberof ModalProductos
     * @method getProducto
     * @description Obtiene una producto de la DB
     */
    getAlmacen = () => {
        this.setState({ loading: true })
        axios.get(`/almacenes/${this.state.almacen_id}`).then(({ data }) => {

            console.log("almacen", data)
            this.setState({
                almacen: data,
                almacenes: {
                    data: [data]
                }
            })
            this.ModalProductos.current.setFieldsValue({
                almacen_id: data._id,
                proyecto_id: data.proyecto_id._id,
            })
        }).catch(error => {
            message.error('Error al traer área')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalProductos
     * @method getProducto
     * @description Obtiene una producto de la DB
     */
    getProducto = () => {
        this.setState({ loading: true })
        axios.get(`/productos/${this.state.producto_id}`).then(({ data }) => {

            console.log("producto", data)
            this.setState({
                producto: data,
                productos: {
                    data: [data]
                }
            })
            this.ModalProductos.current.setFieldsValue({
                producto_id: data._id,
            })
        }).catch(error => {
            message.error('Error al traer área')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberOf ModalAlmacen
     * @method getProyectos
     * @descripcion Obtiene la lista de proyectos
     */
    getProyectos = ({
        page = this.state.proyectos.page,
        limit = this.state.proyectos.limit,
        search = this.state.proyectos.search,
    } = this.state.proyectos) => {
        this.setState({ categorias: { page, limit, search, inputValue: null } })
        axios.get('/proyectos', {
            params: { page, limit, search }
        })
            .then(({ data }) => {
                this.setState({ proyectos: { ...this.state.proyectos, ...data } })
            })
            .catch(error => {
                message.error('Error al obtner las los proyectos')
            })

    }

    /**
     * @method addUser
     * @description Añade un nuevo registro de usuario
     */
    getInmuebles = ({
        limit = this.state.inmuebles.limit,
        page = this.state.inmuebles.page,
        search = this.state.inmuebles.search,
    } = this.state.inmuebles) => {
        this.setState({ loading: true, inmuebles: { ...this.state.inmuebles, limit, page } });
        axios.get("/inmuebles", {
            params: {
                limit,
                page,
                search,
                proyecto_id: this.ModalProductos?.current?.getFieldValue("proyecto_id")
            }
        })
            .then(({ data }) => {
                this.setState(state => {
                    state.inmuebles = { ...data }
                })
            })
            .catch((error) => {
                console.log("error", error);
                message.error("Error al obtener el proyecto");
            })
            .finally(() => this.setState({ loading: false }));
    };

    getAlmacenes = ({
        page = this.state.almacenes.page,
        limit = this.state.almacenes.limit,
        search = this.state.almacenes.search,
    } = this.state.almacenes) => {
        this.setState({ almacenes: { ...this.state.almacenes, page, limit, search, inputValue: null } })
        axios.get('/almacenes', {
            params: { page, limit, search }
        })
            .then(({ data }) => {
                this.setState({ almacenes: { ...this.state.almacenes, ...data } })
            })
            .catch(error => {
                message.error('Error al obtner las los almacenes')
            })

    }

    /**
     * @memberOf ModalProductoAlmacen
     * @method getProyectos
     * @descripcion Obtiene la lista de proyectos
     */
    getProductos = ({
        page = this.state.productos.page,
        limit = this.state.productos.limit,
        search = this.state.productos.search,
    } = this.state.productos) => {
        this.setState({ productos: { ...this.state.productos, page, limit, search, inputValue: null } })
        axios.get('/productos', {
            params: { page, limit, search }
        })
            .then(({ data }) => {
                this.setState({ productos: { ...this.state.productos, ...data } })
            })
            .catch(error => {
                message.error('Error al obtner las los productos')
            })

    }

    /**
        * @memberof ModalAreas
        * @method addArea
        * @description Añade una area a la BD
        */
    addBitacora = (values) => {
        axios.post('/almacenes-bitacoras', {
            ...values
        }).then(response => {


            message.success('Bitacora creada.')
            this.props.onClose()


        }).catch(error => {
            console.log(error)
            message.error('Error al crear Almancén.')
        })
    }

    /**
     * @memberof ModalAreas
     * @method updateArea
     * @description Actualiza la información de una area
     */
    updateBitacora = (values) => {
        this.setState({ loading: true })
        axios.put('/almacenes-bitacoras', {
            ...values,
            id: this.props.almacen_id,
        }).then(response => {
            message.success('Almacen Actualizado')
            this.props.onClose()
        }).catch(error => {
            message.error('Error al actualizar Almacen')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     * @memberof Usuarios
     * @method getUsuarios
     * @description Obtiene la informacion de los Usuarios y actualiza los valores locales de la lista.
     */
    getUsuarios = async ({
        page = this.state.usuarios.page,
        limit = this.state.usuarios.limit,
        search = this.props.search
    } = this.state.usuarios) => {

        this.setState({ loading: true, usuarios: { ...this.state.usuarios, page, limit } })

        axios.get('/usuarios', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                this.setState({ usuarios: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }))
    }

    checkProductoExiste = () => {
        let producto_id = this.ModalProductos?.current?.getFieldValue("producto_id")
        let almacen_id = this.ModalProductos?.current?.getFieldValue("almacen_id")

        console.log(producto_id, almacen_id)
        if (!producto_id || !almacen_id) return

        axios.get('/almacenes-check-producto', {
            params: {
                producto_id,
                almacen_id
            }
        }).then(({ data }) => {
            if (data === false) {
                this.setState({ productoExiste: false, almacen_id: almacen_id, producto_id: producto_id })
            }
        }).catch(error => {
            console.log(error)
        })
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    name="form-productos"
                    ref={this.ModalProductos}
                    onFinish={this.onFinish}
                    className="pd-1"

                >
                    <Row gutter={[16, 16]} className="w-100">
                        <Col span={24}>
                            <Form.Item
                                label="Producto"
                                name="producto_id"
                            >
                                <Select
                                    placeholder="Producto"
                                    className="form-select select-actions"
                                    onChange={producto => this.setState({ producto_id: producto }, () => { this.getProducto(); this.checkProductoExiste() })}
                                    disabled={this.props.producto_id ? true : false}
                                    onSearch={search => this.getProductos({ search })}
                                    showSearch
                                    filterOption={false}

                                    optionLabelProp="label"
                                >
                                    {this.state.productos.data?.map((producto, index) => {


                                        return <Option
                                            className="option-actions "
                                            key={producto._id}
                                            value={producto._id}
                                            label={producto.nombre}

                                        >
                                            {producto?.nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="Volumen"
                                name="volumen"
                            >
                                <InputNumber
                                    disabled={false}
                                    min={0.01}
                                    className="width-100"
                                    addonAfter={this.state.producto?.unidad?.simbolo ? this.state.producto.unidad.simbolo : this.state.producto?.unidad?.nombre}
                                    decimalSeparator=","
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Proyecto"
                                name="proyecto_id"
                                rules={[{
                                    required: false,
                                    message: "Por favor, seleccione el Proveedor"
                                }]}
                                disabled={this.props.almacen_id ? true : false}
                            >
                                <Select
                                    placeholder="Proyecto"
                                    className="form-select select-actions"
                                    onChange={() => this.getInmuebles()}
                                    disabled={!this.props.id && this.state.sku_cargado}
                                    onSearch={search => this.getProyectos({ search })}
                                    showSearch
                                    filterOption={false}

                                    optionLabelProp="label"
                                >
                                    {this.state.proyectos.data?.map((categoria, index) => {


                                        return <Option
                                            className="option-actions "
                                            key={categoria._id}
                                            value={categoria._id}
                                            label={categoria.nombre}
                                            codigo={categoria.codigo}
                                        >
                                            {categoria?.nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Inmueble"
                                name="inmueble_id"
                                rules={[{
                                    required: false,
                                    message: "Por favor, seleccione un inmueble"
                                }]}
                            >
                                <Select
                                    placeholder="Inmueble"
                                    className="form-select select-actions"

                                    disabled={!this.state.inmuebles.data?.length}
                                    onSearch={search => this.getInmuebles({ search })}
                                    showSearch
                                    filterOption={false}

                                    optionLabelProp="label"
                                >
                                    {this.state.inmuebles.data?.map((inmueble, index) => {


                                        return <Option
                                            className="option-actions "
                                            key={inmueble._id}
                                            value={inmueble._id}
                                            label={inmueble.nombre}
                                            codigo={inmueble.codigo}
                                        >
                                            {inmueble?.nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Almacen"
                                name="almacen_id"
                            >
                                <Select
                                    placeholder="Almacen"
                                    className="form-select select-actions"
                                    disabled={this.props.almacen_id ? true : false}
                                    onSearch={search => this.getAlmacenes({ search })}
                                    showSearch
                                    filterOption={false}

                                    optionLabelProp="label"
                                >
                                    {this.state.almacenes.data?.map((almacen, index) => {


                                        return <Option
                                            className="option-actions "
                                            key={almacen._id}
                                            value={almacen._id}
                                            label={almacen.nombre}

                                        >
                                            {almacen?.nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Almacen Destino"
                                name="almacen_destino_id"
                            >
                                <Select
                                    placeholder="Almacen"
                                    className="form-select select-actions"
                                    onSearch={search => this.getAlmacenes({ search })}
                                    showSearch
                                    filterOption={false}

                                    optionLabelProp="label"
                                >
                                    {this.state.almacenes.data?.map((almacen, index) => {


                                        return <Option
                                            className="option-actions "
                                            key={almacen._id}
                                            value={almacen._id}
                                            label={almacen.nombre}

                                        >
                                            {almacen?.nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Tipo"
                                name="tipo">
                                <Radio.Group>
                                    <Radio value={1}>Entrada</Radio>
                                    <Radio value={2}>Salida</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Personal"
                                name="contratista_id"
                                rules={[{
                                    required: false,
                                    message: "Por favor, seleccione un personal"
                                }]}
                            >
                                <Select
                                    placeholder="Usuario"
                                    className="form-select select-actions"


                                    onSearch={search => this.getUsuarios({ search })}
                                    showSearch
                                    filterOption={false}

                                    optionLabelProp="label"
                                >
                                    {this.state.usuarios.data?.map((usuario, index) => {


                                        return <Option
                                            className="option-actions "
                                            key={usuario._id}
                                            value={usuario._id}
                                            label={usuario.nombre}

                                        >
                                            {usuario?.nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <ModalProductoAlmacen
                    visible={!this.state.productoExiste}
                    onClose={() => { this.setState({ productoExiste: true, producto_id: undefined, almacen_id: undefined }); this.getProductos() }}
                    almacen_id={this.state.almacen_id}
                    producto_id={this.state.producto_id}
                />
            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, producto_id, proveedor_id, bitacora_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={bitacora_id ? "Editar Bitácora de Inventario" : "Agregar Bitácora de Inventario"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-productos', key: 'submit', htmlType: 'submit' }}
    >
        <ModalBitacoraInventario {...props} />
    </Modal>

}