import React, { Component } from "react";
import { Modal, Form, Input, message, Spin, Tree } from 'antd';

const axios = require('axios').default;

/**
 * @class ModalConceptos
 * @extends {Component}
 */
class ModalConceptos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false  
        }
    }

    ModalConceptos = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if(this.props.concepto_id){
            this.getConcepto()
        }
    }


    onFinish = (values) => {
        if(this.props.concepto_id){
            this.updateConcepto(values)
        }else{
            this.addConcepto(values)
        }
    }

    /**
     * @memberof ModalConceptos
     * 
     * @method addConcepto 
     * @description Añade un nuevo concepto
     */
    addConcepto = (values) => {
        this.setState({ loading: true })
        axios.post('/concepto',{
            ...values,
            proyecto_id: this.props.proyecto_id
        }).then(()=>{
            message.success('¡Concepto creado!')
            this.props.onClose(true)
        }).catch((error)=>{
            console.log("error", error);
            if(error?.response?.status === 403)
                message.error(error?.response?.data?.message)
            else
                message.error('Error al crear el concepto')
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalConceptos
     * 
     * @method getConcepto 
     * @description Obtiene la informacion de un concepto
     */
    getConcepto = () => {
        this.setState({ loading: true })
        axios.get(`/concepto/${this.props.concepto_id}`)
        .then((response)=>{
            this.ModalConceptos.current.setFieldsValue({
                ...response.data
            })
        }).catch((error)=>{
            console.log("error", error);
            message.error('Error al obtener el concepto')
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalConceptos
     * 
     * @method updateConcepto 
     * @description Añade un nuevo concepto
     */
    updateConcepto = (values) => {
        this.setState({ loading: true })
        axios.put('/concepto',{
            ...values,
            concepto_id: this.props.concepto_id
        }).then(()=>{
            message.success('¡Concepto actualizado!')
            this.props.onClose(true)
        }).catch((error)=>{
            console.log("error", error);
            if(error?.response?.status === 403)
                message.error(error?.response?.data?.message)
            else
                message.error('Error al actualizar el concepto')
        }).finally(()=>this.setState({loading: false}))
    }

    render() {
        return (
            <Form
                layout="vertical"
                name="form-concepto"
                ref={this.ModalConceptos}
                onFinish={this.onFinish}
                className="pd-1"
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                    >
                        <Input placeholder="Nombre" ></Input>
                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, concepto_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={concepto_id ? "Editar Concepto" : "Crear Concepto"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-concepto', key: 'submit', htmlType: 'submit' }}
    >
        <ModalConceptos {...props} />
    </Modal>

}