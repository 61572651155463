import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { User, SetUser } from './Hooks/Logged';
import axios from 'axios'
//componentes
import Routes from './Routes'

//css
import './Styles/Theme/antd-theme-edit.css';
import './Styles/Global/global.css';


axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.interceptors.request.use(function (config) {
	config.headers.Authorization = sessionStorage.getItem("token")
	return config;
})


/**
 * 
 * @class App
 * @extends {Component}
 */
class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			user: 0,
		}
	}

	componentDidMount() {
		axios.get('/user/logged', {
			headers: { Authorization: sessionStorage.getItem('token') }
		}).then(({ data }) => {
			this.setUser(data.data)
		}).catch((error) => {
			console.log('error', error.response)
		})
	}

	setUser = (user) => {
		this.setState({ user })
	};

	render() {
		const { setUser } = this;
		const { user } = this.state;
		return (
			<BrowserRouter>
				<User.Provider value={user}>
					<SetUser.Provider value={setUser}>
						<Routes />
					</SetUser.Provider>
				</User.Provider>
			</BrowserRouter>
		)
	}
}

export default App;
