import React from 'react'
import { Route, Routes } from "react-router-dom";

import Productos from '../components/Admin/Productos/Productos'
import DetalleProducto from '../components/Admin/Productos/DetalleProducto'



/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterAreas(props) {
    return (
        <Routes>
            <Route path="" element={<Productos {...props} />} />
            <Route path=":producto_id" element={<DetalleProducto  {...props}  />} />
        </Routes>
    )
}

export default RouterAreas;
