import React, { Component } from 'react';
import { Layout, Button, Col, Popconfirm, Row, message, Card ,PageHeader, List, Spin, Typography, Space, Tooltip, Collapse } from 'antd'
import axios from 'axios'
import { BankOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

//componentes
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import FloatingButton from '../../Widgets/Floating Button/FloatingButton';
import { IconDelete, IconEdit, IconBankError, IconSATSCR } from '../../Widgets/Iconos';
import { tipo_servicio } from '../../Utils'

//modales
import ModalEmpresas from './ModalEmpresas';
import ModalConfiguraciónSyncfy from '../Syncfy/ModalConfiguraciónSyncfy';
import ModalConfiguraciónTaxpayer from '../Syncfy/ModalConfiguraciónTaxpayer';

import Logged from '../../../Hooks/Logged'
import { usePermissions } from '../../../Hooks/usePermissions'



const { Text } = Typography;
const { Content } = Layout;
const { Panel } = Collapse;

/**
 *
 *
 * @export
 * @class Empresas
 * @extends {Component}
 */
class Empresas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [],
            id: null,
            empresas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            }
        }
    }


    /**
     *
     *
     * @memberof Empresas
     * 
     * @method componentDidMount 
     * @description Cargamos la informacion de los Empresas
     */

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getEmpresas()
    }

    /**
     *
     * @memberof Empresas
     * @method componentDidUpdate
     * @description Si se realiza una busqueda, filtra las empresas
     */
     componentDidUpdate(prevProps){
        if(this.props.search !== prevProps.search)
            this.getEmpresas()
    }


    /**
     *
     * @memberof Empresas
     * @method getEmpresas
     * @description Obtiene la informacion de los Empresas y actualiza los valores locales de la lista.
     */
    getEmpresas = ({
        page = this.state.empresas.page,
        limit = this.state.empresas.limit,
        search = this.props.search
    } = this.state.empresas) => {
        this.setState({ loading: true})
        axios.get('/empresas', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                this.setState({ empresas: data })
            })
            .catch(res => {
                message.error(res.response.data.message ?? "Error al obtener las empresas")
            })
            .finally(()=>this.setState({loading: false}));
    }


    /**
     * @methodOf  Empresas
     * @function showModal
     *
     * @description Muestra el modal de la Empresa
     *
     * */
    showModal = (id) => {
        this.setState({
            visible: true,
            id: id
        });
    };

    /**
     * @methodOf  Empresas
     * @function hideModal
     *
     * @description Oculta el modal de la Empresa
     *
     * */
    hideModal = () => {
        this.setState({
            visible: false,
            id: undefined
        });
    };


    render() {
        const { getEmpresas, showModal, hideModal } = this;
        const { visible, empresas, id } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Empresas"
                    />
                    <Content className="admin-content content-bg pd-1">

                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin Empresas " }}
                                loading={this.state.loading}
                                dataSource={empresas.data}
                                pagination={{
                                    current: empresas.page,
                                    pageSize: empresas.limit,
                                    total: empresas.total,
                                    onChange: (page, limit) => {
                                        this.getEmpresas({ page, limit })
                                    }
                                }}
                                header={<Row className="header-list width-100 pr-1 pl-1" >
                                    <Col span={5} className="center">
                                        <Text strong>Alias</Text>
                                    </Col>
                                    <Col span={5} className="center">
                                        <Text strong>Razón Social</Text>
                                    </Col>
                                    <Col span={5} className="center">
                                        <Text strong>descripción</Text>
                                    </Col>
                                    <Col span={5} className="center">
                                        <Text strong>RFC</Text>
                                    </Col>
                                </Row>}
                                renderItem={(item) => {

                                    return <List.Item className="component-list-item">
                                    <Card className="card-list">
                                    <Row className="width-100 ">
                                        <Col span={5} className="flex-left">
                                            <CustomAvatar 
                                                image={ item.logo ? axios.defaults.baseURL + '/upload/' + item.logo : null} 
                                                name={item.alias} 
                                                color={item.color} 
                                            /> 
                                            <Text ellipsis={true}>{item.alias}</Text>
                                        </Col>
                                        <Col span={5} className="center">
                                            <Text ellipsis={true}>{item.razon_social}</Text>
                                        </Col>
                                        <Col span={5} className="center">
                                            <Text ellipsis={true} >{item.descripcion}</Text>
                                        </Col>
                                        <Col span={5} className="center">
                                            <Text >{item.rfc}</Text>
                                        </Col>
                                            

                                        <Col span={4} className="center">
                                            <Space>
                                                <Tooltip
                                                    title={
                                                        (item.razon_social && item.rfc) ?
                                                            (!item.syncfy_taxpayer ? "Asignar el Certifcado y la Llave para Timbrar" : "Actualizar el Certifcado y la Llave para Timbrar")
                                                            : "Es necesario tener la razón social y el RFC para poder realizar el enlace"

                                                    }>
                                                    <Button

                                                        disabled={!this.props.enlaceFiscal || !(item.razon_social && item.rfc)}
                                                        type={!!item.syncfy_taxpayer ? "primary" : undefined}
                                                        icon={<IconSATSCR width={18} color="currentColor" ballsColor={(!!item.syncfy_taxpayer) ? "currentColor" : undefined} style={{ position: "relative", top: 1 }} />}
                                                        onClick={() => this.setState({ empresa_id: item._id, modalKeys: true })}
                                                    />
                                                </Tooltip>
                                                <Tooltip
                                                    title={
                                                        (item.razon_social && item.rfc) ?
                                                            (item.syncfy_usuario_id && item.syncfy_credenciales_id ? "Realizar el Enlace" : "Modificar el enlace")
                                                            : "Es necesario tener la razón social y el RFC para poder realizar el enlace"

                                                    }>
                                                    <Button
                                                        disabled={!this.props.facturasEnlaceFiscal || !(item.razon_social && item.rfc)}
                                                        onClick={() => this.setState({ syncfy_id: item._id, modalSyncfy: true })}
                                                        danger={!(item.syncfy_usuario_id && item.syncfy_credenciales_id)}
                                                        type="primary"
                                                        icon={
                                                            (item.syncfy_usuario_id && item.syncfy_credenciales_id)
                                                                ?
                                                                <BankOutlined style={{ fontSize: 22, color: "white", position: "relative", bottom: 2 }} />
                                                                :
                                                                <IconBankError color="white" style={{ position: 'relative', bottom: 2 }} />
                                                        }
                                                        title="Eliminar"
                                                        style={{ backgroundColor: (item.syncfy_usuario_id && item.syncfy_credenciales_id) ? "#1890ff" : undefined, border: (item.syncfy_usuario_id && item.syncfy_credenciales_id) ? "none" : undefined }}
                                                    />
                                                </Tooltip>
                                                <Button disabled={!this.props.editEmpresas} type="primary" icon={<EditOutlined />} onClick={() => showModal(item._id)} />
                                                <Popconfirm
                                                    placement="topRight"
                                                    disabled={!this.props.deleteEmpresas}
                                                    title="¿Deseas eliminar esta Empresa?"
                                                    onConfirm={() => axios.delete('/empresa/' + item._id).then((response) => {
                                                        message.success(response.data.message)
                                                        this.getEmpresas()
                                                    })
                                                        .catch((error) => {
                                                            console.log("error", error);
                                                            message.error(error.response?.data?.message ?? "Error al eliminar la empresa");
                                                            this.getEmpresas();
                                                        })
                                                    }
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        disabled={!this.props.deleteEmpresas}
                                                    type="primary" danger icon={<DeleteOutlined />} title="Eliminar" />
                                                </Popconfirm>
                                            </Space>

                                        </Col>
                                    </Row>
                                    </Card>
                                </List.Item>
                                    
                                }}
                            />
                        {this.props.createEmpresas ? <FloatingButton title="Nuevo registro" onClick={() => showModal()} /> : null}
                    </Content>
                </Spin>
                <ModalEmpresas
                    visible={visible}
                    hideModal={hideModal}
                    accept={() => {
                        hideModal();
                        getEmpresas();
                    }}
                    update={() => getEmpresas()}
                    id={id}

                />
                <ModalConfiguraciónSyncfy
                    visible={this.state.modalSyncfy}
                    id={this.state.syncfy_id}
                    onCancel={() => {
                        this.setState({ modalSyncfy: false, id: null, cuenta_id: undefined })
                        this.getEmpresas();
                    }}
                    tipo={2}
                />
                <ModalConfiguraciónTaxpayer
                    visible={this.state.modalKeys}
                    empresa_id={this.state.empresa_id}
                    onCancel={() => {
                        this.setState({ modalKeys: false, empresa_id: null, cuenta_id: undefined })
                        this.getEmpresas();
                    }}
                />
            </>

        )
    }
}


export default function (props) {
    
    
	let user = React.useContext(Logged)

    
	let permissions = usePermissions(user?.rol_id?.permisos, {
		createEmpresas: ["empresas", "create"],
		editEmpresas: ["empresas", "edit"],
		readEmpresas: ["empresas", "read"],
		deleteEmpresas: ["empresas", "delete"],
		facturasEnlaceFiscal: ["empresas", "facturas_enlace_fiscal"],
		enlaceFiscal: ["empresas", "enlace_fiscal"],
	})


    return <Empresas {...props} {...permissions}/>
}
