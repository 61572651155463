import React, {useContext, useState}  from 'react';
import { Space, Dropdown, Menu, Col, Row, Badge, Layout, Input, Typography, Button, Select, Popover, message, Form, InputNumber, Divider } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { BsBellFill } from "react-icons/bs";
import { DownOutlined } from '@ant-design/icons';
//componentes
import { User, SetUser } from '../../Hooks/Logged'

import '../../Styles/Global/header.scss'

const { Header } = Layout;
const { Search } = Input;
const { Title, Paragraph } = Typography;

/**
 * @const Navbar
 * @description Header del sistema
 * @param {*} {  showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal }
 */
const Navbar = ({ showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal, admin }) => {


	let user = useContext(User)
    let setUser = React.useContext(SetUser)
    const [redirect, setRedirect] = useState(false)

    /**
     * @const cerrarSesion
     * @description Cierra la sesion
     */
    const cerrarSesion = () => {
        setUser(undefined);
        sessionStorage.clear();
        setRedirect(true);

    };

	/**
	* @const UserMenu
	* @description Muestra el Menu del dashboard
	*/
	const UserMenu = () => {
			return (
				<Col flex="auto" className="header-content" >
					{redirect ? <Navigate to={'/'}  replace /> : null}
					<Row>
						<Col xl={18} lg={17} md={18} sm={16} >
							<div className="header-search">
								<Search
									placeholder="Buscar"
									onSearch={(e) => { setFilterSearch(e) }}
									id="inputFilterSearch"
									enterButton
									className="input-global-search"
								/>
							</div>
						</Col>
						<Col  xl={6} lg={7} md={6} sm={8}>
							<Row type="flex" justify="center" align="middle" className=" header-options">
								<Col flex={2} className="option-notificacion">
									{/* <Badge count={0}>
										<BsBellFill className="icon-bell" />
									</Badge> */}
								</Col>
								<Col flex={3} className="option-menu">
									<Dropdown
										arrow={true}
										overlay={
											<Menu>
												<Menu.Item key="0" onClick={cerrarSesion}>
													Cerrar Sesión
												</Menu.Item>
											</Menu>
										} trigger={['click']}>
										<div >
											<Link to='#' onClick={e => e.preventDefault()}
												style={{ marginLeft: '2rem' }}>
												{user?.nombre} <DownOutlined />
											</Link>
										</div>
									</Dropdown>
								</Col>
							</Row>
						</Col>
					</Row>	
				</Col>
			)
	}

	return (
		<Header className="header" >
			<Row type="flex" className="row-header-content" >
				<Col className="header-logo" sm={24} xs={24}>
					<div className="logo">
						<img src={'/img/logo_white.png'} alt="logo" />
					</div>
				</Col>
				{UserMenu()}
			</Row>
		</Header>
	);

}

export default Navbar;