import React from 'react'
import { Route, Routes } from "react-router-dom";

import Areas from '../components/Admin/Areas/Areas'
import Rubros from '../components/Admin/Rubros/Rubros'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterAreas(props) {
    return (
        <Routes>
            <Route path="" element={<Areas {...props} />} />
            <Route path="rubros/:area_id" element={<Rubros  {...props}  />} />
        </Routes>
    )
}

export default RouterAreas;
