import React, { Component } from "react";
import { Row, Col, Input, Modal, Form, message, Spin} from 'antd';

import axios from 'axios'

class ModalInmuebles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false

        }
    }

    //Referencia de form
    formInmueble = React.createRef();

    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.inmueble_id)
            this.getInmueble()
    }

    /**
    * @memberof ModalInmuebles
    * @method onFinish
    * @description Se ejecuta al realizar submit al formulario
    **/
    onFinish = (values) => {
        if (this.props.inmueble_id) {
            this.updateInmueble(values)
        } else {
            this.saveInmueble(values)
        }
    }

    /**
    * @memberof ModalInmuebles
    * @method getInmueble
    * @description Obtiena la informacion de un Inmueble
    **/
    getInmueble = () => {
        this.setState({ loading: true })
        axios.get(`/inmuebles/${this.props.inmueble_id}`, {
            params: {
                id: this.props.inmueble_id
            }
        })
        .then(({data}) => {
           this.formInmueble.current?.setFieldsValue({ ...data })

        }).catch(error => {
            message.error("Error al obtener el inmueble")
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalInmuebles
    * @method saveInmueble
    * @description Añade un nuevo inmueble relacionado al proyecto
    **/
    saveInmueble = (values) => {
        this.setState({ loading: true })
        axios.post('/inmuebles', {
            ...values,
            proyecto_id: this.props.proyecto_id,
            inmueble_padre_id: this.props.inmueble_padre_id,
        }).then(response => {
            message.success('Inmueble creado')
            this.props.onClose(true)
        }).catch(error => {
            message.error("Error al crear inmueble")
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberof ModalInmuebles
    * @method updateInmueble
    * @description Actualiza un inmueble
    **/
    updateInmueble = (values) => {
        this.setState({ loading: true })
        axios.put('/inmuebles', {
            ...values,
            id: this.props.inmueble_id,
        }).then(response => {
            message.success('Inmueble actualizado')
            this.props.onClose(true)
        }).catch(error => {
            message.error('Error al actualizar el inmueble')
        }).finally(() => this.setState({ loading: false }))
    }




    render() {


        return <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    name="form-inmueble"
                    ref={this.formInmueble}
                    onFinish={this.onFinish}
                >
                    <Row align="center" gutter={[16]}>
                        <Col xs={22}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[
                                    { required: true, message: "Ingrese el nombre del Inmueble" }
                                ]} >
                                <Input placeholder="Nombre" maxLength={50} />
                            </Form.Item>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Descripción" rows={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
    }
}

export default function (props) {
    const { visible = false, onClose = () => { }, inmueble_id,inmueble_padre_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={inmueble_padre_id ? 'Agregar Inmueble' : inmueble_id ? "Editar Inmueble" : "Crear Inmueble"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-inmueble', key: 'submit', htmlType: 'submit' }}
    >
        <ModalInmuebles {...props} />
    </Modal>

}
