import React from 'react';
import ReactEcharts from "echarts-for-react";
import { Space, Card, Col, Row, Typography, List, Statistic, Button, Image, Progress, Tooltip, Tag, message, Select, Pagination, Switch, Spin } from 'antd';

import { ArrowUpOutlined, ArrowDownOutlined, DollarOutlined, FlagFilled, MoreOutlined, CloseCircleFilled, WarningFilled, CheckCircleFilled } from "@ant-design/icons";

import { GoPrimitiveDot } from 'react-icons/go'


import { IconCheckMedal, IconFacturaElectronica, IconFinanzas, IconLoss, IconMaster, IconMenuDots, IconProfit, IconVisa, IconWarning, IconError, IconSuccess, IconEye, IconPDFColor } from './Iconos'
import { LightenDarkenColor } from 'lighten-darken-color';
import CustomAvatar from './Avatar/Avatar';
import Avatar from 'antd/lib/avatar/avatar';
import { Link } from 'react-router-dom';
import MultiProgress from 'react-multi-progress';
import '../../Styles/Global/cards.scss';

import crm_datasource from "./crm_card_datasource.json"
import { randomColor } from '../Utils';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';

const { Text, Title } = Typography;

const moment = require('moment');
moment.locale('es');

/**
 * CardSaldosGraph
 * 
 * @param {*}
 * @description Tarjeta de Saldos con Grafica para Dashboard
 */
const CardSaldosGraph = ({ proyecto_id }) => {

	const [loading, setLoading] = useState(false)
	const [data, setData] = useState({
		cuentas: [],
		monto: 0
	})

	const get = () => {
		setLoading(true)
		axios.get('/dashboard/saldos', {
			params: { proyecto_id }
		}).then(({ data }) => {
			setData(data)
		}).catch(error => {
			console.log(error)
			message.error("Error al obtener los saldos")
		}).finally(() => setLoading(false))
	}

	useEffect(() => {
		get()
	}, [proyecto_id])

	const renderUrl = (item) => {
		const urlParams = new URL(axios.defaults.baseURL + "/proyectos/logo/" + item._id)
		urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))
		urlParams.searchParams.set("name", item.logo?.name)
		return urlParams.href
	}

	return (
		<Card
			className="card-shadow card-saldos-dashboard card-dashboard"
			bordered={false}
			title={"Saldos"}
			loading={loading}
		>
			<Row gutter={[30, 30]}>
				<Col xs={24}>
					<Row gutter={[13, 13]}>
						<Col xs={24}>
							<Statistic
								value={data.monto}
								prefix={"$"}
								precision={2}
								valueStyle={{ fontSize: 32, fontWeight: 'bold' }}
							/>
						</Col>
						<Col xs={24}>
							<MultiProgress
								elements={data.cuentas.map(({ color, saldo }) => ({
									color,
									value: saldo / data.monto * 100,
									showPercentage: false
								}))}
								height={10}
								roundLastElement
							/>
						</Col>
					</Row>
				</Col>
				<Col xs={24}>
					<Row>

					</Row>

					<Row gutter={[7, 10]} align={"middle"} style={{ flexDirection: "column" }}>
						{data.cuentas.map(({ color, nombre, saldo, ...args }) => (
							<div style={{ flex: 1, flexDirection: "row", width: "100%", display: "flex", justifyContent: "space-between" }}>
								<Space >
									<CustomAvatar color={color} name={nombre} image={renderUrl({ ...args, nombre, color })} />
									<Text>{nombre}</Text>
								</Space>
								<Statistic
									value={saldo}
									valueStyle={{ fontSize: 14, fontWeight: 'bold' }}
									precision={2}
									prefix="$"
								/>
							</div>
						))}
					</Row>
				</Col>
			</Row>
		</Card>
	)
}

/**
 * CardGastoMensual
 * 
 * @param {*}
 * @description Tarjeta de Gasto Mensual con Lista Top 5 Conceptos/Rubros para Dashboard
 */
const CardGastoMensual = ({ proyecto_id }) => {

	const [loading, setLoading] = useState(false)
	const [data, setData] = useState({
		total: 0,
		anterior: 0,
		porcentaje: 0,
		ranking: [],
		tipo: "conceptos"
	})

	const get = ({ tipo } = data) => {
		setLoading(true)
		axios.get('/dashboard/gastos', {
			params: { proyecto_id, tipo, plazo: "month" }
		}).then((res) => {
			setData({ ...res.data, tipo })
		}).catch(error => {
			console.log(error)
			message.error("Error al obtener el gasto mensual")
		}).finally(() => {
			setLoading(false)
		})
	}

	useEffect(() => {
		get()
	}, [proyecto_id])


	return (
		<Spin spinning={loading}>
			<Card
				bordered={false}
				className='card-venta-mensual-dashboard'
			>
				<Row>
					<Col xs={19}>
						<Title level={5}>Monto de Gasto Mensual</Title>
					</Col>
					<Col xs={5}>
						<Switch
							style={{ width: '100%' }}
							checkedChildren="Rubros"
							unCheckedChildren="Conceptos"
							checked={data.tipo === "rubros"}
							onChange={checked => get({ tipo: checked ? "rubros" : "conceptos" })}
						/>
					</Col>
					<Col xs={24}>
						<Space>
							<Title level={2} style={{ marginBottom: 8 }}>
								${data.total.toMoney ? data.total.toMoney(true) : 0}
							</Title>
							<Statistic
								value={Math.abs(data.porcentaje)}
								valueStyle={{ fontSize: 16, fontWeight: 'bold', color: data.porcentaje < 0 ? '#EE6666' : '#24CA49' }}
								precision={2}
								suffix={<>% {data.porcentaje < 0 ? <ArrowUpOutlined style={{ color: '#EE6666' }} /> : <ArrowDownOutlined style={{ color: '#24CA49' }} />}</>}
							/>
						</Space>
					</Col>
					<Col xs={24}>
						<Text style={{ fontSize: 14 }}>Comparado con los ${data.anterior.toMoney ? data.anterior.toMoney(true) : 0} del mes pasado</Text>
					</Col>
					<Col xs={24}>
						<List
							size='small'
							className='list-border-none list-top-dashboard'
							header={<Title level={5} style={{ marginBottom: 0 }}>Top 5 {data.tipo === "conceptos" ? "Conceptos" : "Areas/Rubros"}</Title>}
							bordered={false}
							dataSource={data.ranking}
							renderItem={item => <List.Item>
								<List.Item.Meta
									avatar={<Avatar shape="square" size="small" style={{ backgroundColor: randomColor() }} />}
									title={item.nombre}
								/>

								<div className="list-top-dashboard-monto">${item.total.toMoney ? item.total.toMoney(true) : 0}</div>
							</List.Item>}
						/>
					</Col>
				</Row>
			</Card>
		</Spin>
	)
}

/**
 * CardGastoAnual
 * 
 * @param {*}
 * @description Tarjeta de Gasto Anual con Lista Top 5 Conceptos/Rubros para Dashboard
 */
const CardGastoAnual = ({ proyecto_id }) => {

	const [loading, setLoading] = useState(false)
	const [data, setData] = useState({
		total: 0,
		anterior: 0,
		porcentaje: 0,
		ranking: [],
		tipo: "conceptos"
	})

	const get = ({ tipo } = data) => {
		setLoading(true)
		axios.get('/dashboard/gastos', {
			params: { proyecto_id, tipo, plazo: "year" }
		}).then((res) => {
			setData({ ...res.data, tipo })
		}).catch(error => {
			console.log(error)
			message.error("Error al obtener el gasto anual")
		}).finally(() => setLoading(false))
	}

	useEffect(() => {
		get()
	}, [proyecto_id])

	return (
		<Spin spinning={loading}>
			<Card
				bordered={false}
				className='card-venta-mensual-dashboard'
				loading={loading}
			>
				<Row>
					<Col xs={19}>
						<Title level={5}>Monto de Gasto Anual</Title>
					</Col>
					<Col xs={5}>
						<Switch
							style={{ width: '100%' }}
							checkedChildren="Rubros"
							unCheckedChildren="Conceptos"
							checked={data.tipo === "rubros"}
							onChange={checked => get({ tipo: checked ? "rubros" : "conceptos" })}
						/>
					</Col>
					<Col xs={24}>
						<Space>
							<Title level={2} style={{ marginBottom: 8 }}>
								${data.total.toMoney ? data.total.toMoney(true) : 0}
							</Title>
							<Statistic
								value={Math.abs(data.porcentaje)}
								valueStyle={{ fontSize: 16, fontWeight: 'bold', color: data.porcentaje < 0 ? '#EE6666' : '#24CA49' }}
								precision={2}
								suffix={<>% {data.porcentaje < 0 ? <ArrowUpOutlined style={{ color: '#EE6666' }} /> : <ArrowDownOutlined style={{ color: '#24CA49' }} />}</>}
							/>
						</Space>
					</Col>
					<Col xs={24}>
						<Text style={{ fontSize: 14 }}>Comparado con los ${data.anterior.toMoney ? data.anterior.toMoney(true) : 0} del año pasado</Text>
					</Col>
					<Col xs={24}>
						<List
							size='small'
							className='list-border-none list-top-dashboard'
							header={<Title level={5} style={{ marginBottom: 0 }}>Top 5 {data.tipo === "conceptos" ? "Conceptos" : "Areas/Rubros"}</Title>}
							bordered={false}
							dataSource={data.ranking}
							renderItem={item => <List.Item>
								<List.Item.Meta
									avatar={<Avatar shape="square" size="small" style={{ backgroundColor: randomColor() }} />}
									title={item.nombre}
								/>

								<div className="list-top-dashboard-monto">${item.total.toMoney ? item.total.toMoney(true) : 0}</div>
							</List.Item>}
						/>
					</Col>
				</Row>
			</Card>
		</Spin>
	)
}

/**
 * CardDashboardGraph
 * 
 * @param {*}
 * @description Grafica 'Multiple X Axes' para Dashboard
 */
const CardDashboardGraph = ({ proyecto_id }) => {
	const colors = ['#EE6666', '#24CA49'];

	const [loading, setLoading] = useState(false)
	const [data, setData] = useState({
		data: [],
		limit: 10,
		page: 1,
		total: 0,
		plazo: 'years',
		loading: false
	})

	const get = ({
		page = data.page,
		limit = data.limit,
		plazo = data.plazo
	} = data) => {
		setLoading(true)
		axios.get('/dashboard/historico', {
			params: { page, limit, plazo, proyecto_id }
		}).then((res) => {
			setData({ ...res.data, page, limit, plazo })
		}).catch(error => {
			console.log(error)
			message.error("Error al obtener el historico")
		}).finally(() => setLoading(false))
	}

	useEffect(() => {
		get()
	}, [proyecto_id])

	return (
		<Spin spinning={loading}>
			<Card
				className='card-shadow card-graph-dashboard card-dashboard'
				title="Historico de Transacciones"
				bordered={false}
				extra={<Space>
					<Select
						defaultValue={data.plazo}
						onChange={(plazo) => get({ plazo })}
					>
						<Select.Option value="months">
							Meses
						</Select.Option>
						<Select.Option value="years">
							Años
						</Select.Option>
					</Select>
					<Pagination simple
						onChange={(page, limit) => this.get({ page, limit })}
						current={data.page}
						total={data.total}
					/>
				</Space>}
			>
				<ReactEcharts
					style={{ width: '100%', height: '472px', maxHeight: '472px' }}
					option={{
						color: colors,
						tooltip: {
							trigger: 'none',
							axisPointer: {
								type: 'cross'
							},

						},
						legend: {},
						grid: {
							top: 70,
							bottom: 50
						},
						xAxis: [
							{
								type: 'category',
								axisTick: {
									alignWithLabel: true
								},
								axisLine: {
									onZero: false,
									lineStyle: {
										color: colors[1]
									}
								},
								axisPointer: {
									label: {
										formatter: function (params) {
											let monto = params.seriesData.length ? params.seriesData[0].data : 0
											monto = monto.toMoney ? monto.toMoney(true) : monto
											return (
												'Ingresos ' +
												params.value +
												' ：$' + monto
											);
										}
									}
								},
								// prettier-ignore
								data: data.data.map(({ year, month }) => month ? `${year}/${month}` : year)
							},
							{
								type: 'category',
								axisTick: {
									alignWithLabel: true
								},
								axisLine: {
									onZero: false,
									lineStyle: {
										color: colors[0]
									}
								},
								axisPointer: {
									label: {
										formatter: function (params) {
											let monto = params.seriesData.length ? params.seriesData[0].data : 0
											monto = monto.toMoney ? monto.toMoney(true) : monto
											return (
												'Egresos ' +
												params.value +
												' ：$' + monto
											);
										}
									}
								},
								// prettier-ignore
								data: data.data.map(({ year, month }) => month ? `${year}/${month}` : year)
							}
						],
						yAxis: [
							{
								type: 'value'
							}
						],
						series: [
							{
								name: 'Egresos',
								type: 'line',
								xAxisIndex: 1,
								smooth: true,
								emphasis: {
									focus: 'series'
								},
								data: data.data.map(({ egresos }) => egresos)
							},
							{
								name: 'Ingresos',
								type: 'line',
								smooth: true,
								emphasis: {
									focus: 'series'
								},
								data: data.data.map(({ ingresos }) => ingresos)
							}
						]
					}}
				/>
			</Card>
		</Spin>
	)
}

/**
 * @const CardCuenta
 * @description Card de cuentas de Finanzas
 * @param {*} cuenta Object con la información del step
 */
const CardCuenta = ({ cuenta = { saldo: 0 } }) => {

	let logos = {
		1: <IconMaster />,
		2: <IconVisa />,
		3: <img src={'/images/AE.png'} alt="AE" height="30px" />
	}

	return <Card bordered={false} className="card-cuenta"
		style={{
			background: `linear-gradient(244.64deg, 
                ${LightenDarkenColor(cuenta.color ? cuenta.color : "#0022A9", -20)} 10%, 
                ${LightenDarkenColor(cuenta.color ? cuenta.color : "#0022A9", 20)} 49.92%, 
                ${LightenDarkenColor(cuenta.color ? cuenta.color : "#0022A9", -10)}  85.62%)`
		}}
	>
		<Row justify='end' align='top'>
			<Col span={12} className="center">
				{cuenta.syncfy_credenciales_id && cuenta.syncfy_cuenta_bancaria_id ?
					<Link to={`/admin/finanzas/transacciones/sin-clasificar/${cuenta._id}`} style={{ position: "absolute", right: 0, top: -8 }}>
						<Tooltip title="Transacciones Bancarias">
							<Button type='primary' icon={<DollarOutlined style={{ color: "currentColor" }} />} style={{ fontWeight: 300 }} ></Button>
						</Tooltip>
					</Link>
					: null}
			</Col>
		</Row>
		<Row>
			<Col span={4} className="center">
				<CustomAvatar name={[cuenta.nombre, cuenta.apellido]} image={cuenta.logo} color={cuenta.color} />
			</Col>
			<Col span={14} className="flex-left ">
				<Text className="cuenta-nombre">{cuenta.nombre} <IconCheckMedal /></Text>
			</Col>

		</Row>
		<Card.Meta
			className="card-saldo-sub-meta"
			title={<Row justify="space-between">
				<Col><Text style={{ marginRight: '0.5rem' }} className="text-white">$ {cuenta.saldo?.toMoney(true)}</Text><small>MXN</small></Col>
				<Col> <Image preview={false} src={"/img/Chip.png"} /></Col>
			</Row>}
			description={cuenta.syncfy_saldo ? <><Text style={{ marginRight: '0.5rem' }} className="text-white">$ {cuenta.syncfy_saldo?.toMoney()}</Text><small>MXN</small></> : <><Text style={{ marginRight: '0.5rem' }} className="text-white"></Text></>} />
		<Row>
			<Col span={8} className="flex-left">
				<small className="text-white">ACCOUNT NUMBER</small>
			</Col>
			<Col span={12} className="flex-left">
				<Text strong style={{ paddingLeft: '7px' }} className="text-white">{cuenta.cuenta}</Text>

			</Col>
			<Col span={4} className="center">
				{logos[cuenta.marca]}
			</Col>
		</Row>
	</Card>
}


/**
 * @const CardSaldo
 * @description Card que muetra el total de Saldo diponible entre todas las cuentas
 * @param num saldo suma de los saldos disponibles
 * @param num cuentas cantidad de cuentas registradas
 */
const CardSaldo = ({ saldo = 0, cuentas = 0 }) => {
	return <Card className="card-saldo">
		<Card.Meta
			className="card-saldo-main-meta"
			title="Saldo al Momento"
			description={<><Text style={{ marginRight: '0.5rem' }}>$ {saldo?.toMoney(true)}</Text><small>MXN</small></>} />
		<Card.Meta
			className="card-saldo-sub-meta"
			title="Cuentas Incluidas"
			description={<>{cuentas}</>} />
	</Card>
}


/**
 * @const CardGains
 * @description Card con la informacion de montos pagados del mes actual y otros meses
 * @param {String} title Object con la información del step
 * @param {String} status estatus de los montos 
 * @param {Decimal} mes_actual_pagado Monto total del mes
 * @param {Decimal} otros_meses_pagado Monto total de otros meses
 */
const CardGains = ({
	title = "Profit",
	status = 'success',
	mes_actual_pagado = 0,
	otros_meses_pagado = 0 }) => {
	return <Card
		className="card-shadow  card-gains"
		title={
			<Row align='middle' justify='space-between'>
				<Col span={4} lg={4}>
					<div className={`${title === 'Ingresos' ? 'green-box' : 'red-box'}`}>
						{title === 'Ingresos' ? <IconProfit /> : <IconLoss />}
					</div>
				</Col>
				<Col span={16} lg={16} >
					<Text className="card-title">{title}</Text>
				</Col>
			</Row>
		}>

		<Row >
			<Col span={24} className="center">
				<Text className="text-paid">{moment().format('MMMM')}</Text>
			</Col>
			<Col span={16} className="flex-left">
				<Text className="text-paid">Monto Pagado</Text>
			</Col>
			<Col span={8} className="flex-right">
				<Text className="text-paid">{mes_actual_pagado.toMoney(true)}</Text>
			</Col>
			<Col span={24} className="center">
				<Progress percent={90} status={status} showInfo={false} />
			</Col>
		</Row>
		<Row>
			<Col span={24} className="center">
				<Text>Otros Meses</Text>
			</Col>
			<Col span={16} className="flex-left">
				<Text className="text-paid">Monto Pagado</Text>
			</Col>
			<Col span={8} className="flex-right">
				<Text className="text-paid">{otros_meses_pagado.toMoney(true)}</Text>
			</Col>
			<Col span={24} className="center">
				<Progress percent={100} status={status} showInfo={false} />
			</Col>

		</Row>

	</Card>
}

/**
 * 
 * 
 * @export
 * @const VentasAnuales
 * @description Card de ventas por año del dashboard de CRM
 */
const CardVentasAnuales = (props) => {

	console.log(props.ventas_anuales?.map(y => y.año.toString()))
	return <Card title={"Ventas por año"} bordered={false} className="card-shadow echart-card card-crm-ventas-anuales">
		<ReactEcharts
			style={{ height: '240px', width: "100%" }}
			option={{
				responsive: true,
				maintainAspectRatio: false,
				xAxis: {
					type: 'category',
					data: props.ventas_anuales?.map(y => y.año.toString())
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						data: props.ventas_anuales?.map(y => y.monto.toString()),
						type: 'bar',
						showBackground: true,
						backgroundStyle: {
							color: 'rgba(180, 180, 180, 0.2)'
						}
					}
				]
			}}
		/>
	</Card>
}

/**
 * 
 * 
 * @export
 * @param {*}
 * @description Grafica de Ventas Reales vs Estimadas para dashboard CRM
 */
const CardCRMVentasEstimadasReales = ({ }) => {
	const colors = ['#5470C6', '#EE6666'];

	return <Card className="card-shadow card-crm-ventas-estimadas-reales" title={"Ventas Estimadas vs Reales"} bordered={false}>
		<ReactEcharts
			style={{ width: "100%", height: "470px" }}
			option={{
				dataset: [
					{
						id: 'dataset_raw',
						source: crm_datasource
					},
					{
						id: 'dataset_since_1950_of_germany',
						fromDatasetId: 'dataset_raw',
						transform: {
							type: 'filter',
							config: {
								and: [
									{ dimension: 'Year', gte: 1950 },
									{ dimension: 'Country', '=': 'Germany' }
								]
							}
						}
					},
					{
						id: 'dataset_since_1950_of_france',
						fromDatasetId: 'dataset_raw',
						transform: {
							type: 'filter',
							config: {
								and: [
									{ dimension: 'Year', gte: 1950 },
									{ dimension: 'Country', '=': 'France' }
								]
							}
						}
					}
				],
				tooltip: {
					trigger: 'axis'
				},
				xAxis: {
					type: 'category',
					nameLocation: 'middle'
				},
				yAxis: {
					name: 'Income'
				},
				series: [
					{
						type: 'line',
						datasetId: 'dataset_since_1950_of_germany',
						showSymbol: false,
						encode: {
							x: 'Year',
							y: 'Income',
							itemName: 'Year',
							tooltip: ['Income']
						}
					},
					{
						type: 'line',
						datasetId: 'dataset_since_1950_of_france',
						showSymbol: false,
						encode: {
							x: 'Year',
							y: 'Income',
							itemName: 'Year',
							tooltip: ['Income']
						}
					}
				]
			}}
		/>
	</Card>
}

/**
 * @const CardCuenta
 * @description Card de la descripcion del step del project manager
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardEmpresa = ({ empresa }) => {


	return <Card className='card-shadow card-empresa'>
		<Row align='middle' justify='space-between' className='flex mb-1'>
			<Space>
				<CustomAvatar image={null} color={null} />
				<Text className='empresa-nombre'>{empresa.alias}</Text>
			</Space>
			<Link to={'/admin/empresas/' + empresa._id} style={{ float: "left" }}>
				<Tag className='factura-tag flex-right'><Space size={3}><IconFacturaElectronica color="#FFFFFF" style={{ position: "relative", top: 1 }} /><Text className='texto'>Facturas</Text></Space></Tag>
			</Link>

		</Row>
		<Row align='middle'>
			<FlagFilled style={{ color: '#C4CAD3' }} className='mr-1' /><Text className='alta-fecha'>Alta {moment(empresa.createdAt).format('LL')}</Text>
		</Row>
		<Row className='mt-1'>
			<Text className='card-title'>Bancos</Text>
		</Row>
		<Row justify='space-between'>
			<Col span={12}>
				<Row>
					<Text className='cantidad-titulo'>Ingresos</Text>
				</Row>
				<Row>
					<Text className='cantidad'>$ {empresa.transaccion_ingreso?.toMoney(true)} MXN</Text>
				</Row>
			</Col>
			<Col span={12}>
				<Row>
					<Text className='cantidad-titulo'>Egresos</Text>
				</Row>
				<Row>
					<Text className='cantidad'>$ {empresa.transaccion_egreso?.toMoney(true)} MXN</Text>
				</Row>
			</Col>
		</Row>
		<Row className='mt-1'>
			<Text className='card-title'>Facturación</Text>
		</Row>
		<Row justify='space-between'>
			<Col span={12}>
				<Row>
					<Text className='cantidad-titulo'>Ingresos</Text>
				</Row>
				<Row>
					<Text className='cantidad'>$ {empresa.facturado_ingreso?.toMoney(true)} MXN</Text>
				</Row>
			</Col>
			<Col span={12}>
				<Row>
					<Text className='cantidad-titulo'>Egresos</Text>
				</Row>
				<Row>
					<Text className='cantidad'>$ {empresa.facturado_egreso?.toMoney(true)} MXN</Text>
				</Row>
			</Col>
		</Row>
	</Card>
}

/**
 * @const CardConciliacion_1

 * @description Card con los datos de razon social
 */
const MontosFaltantes = ({ transacciones = [], facturas = [], onClick = () => { } }) => {

	// * 1 - Ingreso , 2 - Egreso ,3 - Traspaso ,4 - Nulo
	let trans_ingresos = transacciones.find(trans => trans._id == 1);
	let trans_egresos = transacciones.find(trans => trans._id == 2);


	//0 - Sin Cambios ,1 - Ingreso ,-1 - Egreso
	let fact_ingresos = facturas.find(fact => fact._id == 1);
	let fact_egresos = facturas.find(fact => fact._id == -1);



	return <Card
		onClick={onClick}
		hoverable={true}
		className="card-project card-shadow card-medium-small"

		title={
			<Row>
				<Text strong className='pt-5px'>
					Montos Faltantes
				</Text>
			</Row>
		}
	>

		<Row align="middle" justify="start" style={{ marginTop: "3.5rem" }}>
			<Col span={24} >
				<Row align="middle" justify="start" >
					<Col span={24} className="card-rs-blue">Bancos</Col>
				</Row>
			</Col>

			<Col xs={24} lg={12} >
				<Row align="middle" justify="start"  >
					<Col span={24} className="card-rs-text">Ingresos</Col>
					<Col span={24} className="card-rs-cantidad"> ${(trans_ingresos?.total) ? trans_ingresos?.total.toMoney(true) : 0} MXN</Col>
				</Row>
			</Col>
			<Col xs={24} lg={12} >

				<Row align="middle" justify="start" >
					<Col span={24} className="card-rs-text">Egresos</Col>
					<Col span={24} className="card-rs-cantidad"> ${(trans_egresos?.total) ? trans_egresos?.total.toMoney(true) : 0} MXN</Col>
				</Row>

			</Col>
		</Row>

		{/* Facturación */}
		<Row align="middle" justify="start" style={{ marginTop: "10px" }}>
			<Col span={24} >
				<Row align="middle" justify="start" >
					<Col span={24} className="card-rs-blue">Facturación</Col>
				</Row>

			</Col>

			<Col xs={24} lg={12} >
				<Row align="middle" justify="start"  >
					<Col span={24} className="card-rs-text">Ingresos</Col>
					<Col span={24} className="card-rs-cantidad"> ${(fact_ingresos?.total) ? fact_ingresos?.total.toMoney(true) : 0} MXN</Col>
				</Row>
			</Col>
			<Col xs={24} lg={12} >

				<Row align="middle" justify="start" >
					<Col span={24} className="card-rs-text">Egresos</Col>
					<Col span={24} className="card-rs-cantidad"> ${(fact_egresos?.total) ? fact_egresos?.total.toMoney(true) : 0} MXN</Col>
				</Row>

			</Col>
		</Row>

	</Card>

}

/**
 * @const CardEstatusContabilidad

 * @description Card con los datos de razon social
 */
const CardEstatusContabilidad = ({ sin_soporte = 0, incompleto = 0, completo = 0, onClick = () => { } }) => {
	return <Card
		onClick={onClick}
		hoverable={true}
		className="card-project card-shadow card-medium-small"
	>
		<Row className='mb-1 p-1'>
			<Text strong className='pt-5px'>
				Estatus
			</Text>
		</Row>
		<Row align="middle" justify="start" style={{ marginTop: "10px" }}>
			<Col span={24} >
				<Row gutter={[16, 16]}>
					<Col span={20} className="flex-left">
						<IconError />
						<Text strong className='text-error ml-1'>
							Sin Soporte
						</Text>
					</Col>
					<Col span={4} >
						<Text strong >{sin_soporte}</Text>
					</Col>
					<Col span={20} className="flex-left">
						<IconWarning />
						<Text strong className='text-warning ml-1'>
							Incompleto
						</Text>

					</Col>
					<Col span={4} >
						<Text strong >{incompleto}</Text>
					</Col>
					<Col span={20} className="flex-left">
						<IconSuccess />
						<Text strong className='text-success ml-1'>
							Completo
						</Text>

					</Col>
					<Col span={4} >
						<Text strong >{completo}</Text>
					</Col>
				</Row>
			</Col>
		</Row>
	</Card>
}

/**
 * @const CardIconoMoney

 * @description Card con los datos para montos que contenga icono en lugar de titulo
 */
const CardIconoMoney = ({ icon = null, extra = null, title = "Card Title", first_subtitle = "Card Title", second_subtitle = "Card Title", first = 0, second = 0 }) => {

	return <Card
		extra={
			<Title level={4}>{title}</Title>
		}
		className="card-head-borderless border-10 card-icono-montos"
		title={icon}
	>
		<Row>
			<Col span={12}>
				<Title level={5} className="card-custom-title">{first_subtitle}</Title>
				<Statistic
					prefix='$'
					className="stadistic-fw-800"
					precision={2}
					value={first}
				/>
			</Col>
			<Col span={12}>
				<Title level={5} className="card-custom-title">{second_subtitle}</Title>
				<Statistic
					prefix='$'
					className="stadistic-fw-800"
					precision={2}
					value={second}
				/>
			</Col>
		</Row>
	</Card>
}


const CardGraphMontosComparacion = (props) => {
	return <Card className='card-graph-dashboard border-10' bordered={false}>
		<ReactEcharts
			style={{ width: "100%", height: "500px", maxWidth: "1000px", margin: "0 auto" }}

			option={{

				title: {
					text: 'Stacked Line'
				},
				tooltip: {
					trigger: 'axis'
				},
				legend: {
					data: ['Ingresos', 'Egresos']
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				toolbox: {
					feature: {
						saveAsImage: {}
					}
				},
				xAxis: {

					type: 'category',
					data: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
				},
				yAxis: {
					type: 'value'
				},
				series: [
					{
						name: 'Ingresos',
						type: 'line',

						data: props.ingresos
					},
					{
						name: 'Egresos',
						type: 'line',

						data: props.egresos
					},
				]
			}}
		/>
	</Card>
}

/**
 * 
 * @param {*} data 
 * @param {String} title
 * @param {Component} extra 
 * 
 * @description Lista simple con Avatar, titulo, description y monto
 * @returns  Card
 */
const CardListaMontos = ({ data, title = "Title", extra = null }) => {
	return <Card
		title={title}
		extra={extra}
		className="card-head-borderless card-list-montos border-10">
		<List
			size='small'
			className='list-border-none'
			bordered={false}
			dataSource={data}
			renderItem={item => <List.Item>
				<List.Item.Meta
					avatar={<Avatar shape="circle" size="large" style={{ backgroundColor: item.color }} />}
					title={<Text ellipsis>{item.concepto}&nbsp;</Text>}
					description={moment(item.fecha).format('L')}
				/>
				<Text className="list-item-monto">$ {item.monto.toMoney ? item.monto.toMoney() : 0}</Text>
			</List.Item>}
		/>
	</Card>
}

/**
 * 
 * @param {*} data 
 * @param {String} title
 * @param {Component} extra 
 * 
 * @description Card para los porcentajes de los pagos
 * @returns Card 
 */
const CardPagosPendientesPorcentajes = ({ data, header, title = () => { }, extra = null }) => {

	return <Card
		title={header}
		extra={extra}
		className="card-head-borderless card-list-montos border-10">
		<List
			size='small'
			className='list-border-none'
			bordered={false}
			dataSource={data}
			renderItem={item => <List.Item>
				<Row className='width-100 card-list-item-blue pd-1 border-10'>
					{title(item)}
					<MultiProgress
						max={item.monto}
						elements={[
							{
								value: item.monto_pagado
							},
							{
								value: item.periodo !== 1 ? item.monto_pagar : 0
							},
							{
								value: item.periodo !== 1 ? item.monto_pendiente : item.monto_pagar + item.monto_pendiente
							}
						]}
					/>
				</Row>

			</List.Item>}
		/>
	</Card >
}
function CardProyecto({
	image = "sauvage-logo.png",
	createdAt = new Date(),
	color = null,
	nombre = "Proyecto Nombre",
	onClick = () => { } }) {
	return <Card className='card card-proyecto grid'>
		<Row gutter={[8, 8]} >
			<Col span={5}>
				<CustomAvatar size={50} image={image} name={nombre} color={color} />
			</Col>
			<Col span={19} className="flex-right-column">
				<small className="text-gray">Fecha de Inicio:</small>
				<Text className="text-fecha">{moment(createdAt).format('DD-MM-YYYY')}</Text>
			</Col>
			<Col span={20} className="flex-left-column">
				<Text className="text-gray">Nombre</Text>
				<Text className="text-black-lg">{nombre}</Text>
			</Col>
			<Col span={4} className="center">
				<Button size='large' ghost onClick={onClick} style={{ height: '50px' }} icon={<IconPDFColor style={{ margin: '0px 5px' }} />} />
			</Col>
		</Row>
	</Card>
}

function CardPrespuesto({
	presupuesto = 0.00,
	ejercido = 0.00,
	presupuesto_ingresos = 0.00,
	fecha_ultima_transaccion = new Date(),
	monto_ultima_transaccion = 0 }) {

	return <Card className="card">
		<Row gutter={[8, 8]}>
			<Col span={12} className="flex-left">
				<Text className="text-gray">Presupuesto</Text>
			</Col>
			<Col span={12} className="flex-left">
				<Text className="text-gray">Ejercido a {moment(fecha_ultima_transaccion).format('DD-MM-YYYY')}</Text>
			</Col>
			<Col span={12} className="flex-left-column">
				<Text className="text-black">$ {presupuesto.toMoney(true)} MXN</Text>
			</Col>
			<Col span={12} className="flex-left-column">
				<Text className="text-black">$ {(ejercido).toMoney(true)} MXN</Text>
			</Col>
			<Col span={12} className="flex-left">
				<Text className="text-gray">Presupuesto Ingresos</Text>
			</Col>
			<Col span={12} className="flex-left">
				<Text className="text-gray">Ingreso más reciente</Text>
			</Col>
			<Col span={12} className="flex-left-column">
				<Text className="text-black">$ {(presupuesto_ingresos)?.toMoney(true)} MXN</Text>
			</Col>
			<Col span={12} className="flex-left-column">{/*ultima transaccion */}
				<Text className="text-black">$ {monto_ultima_transaccion.toMoney(true)} MXN</Text>
			</Col>
		</Row>
	</Card>
}


function CardConcepto({
	concepto = '-',
	subconcepto = '-'
}) {

	return <Card className="card">
		<Row gutter={[8, 8]}>
			<Col span={24} className="flex-left">
				<Text className="text-gray">Concepto</Text>
			</Col>
			<Col span={24} className="flex-left">
				<Text strong className="text-black">{concepto}</Text>
			</Col>
			<Col span={24} className="flex-left-column">
				<Text className="text-gray">Subconcepto</Text>
			</Col>
			<Col span={24} className="flex-left-column">
				<Text strong className="text-black">{subconcepto}</Text>
			</Col>
		</Row>
	</Card>
}

function CardEgresado({
	presupuesto = 0,
	ejercido = 0
}) {

	return <Card className="card">
		<Row gutter={[8, 8]}>
			<Col span={24} className="flex-left">
				<Text className="text-gray">Presupuesto</Text>
			</Col>
			<Col span={24} className="flex-left">
				<Text strong className="text-black"> $ {presupuesto.toMoney(true)} MXN</Text>
			</Col>
			<Col span={24} className="flex-left-column">
				<Text className="text-gray">Ejercido</Text>
			</Col>
			<Col span={24} className="flex-left-column">
				<Text strong className="text-black">$ {ejercido.toMoney(true)} MXN</Text>
			</Col>
		</Row>
	</Card>
}

function CardTransaccionesClasificacion({
	porcentaje = 0,
	no_clasificadas = 0,

}) {
	return <Card className="card card-proyecto grid">
		<Row gutter={[8, 8]}>
			<Col span={24}>
				<Text className="text-gray">Transacciones Clasificadas</Text>
			</Col>
			<Col span={24}>
				<Progress percent={porcentaje} />
			</Col>
			<Col span={20}>
				<Text className="text-gray">Transacciones Sin Clasificar</Text>
			</Col>
			<Col span={24} className="flex-right">
				<Text className="text-black-lg">{no_clasificadas}</Text>
			</Col>
		</Row>
	</Card>
}

/*
* @function CardInfoDashboard
* @desrcition Card sencilla muestra un titulo y un dato
*
* @params background: color de fondo de la Card
* @params title: titulo principal
* @params number: numero o dato a deslpegar
*/
function CardInfoDashboard(props) {
	return (
		<Card>
			<Title level={3}>{props.title}</Title>
			<Title level={4} style={{ textAlign: "center" }}>{props.monto}</Title>
		</Card>
	);
}

/*
* @function CardProveedor
* @desrcition Card sencilla muestra un titulo y un dato
*
* @params background: color de fondo de la Card
* @params title: titulo principal
* @params number: numero o dato a deslpegar
*/
function CardProveedor(props) {

	let icon = null
	if (props.facturas?.vencidas > 0) icon = <CloseCircleFilled style={{ fontSize: '37px', color: '#ff4d4f' }} />
	else if (props.facturas?.por_vencer > 0) icon = <WarningFilled style={{ fontSize: '37px', color: '#faad14' }} />
	else icon = <CheckCircleFilled style={{ fontSize: '37px', color: '#52c41a' }} />

	return (
		<>
			<Link to={`/admin/proveedores/detalle/${props._id}`}>
				<Card style={{ width: '100%' }} className="card-proveedores hover-effect">
					<div style={{ flex: '1 1 auto' }} className="content-title">
						<Text className="title-proveedor">{props.alias}</Text>
						{icon}
					</div>
					<div style={{ flex: '1 1 auto' }} className="content-title">
						<Text strong>Saldo Total: $ {props.facturas?.saldo_total ? props.facturas?.saldo_total.toMoney(true) : 0} MXN</Text>
					</div>
					<Row>
						<Col span={6}>
							<Text className="text-data-title">Liquidadas</Text>
							<Text className="text-data">{props.facturas?.liquidadas ?? 0}</Text>
						</Col>
						<Col span={6}>
							<Text className="text-data-title">Vencidas</Text>
							<Text className="text-data">{props.facturas?.vencidas ?? 0}</Text>
						</Col>
						<Col span={6}>
							<Text className="text-data-title">Por Vencer</Text>
							<Text className="text-data">{props.facturas?.por_vencer ?? 0}</Text>
						</Col>
						<Col span={6}>
							<Text className="text-data-title">Cotizaciones</Text>
							<Text className="text-data">{props.facturas?.cotizaciones ?? 0}</Text>
						</Col>
					</Row>
				</Card>
			</Link>
		</>
	);
}


/**
* @function CardFacturas
* @desrcition Card sencilla muestra un titulo y un dato
*
* @params background: color de fondo de la Card
* @params title: titulo principal
* @params number: numero o dato a deslpegar
*/
function CardFacturas(props) {

	const tags = {
		'1': <Tag className="badge-facturas" color="#226dff">Vigente</Tag>,
		'2': <Tag className="badge-facturas" color="#e1c634">Por Vencer</Tag>,
		'3': <Tag className="badge-facturas" color="#fa1e1e">Vencida</Tag>,
		'4': <Tag className="badge-facturas" color="#3e8c12">Liquidada</Tag>,
		'5': <Tag className="badge-facturas" color="purple">Factura</Tag>
	}

	return (
		<Card style={{ width: '100%' }} className="card-proveedores hover-effect" onClick={() => { if (props.onClick) props.onClick() }}>
			<div>
				{tags[props.info_estatus]}
			</div>
			<Row>
				<Col span={16}>
					<Text className="text-concepto">{props.concepto}</Text>
					<Text strong className="text-proveedor">{props.proveedor_id.alias}</Text>
				</Col>
				<Col span={8}>
					<Text className="text-monto">$ {props.monto.toMoney(true)} MXN</Text>
				</Col>
			</Row>
		</Card>
	);
}


export {
	CardSaldosGraph,
	CardGastoMensual,
	CardGastoAnual,
	CardDashboardGraph,
	CardCuenta,
	CardSaldo,
	CardGains,
	CardVentasAnuales,
	CardCRMVentasEstimadasReales,
	CardEmpresa,
	MontosFaltantes,
	CardEstatusContabilidad,
	CardProyecto,
	CardTransaccionesClasificacion,
	CardPrespuesto,
	CardConcepto,
	CardEgresado,


	CardIconoMoney,
	CardGraphMontosComparacion,
	CardListaMontos,
	CardPagosPendientesPorcentajes,
	CardInfoDashboard,
	CardProveedor,
	CardFacturas,
}