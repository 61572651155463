import React, { Component } from "react";
import { Modal, Form, Input, InputNumber, message, Spin, Tree } from 'antd';

const axios = require('axios').default;

/**
 * @class ModalSubConceptos
 * @extends {Component}
 */
class ModalSubConceptos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false  
        }
    }

    ModalSubConceptos = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if(this.props.subconcepto_id){
            this.getConcepto()
        }
    }


    onFinish = (values) => {
        if(this.props.subconcepto_id){
            this.updateConcepto(values)
        }else{
            this.addConcepto(values)
        }
    }

    /**
     * @memberof ModalSubConceptos
     * 
     * @method addConcepto 
     * @description Añade un nuevo subconcepto
     */
    addConcepto = (values) => {
        this.setState({ loading: true })
        axios.post('/subconcepto',{
            ...values,
            concepto_id: this.props.concepto_id,
            proyecto_id: this.props.proyecto_id
        }).then(()=>{
            message.success('¡Concepto creado!')
            this.props.onClose(true)
        }).catch((error)=>{
            console.log("error", error);
            if(error?.response?.status === 403)
                message.error(error?.response?.data?.message)
            else
                message.error('Error al crear el subconcepto')
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalSubConceptos
     * 
     * @method getConcepto 
     * @description Obtiene la informacion de un subconcepto
     */
    getConcepto = () => {
        this.setState({ loading: true })
        axios.get(`/subconcepto/${this.props.subconcepto_id}`)
        .then((response)=>{
            this.ModalSubConceptos.current.setFieldsValue({
                ...response.data
            })
        }).catch((error)=>{
            console.log("error", error);
            message.error('Error al obtener el subconcepto')
        }).finally(()=>this.setState({loading: false}))
    }

    /**
     * @memberof ModalSubConceptos
     * 
     * @method updateConcepto 
     * @description Añade un nuevo subconcepto
     */
    updateConcepto = (values) => {
        this.setState({ loading: true })
        axios.put('/subconcepto',{
            ...values,
            subconcepto_id: this.props.subconcepto_id
        }).then(()=>{
            message.success('¡Concepto actualizado!')
            this.props.onClose(true)
        }).catch((error)=>{
            console.log("error", error);
            if(error?.response?.status === 403)
                message.error(error?.response?.data?.message)
            else
                message.error('Error al actualizar el subconcepto')
        }).finally(()=>this.setState({loading: false}))
    }

    render() {
        return (
            <Form
                layout="vertical"
                name="form-subconcepto"
                ref={this.ModalSubConceptos}
                onFinish={this.onFinish}
                className="pd-1"
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el nombre"
                        }]}
                    >
                        <Input placeholder="Nombre" ></Input>
                    </Form.Item>
                    <Form.Item
                        label="Presupuesto"
                        name="presupuesto"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese el presupuesto"
                        }]}
                    >
                        <InputNumber 
                        	className="width-100"
                        	precision={2}
                        	min={0.01}
                        	max={99999999999}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        />
                    </Form.Item>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, subconcepto_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={subconcepto_id ? "Editar Sub Concepto" : "Crear Sub Concepto"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-subconcepto', key: 'submit', htmlType: 'submit' }}
    >
        <ModalSubConceptos {...props} />
    </Modal>

}