import React, { Component } from 'react';
import { Card, Layout, Modal, Form, Input, message, Spin, Row, Col, Typography, Tag, Space, Tooltip, InputNumber } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";

import './cards.css';

const { Content } = Layout;
const { Title, Text } = Typography;

export class CardInfoDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

    }

    


    render() {
        return (
            <Card style={{ width: '100%', background: this.props.background, flex: ' 1 1 auto', }} className={`PM-card-dashboard ${this.props.text} ${this.props.hover ? 'hover-effect' : ''}`}>
			<div>
				<h3
					className={`PM-card-title`}
					style={{ textAlign: (this.props.textAlign) ? "center" : undefined }}
				>{this.props.title}</h3>
			</div>
			<div><h4 className="PM-card-body" >{this.props.number ? this.props.number : null}</h4></div>
			{this.props.children}
		</Card>
        )
    }
}



export default function (props) {



    return <CardInfoDashboard {...props} />
}