import React, { Component } from "react";
import { Modal, Form, Input, message, Spin, InputNumber } from 'antd';
import { SelectProducto, SelectProyecto, SelectInmueble } from "../../Widgets/Inputs/Selects";
import PrioridadPicker from "../../Widgets/Inputs/PrioridadPicker";

const axios = require('axios').default;

/**
 *
 *
 * @class ModalRequisiciones
 * @extends {Component}
 */
class ModalRequisiciones extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proyecto_id: null
        }
    }

    ModalRequisiciones = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    onFinish = (values) => {
        console.log("values", values);
        this.addRequisicion(values)
    }


    /**
     *
     * @methodOf ModalRequisiciones
     * @function addRequisicion
     * @description Añade una nueva requisicion
     */
    addRequisicion = (values) => {
        this.setState({loading: true})
        axios.post('/requisiciones',{
            ...values
        }).then(response => {
            message.success("Requisición creada")
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al crear la requisicion")
        }).finally(()=>{
            this.setState({loading: false})
        })
    }


    render() {
        return (
            <Form
                layout="vertical"
                name="form-requisiciones"
                ref={this.ModalRequisiciones}
                onFinish={this.onFinish}
                className="pd-1"
                initialValues={{
                    cantidad: 1,
                    prioridad: 0
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Form.Item
                        label="Producto"
                        name="producto_id"
                        rules={[{
                            required: true,
                            message: "Por favor, seleccione el producto"
                        }]}
                    >
                        <SelectProducto/>
                    </Form.Item>

                    <Form.Item
                        label="Proyecto"
                        name="proyecto_id"
                        rules={[{
                            required: true,
                            message: "Por favor, seleccione el proyecto"
                        }]}
                    >
                        <SelectProyecto
                            onSelect={(proyecto_id) => {
                                this.ModalRequisiciones.current.setFieldsValue({inmueble_id: undefined})
                                this.setState({proyecto_id})
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Cantidad / Volumen"
                        name="volumen"
                        rules={[{
                            required: true,
                            message: "Por favor, ingrese la cantidad"
                        }]}
                    >
                         <InputNumber
                            className="width-100"
                            min={1}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Inmueble"
                        name="inmueble_id"
                        rules={[{
                            required: true,
                            message: "Por favor, seleccione el inmueble"
                        }]}
                    >
                        <SelectInmueble
                            mode="multiple"
                            proyecto_id={this.state.proyecto_id}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Prioridad"
                        name="prioridad"
                        rules={[{
                            required: true,
                            message: "Por favor, seleccione la prioridad"
                        }]}
                    >
                        <PrioridadPicker/>
                    </Form.Item>

                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, area_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={"Nueva Requisición"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-requisiciones', key: 'submit', htmlType: 'submit' }}
    >
        <ModalRequisiciones {...props} />
    </Modal>

}