import React, { Component } from 'react';
import { Row, Col, Button, Popconfirm, Menu, DatePicker, message, Form, Checkbox, Dropdown, PageHeader, Space, Tag, List, Card, Typography } from 'antd'
import { Link } from 'react-router-dom';

import { IconArrow as Arrow, IconPlus, IconEye, IconEdit, IconDelete, IconDownloadCustom as Download, IconDetails } from '../../Widgets/Iconos';
import Reporte from './AcreedorReporte'

import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';

import ModalAcreedores from './Modales/ModalAcreedor';
import Avatar from "../../Widgets/Avatar/Avatar";
import { Content } from 'antd/lib/layout/layout';
import FloatingButton from '../../Widgets/Floating Button/FloatingButton';

const axios = require('axios').default
const { RangePicker } = DatePicker;
const { Text } = Typography;
const moment = require('moment');

axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


class Acreedores extends Component {

    constructor(props) {
        super(props)
        this.state = {

            dataAcreedores: [],

            acreedores: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },


            accionModal: 'crear',
            registroId: '',
            loading: true,
            modalVisible: false,

            datosConsultados: false,
            numConsulta: 0,
            dataRpt: {
                fecha: '',
                espacio: '',
                dataInfo: [],
                author: '',
                totalTran: 0,
                sinFiltros: true,

                fecha_inicio: '',
                fecha_fin: '',
                area: '',
                rubro: '',

                proyectos: [],
                cuentas: [],
                numConsulta: 0,
                montoPagado: 0,

                totalVenta: 0,
                totalPagado: 0,
                totalDeuda: 0
            },


            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,


            start: undefined,
            end: undefined,
            data: [],


            projects: [],

            areas: [],
            rubros: [],

            tipos: [],
            cuentas: [],

            dropdowns: {
                projects: false,
                areas: false,
                rubros: false,

                tipo: false
            },

            projectsSelected: [],
            cuentasSelected: [],
            area: null,
            rubro: null,

            cuenta: null,
            tipo: null,

            filtroSearch: "",
            searching: true


        }
    }


    getAcreedores = ({
        page = this.state.acreedores.page,
        limit = this.state.acreedores.limit,
        search = this.props.search

    } = this.state.acreedores) => {
        this.setState({ loading: true, acreedores: { ...this.state.acreedores, page, limit } })
        axios.get('/acreedores', {
            params: {
                page,
                limit,

                search,
                paginate: true
            }
        }).then(({ data }) => {
            this.setState({
                acreedores: data,
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }


    /**
   * @memberof Acreedores
   * @method   componentDidMount
   **/
    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search

        

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        //Obtenemos el listado de Acreedores
        this.getAcreedores();
        this.getProjects();
    }



    /**
     * @memberof Acreedores
     * @method componentDidUpdate
     * @description actualiza la vista si se ha realizado una busqueda
     */
    async componentDidUpdate() {
        /*Consulta con input header*/
        var filtro = this.state.filtroSearch;
        var propFiltro = this.props.search;


        if (filtro !== propFiltro && this.state.searching === true) {
            //this.receivedData();
            this.getAcreedores();
            this.state.searching = false;
        }
    }


    /**
    * @memberof Acreedores
    * @method receivedData
    * @description Obtiene la informacion y actualiza los valores locales de la lista.
    */
    receivedData = async (
        page = this.state.currentPage,
        quantity = this.state.perPage,
        filter = {},
        projects = this.state.projectsSelected,
        area = this.state.area,
        rubro = this.state.rubro,

        end = this.state.end,
        start = this.state.start
    ) => {

        this.setState({ loading: true });


        var search = this.props.search;
        var numPage = 1, numReg = 0;

        axios.get('acreedores/list', {
            params: {
                //pagination:false,
                search: search,
                page: page,
                limit: quantity,
                filter,
                projects,
                area,
                rubro,

                end,
                start
            }
        })
            .then(res => {
                this.state.searching = true;


                var
                    data = res.data.docs,
                    dataL = data, data_list = [];

                numPage = page;
                if (numPage === null || numPage === 1) { numReg = 0 }
                else { numReg = ((numPage - 1) * 10) }


                for (let index = 0; index < dataL.length; index++) {
                    const element = dataL[index];
                    numReg = numReg + 1;
                    var monto_pagado = 0;


                    if (element.transacciones.length > 0) {
                        for (let t = 0; t < element.transacciones.length; t++) {
                            const tran = element.transacciones[t];
                            monto_pagado += parseFloat(tran.monto)
                        }

                    }


                    data_list.push({
                        _id: element._id,
                        index: numReg,
                        acreedor: element.acreedor,
                        email: element.email,
                        telefono: element.telefono,
                        area_id: element.area_id,
                        rubro_id: element.rubro_id,

                        proyecto_id: element.proyecto_id,
                        createdAt: element.createdAt,
                        contacto_id: element.contacto_id,
                        monto_pagado: monto_pagado,

                        monto_pendiente: element.monto_pendiente,
                        monto_prestamo: element.monto_prestamo,
                        monto_retorno: element.monto_retorno,
                        fecha_prestamo: element.fecha_prestamo.toString().substring(0, 10),
                        updatedAt: element.updatedAt,

                        __v: element.__v
                    });
                }


                this.setState({
                    filtroSearch: search,
                    dataAcreedores: data_list,// res.data.data.itemsList
                    currentPage: page,
                    itemCount: res.data.data.paginator.itemCount,
                    perPage: quantity,
                    pageCount: res.data.data.paginator.pageCount,
                    loading: false,

                });


                this.receivedConsulta();

            })
            .catch(res => {
                console.log("error", res);
            })
            .finally(f => {
                this.setState({ loading: false })
            });


        ///acreedores/list
    }


    /**
    * @memberof Acreedores
    * @method eliminar 
    * @description Elimina registro de acreedor por su id
    */
    eliminarAcreedor = id => {

        axios.delete('/acreedores', {
            params: {id}
        })
            .then((response) => {
                message.success("Eliminado correctamente.");
                this.receivedData();
            })
            .catch((error) => {
                message.error("Sucedio un error al eliminar el registro.")

            })

    }



    /**
     *  @memberof Acreedores
     * @method modalCrear 
     * @description Abrir modal tipo creación para nuevo registro.
     */
    modalCrear = () => {
        this.setState({
            accionModal: 'crear',
            modalVisible: true,
        })
    }


    /**
     *  @memberof Acreedores
     * @method modalEditar 
    * @description Abrir modal tipo edición para registro seleccionado desde tabla.
    * @param item Elemento seleccionado para edición, se obtiene id para edición de registro.
    */
    modalEditar = (item) => {
        
        setTimeout(() => {
            this.setState({
                
                accionModal: 'editar',
                modalVisible: true,
            })
        }, 100);
    }


    /**
     *  @memberof Acreedores
     * @method hideModal 
     * @description Cerrar modal y limpiar valor registroId
    */
    hideModal = () => {
        //this.receivedData();
        this.setState({
            registroId: '',
            modalVisible: false,
        })

        //this.receivedData();
    }


    /**
     * @memberof Acreedores
     * @description Actualiza los valores del state;
     */
    update = () => this.setState({ update: !this.state.update })


    /**
     * @memberof Acreedores
     * @function getProjects
     * @description Obtenemos los proyectos y los ponemos en el listado para filtro
     *
     * */
    getProjects = () => {
        axios.get('/proyectos', {
            params: {
                pagination: false
            }
        }).then((proyectos) => {
            this.setState({
                projects: proyectos.data.data
            })
        }).catch((error) => {
        })
    };


    /**
      * @memberof Acreedores
     *
     * @function onProjectsChange
     * @description Se actualiza el arreglo de proyectos seleccionados jutno con los arreglos IDs
     *
     * @param _id
     * @description El ID del proyeccto a agregar al arreglo de proyectos seleccionados
     *
     * @param status
     * @description True, si se va a agregar, ffalse si no
     * */
    onProjectsChange = (_id, status) => {

        let { projectsSelected } = this.state;
        let filter = projectsSelected.findIndex(item => item.toString() === _id.toString())

        if (status) {
            if (filter === -1) projectsSelected.push(_id)
        } else {
            if (filter !== -1) projectsSelected.splice(filter, 1)
        }
        this.setState({ projectsSelected })
        //this.receivedData();
    };


    /**
      * @memberof Acreedores
     * @function onDatesChange
     * @description Se actualizan los valores de las fechas.
     * @param values
     * @description Valores de los rangerspicers
     * */
    onDatesChange = async (values) => {
        try {
            this.state.start = values[0].format('YYYY-MM-DD');
            this.state.end = values[1].format('YYYY-MM-DD');
        } catch (e) {
            this.state.start = null;
            this.state.end = null;
        }
        this.update();
        //this.receivedData();
    };



    /**
    * @memberof Acreedores
     * @function handleVisibleChange
     * @description Permite mostrar u ocultar los dropdowns en general
     * @param type
     * @description Tipo de dropdown (proyeto)
     * @flag
     * @description Si se mostrará u ocultará.
     *
     * */
    handleVisibleChange = (type, flag) => {
        let { dropdowns } = this.state;
        dropdowns[type] = flag;
        this.setState({ dropdowns });
    };



    /**
        * @memberof Acreedores
       * @function receivedConsulta
       * @description Consulta de acreedores para tabla
       * */
    receivedConsulta = () => {

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.setState({ loading: true })

        var page = this.state.currentPage,
            quantity = 10000,
            filter = {},
            sort = {},
            projects = this.state.projectsSelected,
            area = this.state.area,
            rubro = this.state.rubro,

            cuentas = this.state.cuentasSelected,
            tipo = this.state.tipo,
            end = this.state.end,
            start = this.state.start


        var numPage = 1, numReg = 0;

        axios.post('/acreedores/reporteInfo', {
            params: {
                page: page,
                limit: quantity,
                filter,
                sortfield: sort,
                projects,
                cuentas,
                area,
                rubro,

                tipo,
                end,
                start
            }
        })
            .then(res => {

                var data = res.data.data;
                var consultaN = this.state.dataRpt.numConsulta;
                consultaN = consultaN + 1;


                var data_rpt = {
                    author: data.author,
                    fecha: data.fecha,
                    espacio: '',
                    dataInfo: data.clientes,
                    sinFiltros: data.sinFiltros,


                    totalTran: data.total,

                    fecha_inicio: data.fecha_inicio,
                    fecha_fin: data.fecha_fin,
                    area: data.area,
                    rubro: data.rubro,

                    proyectos: data.proyectos,
                    numConsulta: consultaN,

                    montoPagado: data.montoPagado,
                    totalVenta: data.totalVenta,
                    totalPagado: data.totalPagado,
                    totalDeuda: data.totalDeuda,


                    totalMontoPendiente: data.totalMontoPendiente,
                    totalMontoPrestamo: data.totalMontoPrestamo,
                    totalMontoRetorno: data.totalMontoRetorno,
                    totalMontoPagado: data.totalMontoPagado,


                    _totalMontoPendiente: data._TotalMontoPendiente,
                    _totalMontoPrestamo: data._TotalMontoPrestamo,
                    _totalMontoRetorno: data._TotalMontoRetorno,
                    _totalMontoPagado: data._TotalMontoPagado,

                }


                this.setState({
                    dataRpt: data_rpt,
                    loading: false,
                    datosConsultados: true,
                    numConsulta: consultaN
                });


            })
            .catch(res => {
                console.log("error", res);
                this.setState({ dataClientes: [] });
            });



    };


    render() {
        const { onDatesChange, onProjectsChange, onCuentasChange } = this;

        

        return (
            <div>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Acreedores"
                    
                />


                <Content className="admin-content content-bg pd-1">
                    <List
                        loading={this.state.acreedores.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin acreedores " }}
                        dataSource={this.state.acreedores.data}
                        pagination={{
                            current: this.state.acreedores.page,
                            pageSize: this.state.acreedores.limit,
                            total: this.state.acreedores.total,
                            position: 'bottom',
                            className: "flex-left",
                            showSizeChanger: true,
                            onChange: (page, limit) => this.getAcreedores({ page, limit })
                        }}
                        header={<Row className="header-list width-100 pr-1 pl-1" gutter={[16, 16]}>

                            <Col xs={24} lg={3} className="center">
                                <Text strong>Acreedor</Text>
                            </Col>
                            <Col xs={24} lg={3} className="center">
                                <Text strong>Monto a Pagar</Text>
                            </Col>
                            <Col xs={24} lg={3} className="center">
                                <Text strong>Monto Pagado</Text>
                            </Col>
                            <Col xs={24} lg={3} className="center">
                                <Text strong>Monto Pendiente</Text>
                            </Col>
                            <Col xs={24} lg={3} className="center">
                                <Text strong>Contacto</Text>
                            </Col>
                            <Col xs={24} lg={3} className="center">
                                <Text strong>Proyecto</Text>
                            </Col>
                            <Col xs={24} lg={3} className="center">
                                <Text strong>Fecha Prestamo</Text>
                            </Col>
                            <Col xs={24} lg={3} className="center">
                                <Text strong>Acciones</Text>
                            </Col>
                        </Row>}

                        renderItem={item => (
                            <List.Item className="component-list-item">

                                <Card className="card-list">
                                    <Row className="width-100 " gutter={[16, 16]}>

                                        <Col xs={24} lg={3} className="center">
                                            <Text>{item.acreedor}</Text>
                                        </Col>
                                        <Col xs={24} lg={3} className="center">
                                            <Text>$ {item?.monto_retorno?.toMoney(true)} MXN</Text>
                                        </Col>
                                        <Col xs={24} lg={3} className="center">
                                            <Text>$ {item?.monto_pagado?.toMoney(true)} MXN</Text>
                                        </Col>
                                        <Col xs={24} lg={3} className="center">
                                            <Text>$ {item?.monto_pendiente?.toMoney(true)} MXN</Text>
                                        </Col>
                                        <Col xs={24} lg={3} className="center">
                                            <Avatar name={[item.contacto_id.nombre, item.contacto_id.apellidos]} image={item.contacto_id.avatar} />
                                        </Col>
                                        <Col xs={24} lg={3} className="center">
                                            <Tag>{item?.proyecto_id?.nombre}</Tag>
                                        </Col>
                                        <Col xs={24} lg={3} className="center">
                                            <Text>{moment(item.fecha_prestamo).format('ll')}</Text>
                                        </Col>
                                        <Col xs={24} lg={3} className="center">
                                            <Space size="small" direction="horizontal">
                                                <Link title="Editar" to={`/admin/acreedores/detalle/${item._id}`}>
                                                    <Button type="secondary" name={item.nombre} id={item._id}
                                                        title="Ver detalles" icon={<IconDetails />}>
                                                    </Button>
                                                </Link>


                                                <Button
                                                    type="primary"
                                                    disabled={!this.props.editAcreedores}
                                                    icon={<IconEdit />}
                                                    title="Editar"
                                                    onClick={() => {this.setState({acreedor_id: item._id}, () => {this.modalEditar()})}}
                                                />
                                                <Popconfirm
                                                    placement="topRight"
                                                    disabled={!this.props.deleteAcreedores}
                                                    title="¿Deseas eliminar este acreedor?"
                                                    onConfirm={() => { this.eliminarAcreedor(item._id) }}
                                                    okText="Si"
                                                    cancelText="No"
                                                >
                                                    <Button
                                                        type="primary"
                                                        disabled={!this.props.deleteAcreedores}
                                                        icon={<IconDelete />}
                                                        title="Eliminar"
                                                        danger
                                                    />
                                                </Popconfirm>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
                </Content>

                <ModalAcreedores
                    ref={this.ModalAcreedoresRef}
                    modalVisible={this.state.modalVisible}
                    accionModal={this.state.accionModal}
                    acreedor_id={this.state.acreedor_id}
                    closeMethod={() => {this.hideModal(); this.setState({acreedor_id: undefined}); this.getAcreedores()}}
                    accept={() => {
                        this.hideModal();
                        this.getAcreedores();
                    }}
                >
                </ModalAcreedores>

                <Reporte
                    rpt={this.state.dataRpt}
                    reporte={this.state.datosConsultados}
                    numConsulta={this.state.numConsulta}

                    trigger={() =>
                        <Button
                            type="link" size="small"
                            style={{ position: 'relative', top: '0.4em', right: '0.5em' }}
                            onClick={this.receivedConsulta}>
                            <Download />
                        </Button>}
                />

                <FloatingButton className="btnAddPlus" title="Nuevo registro" onClick={this.modalCrear} >
                    <IconPlus />
                </FloatingButton>
            </div>
        )
    }
}

export default function (props) {
    let user = React.useContext(Logged);

    

    let permissions = usePermissions(user?.rol_id?.permisos, {
        
        createAcreedores: ["acreedores", "create"],
		editAcreedores: ["acreedores", "edit"],
		readAcreedores: ["acreedores", "read"],
		deleteAcreedores: ["acreedores", "delete"],

    })

    return <Acreedores {...props} {...permissions}/>
}