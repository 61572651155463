import React from 'react'
import { Route, Routes } from "react-router-dom";

import TransaccionesProgramadasDashboard from "../components/Admin/TransaccionesProgramadas/Dashboard"
import TransaccionesProgramadasLista from "../components/Admin/TransaccionesProgramadas/Lista"
import TransaccionesProgramadaCuentas from "../components/Admin/TransaccionesProgramadas/Cuentas"



/**
 * 
 * @export
 * @function RouterTP
 * @description Router para Transacciones Programdas  
 */
function RouterTP(props) {
    return (
        <Routes>
            <Route path="" element={<TransaccionesProgramadasDashboard {...props} />} />
            <Route path="lista" element={<TransaccionesProgramadasLista {...props} />} />
            <Route path="cuentas" element={<TransaccionesProgramadaCuentas {...props} />} />
        </Routes>
    )
}

export default RouterTP

