import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography, Collapse, Dropdown, Menu, Upload, Switch, Modal } from 'antd'
import axios from 'axios';
import { FaTruck } from "react-icons/fa";
import { EllipsisOutlined, DeleteOutlined } from '@ant-design/icons';
//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import FormRequisicionOpcionAceptada from "./FormRequisicionOpcionAceptada";
import { IconCheckMedalColor, IconPlusGroup, IconFlag, IconEye } from '../../../Widgets/Iconos';

//Modal
import ModalEntrega from "../ModalEntrega"
import ModalRequisicionDetalle from "../ModalRequisicionDetalle"

const { Text } = Typography;
const moment = require('moment')

const { Content } = Layout;
const { Panel } = Collapse;


let colors_flag = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}

/**
 * @export
 * @class ListaEntrega
 * @extends {Component}
 * @description Vista de ListaEntrega
 */
export default class ListaEntrega extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            requisiciones:{
                data: [],
                page: 1,
                limit: 10,
                total: 0,
            },
            modal_visible: false,
            requisicion_id: undefined,

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getRequisiciones()
    }

    /**
     * @methodOf ModalRequisiciones
     * @function getRequisiciones
     * @description Otiene el listado de requeridos
     */
    getRequisiciones = ({
        page,
        limit,
        search,
    } = this.state.requisiciones) => {

        this.setState({loading: true})
        axios.get("/requisiciones",{
            params:{
                page,
                limit,
                search,
                estatus: this.props.estatus,
                entrega: true
            }
        }).then(({data}) => {
            let requisiciones = page === 1 ? [] : [...this.state.requisiciones.data]

            this.setState({
                requisiciones: {
                    data: requisiciones.concat(data.data),
                    page: data.page,
                    total: data.total,
                    limit: data.limit
                }
            })

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener las requisiciones")

        }).finally(()=>this.setState({loading: false}))

    }
    

    next = () => {
        if(this.state.requisiciones.data.length < this.state.requisiciones.total){
            this.getRequisiciones({page: this.state.requisiciones.page + 1})
        }
    }


    render() {

        let { label, name, estatus } = this.props

        return (
            <>
                <Collapse
                    bordered={false}
                    className="mb-1 w-100"
                    defaultActiveKey={[this.props.estatus]}
                    onChange={()=>this.props.panelChange(this.props.estatus)}
                >
                    <Panel
                        header={this.props.label}
                        className={`requisiciones-tab tab-${this.props.name}`}
                        key={this.props.estatus}
                    >
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: this.state.loading ? "Cargando" : "Sin Requisiciones" }}
                            dataSource={this.state.requisiciones.data}
                            renderItem={({_id, producto_id, proyecto_id, volumen, prioridad, opcion_final, almacen_id, activado, volumen_entregado, createdAt, fecha_entrega, unidad_id}, index) => (
                                <List.Item className="component-list-item" key={_id}>
                                    <Collapse className='width-100'>
                                        <Panel
                                            header={
                                                <Row className="width-100 " gutter={[0, 12]}>
                                                    <Col xs={24} md={6} className="center" >
                                                        <Text ellipsis>{producto_id?.nombre}</Text>
                                                    </Col>
                                                    <Col xs={24} md={3} className="center" >
                                                        <Text ellipsis >{producto_id.sku}</Text>
                                                    </Col>
                                                    <Col xs={24} md={3} className="center" >
                                                        <Text ellipsis >{volumen?.toMoney(true)} {unidad_id?.nombre}</Text>
                                                    </Col>
                                                    <Col xs={24} md={3} className="center" >
                                                        <Text ellipsis >{volumen_entregado?.toMoney(true)} {this.props.estatus === 4 ? "Entregado" : "Recolectado"}</Text>
                                                    </Col>
                                                    <Col xs={24} md={4} className="center" >
                                                        <CustomAvatar 
                                                            name={proyecto_id.nombre}
                                                            color={proyecto_id.color}
                                                        />
                                                        <Text>{proyecto_id.nombre}</Text>
                                                    </Col>
                                                    <Col xs={24} md={1} className="center" >
                                                        <IconFlag fill={colors_flag[prioridad] ? colors_flag[prioridad] : '#A4A4A4'} />
                                                    </Col>
                                                    <Col xs={24} md={3} className="center" >
                                                        <Text ellipsis >{moment(createdAt).format("DD/MM/YYYY")}</Text>
                                                    </Col>
                                                    <Col xs={24} md={1} className="center">
                                                        <Button
                                                            type="primary"
                                                            icon={<IconEye/>}
                                                            onClick={(e)=>{
                                                                this.setState({modal_visible_detalle: true, requisicion_id: _id})
                                                                e.stopPropagation()
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            }
                                            key={_id}
                                            className={`requisiciones-item-${name}`}
                                        >
                                            <List
                                                className='component-list'
                                                itemLayout="horizontal"
                                                locale={{ emptyText: "Sin proveedores" }}
                                                dataSource={[{...opcion_final}]}
                                                renderItem={item => (
                                                    <List.Item className="width-100">
                                                        <Row className="width-100">
                                                        	<Col span={10} className="center">
                                                        		<Text> Almacen: <strong>{almacen_id?.nombre}</strong> </Text>
                                                        	</Col>
                                                        	<Col span={10} className="center">
                                                        		<Text> Fecha de Entrega: <strong>{moment(fecha_entrega).format("DD/MM/YYYY")}</strong> </Text>
                                                        	</Col>
                                                        	<Col span={40} className="center">
                                                        		<Button
                                                        			type="primary"
		                                                            icon={<FaTruck/>}
		                                                            className="center"
		                                                            onClick={()=>this.setState({modal_visible: true, requisicion_id: _id})}
		                                                        />
                                                        	</Col>
                                                        </Row>
                                                    </List.Item>
                                                )}
                                            />
                                        </Panel>
                                    </Collapse>
                                </List.Item>
                            )}
                        />

                    </Panel>
                </Collapse>
                <ModalEntrega
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({modal_visible: false, requisicion_id: undefined})
                        if(flag === true){
                            this.props.getRequisiciones([4,5,6])
                        }
                    }}
                    requisicion_id={this.state.requisicion_id}
                    estatus={this.props.estatus}
                />
                <ModalRequisicionDetalle
                    visible={this.state.modal_visible_detalle}
                    onClose={(flag)=>{
                        this.setState({modal_visible_detalle: false, requisicion_id: undefined})
                        
                    }}
                    requisicion_id={this.state.requisicion_id}
                />
            </>
        )
    }
}
