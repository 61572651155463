import React, { Component } from 'react';
import { Button, Col, Row, Select, Typography, Card, Checkbox, message, Spin, Empty, Tag, Space, Collapse } from 'antd'
import axios from 'axios';


//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { IconFlag, IconCheckMedalColor } from '../../../Widgets/Iconos';

const { Title, Text } = Typography;
const { Panel } = Collapse;
const moment = require('moment')

let colors = {
    0: '#B706F5', //Requerido
    1: '#EDF10A', //Cotizado
    2: '#F57906', //Pausado
    3: '#F50606', //Cancelado
    4: '#6106F5', //Entrega
    5: '#F506B2', //Recoleccion
    6: '#08E453', //Entregado
}

let colors_flag = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}

let unidades = {
    'H87': 'Pieza',
    'EA': 'Elemento',
    'KGM': 'Kilogramo',
    'MTR': 'Metro',
    'KT': 'Kit',
    'SET': 'Conjunto',
    'LTR': 'Litro',
    'XBX': 'Caja',
    'xun': 'Unidad',
    'GRM': 'Gramo',
    'BUT': 'Bulto',
    'TON': 'Tonelada',
}

/**
 * @class ListasRequisiciones
 * @description Retorna una lista de las requisiciones, dependiendo del estatus 
 */
export default class ListasRequisiciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            data: [],
            requisiciones:{
                data: [],
                page: 1,
                limit: 10,
                total: 0,
            },
            page: 1,
            limit: 10,
            total: 0
        }
    }

    /***
     *
     * @methodOf ListasRequisiciones
     * @function componentDidMount
     * @description
     */
    componentDidMount() {
        this.getRequisiciones()

    }

    componentDidUpdate(prevProps) {
        if (prevProps.search != this.props.search) {
            this.getRequisiciones()
        }
    }

    /**
     * @methodOf ModalRequisiciones
     * @function getRequisiciones
     * @description Otiene el listado de requeridos
     */
    getRequisiciones = ({
        page,
        limit,
        search,
    } = this.state.requisiciones) => {

        this.setState({loading: true})
        axios.get("/requisiciones",{
            params:{
                page,
                limit,
                search,
                estatus: this.props.estatus
            }
        }).then(({data}) => {
            let requisiciones = page === 1 ? [] : [...this.state.requisiciones.data]
                    console.log("data.data", data.data);
            this.setState({
                requisiciones: {
                    data: requisiciones.concat(data.data),
                    page: data.page,
                    total: data.total,
                    limit: data.limit
                }
            })

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener las requisiciones")

        }).finally(()=>this.setState({loading: false}))

    }


     /***
     *
     * @methodOf ListaRequisicion
     * @function activate
     * @description Actualiza el marcador de activacion de la requisicion
     * 
     * @params requisicion_id id de la requisisicion a actualizar
     */
    activate = (requisicion_id, index) => {
        axios.put('/requisiciones',{
            requisicion_id,
            activado: true
        }).then(response => {
            let data = this.state.requisiciones.data
            data[index].activado = response.data.activado
            this.setState({
                requisiciones:{
                    ...this.state.requisiciones,
                    data
                }
            })
        }).catch(error => {
            message.error(error?.response?.data.message ?? "Error al actualizar")
        })
    }

    next = () => {
        if(this.state.requisiciones.data.length < this.state.requisiciones.total){
            this.getRequisiciones({page: this.state.requisiciones.page + 1})
        }
    }

    render() {

        const { estatus, title } = this.props

        return (
            <Spin spinning={this.state.loading}>
                <Collapse
                    bordered={false}
                    className="mb-1 w-100"
                    style={{background: "transparent"}}
                    defaultActiveKey={[this.props.estatus]}
                    onChange={()=>this.props.panelChange(this.props.estatus)}
                >
                    <Panel
                        header={this.props.label}
                        className={`requisiciones-tab tab-${this.props.name}`}
                        key={this.props.estatus}
                    >
                            {

                                this.state.requisiciones.data.map((item, index) => {
                                    return <Card size="small" className="card-list-small hover  " onClick={() => this.props.openModalDetalle(item._id)}>
                                        <Row style={{ width: '100%' }} className="">
                                            <Col xs={12} md={2} className="center">
                                                <Text ellipsis>{item.folio}</Text>
                                            </Col>
                                            <Col xs={12} md={5} className="flex-column text-center">
                                                <Text ellipsis>{item.producto_id?.nombre}</Text>
                                                <Text ellipsis>{item.producto_id.sku}</Text>
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                <Text ellipsis>{item?.categoria_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                <Text ellipsis>{item.volumen} {item?.unidad_id?.nombre}</Text>
                                            </Col>
                                            <Col xs={12} md={4} className="center">
                                                <CustomAvatar
                                                    size="large"
                                                    url={{
                                                        url:`${axios.defaults.baseURL}/proyectos/logo/${item?.proyecto_id?._id}`,
                                                        name: item?.proyecto_id?.logo?.name
                                                    }}
                                                    name={item.proyecto_id.nombre}
                                                    color={item.proyecto_id.color} 
                                                />
                                                <Text ellipsis>{item.proyecto_id.nombre}</Text>
                                                
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                {item.inmuebles?.length > 0 ? <Tag color="green" style={{ marginRight: '4px', marginLeft: '0px' }}>{item.inmuebles[0].nombre}</Tag> : ''}
                                                {item.inmuebles?.length > 1 ? <Tag color="green" style={{ marginRight: '4px', marginLeft: '0px' }}>...</Tag> : ''}
                                            </Col>
                                            <Col span={2} className="center">
                                                <Space size={15}>
                                                    <IconFlag fill={colors_flag[item.prioridad] ? colors_flag[item.prioridad] : '#A4A4A4'} />
                                                    <Button
                                                        className={`btn-permission ${item.activado ? 'active' : ''}`}
                                                        type="ghost"
                                                        icon={<IconCheckMedalColor />}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            this.activate(item._id, index)
                                                        }}
                                                    />
                                                </Space>
                                            </Col>
                                            <Col xs={12} md={2} className="center">
                                                <Text className="text-gray">{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                })
                            }
                    </Panel>
                </Collapse>
            </Spin>

        )
    }
}
