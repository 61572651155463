import React, { Component } from 'react';
import { Card, Layout, Modal, Form, Input, message, Spin, Row, Col, Typography, Tag, Space, Tooltip, InputNumber } from 'antd'
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";



import './Cards.scss'

const { Content } = Layout;
const { Title, Text } = Typography;

export class CardProveedores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            producto: {}
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        

        this.getProducto()

    }

    getProducto = () => {
        axios.get(`/productos/${this.props.producto_id}`)
            .then(res => {
                
                this.setState({
                    producto: res.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }


    render() {
        return (
            <>
                <Card className={`card-shadow card-producto ${this.props.className}`}>
                    <Row align='middle' className='mb-2'>
                        <Col span={24}>
                            <Text className='card-title'>Proveedores</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Row>
                                <Col span={24}>
                                    <Text className='sub-title'>Mejor Precio Actual</Text>
                                </Col>
                                <Col span={24}>
                                    <Text className='mejor'>$ {this.state?.producto?.mejor?.costo ? this.state?.producto?.mejor?.costo.toMoney(true) : 0} MXN</Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={24} >
                                    <Text className='sub-title'>Proveedor</Text>
                                </Col>
                                <Col span={24}>
                                    <Text className='proveedor'>{this.state?.producto?.mejor?.proveedor_id?.alias}</Text>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={12}>
                            <Row>
                                <Col span={24} className="mt-1">
                                    <Text className='sub-title'>Peor Precio Actual</Text>
                                </Col>
                                <Col span={24}>
                                    <Text className='peor'>$ {this.state?.producto?.peor?.costo ? this.state?.producto?.peor?.costo.toMoney(true) : 0} MXN</Text>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={24} className="mt-1">
                                    <Text className='sub-title'>Proveedor</Text>
                                </Col>
                                <Col span={24}>
                                    <Text className='proveedor'>{this.state?.producto?.peor?.proveedor_id?.alias}</Text>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                    <Row>
                        <Col span={24} className="mt-1">
                            <Text className='sub-title'>Proveedores</Text>
                        </Col>
                        <Col span={24}>
                            <Text className='title'>{this.state?.producto?.proveedores ? this.state?.producto?.proveedores : 0} {this.state?.producto?.proveedores === 1 ? "Proveedor": "Proveedores"}</Text>
                        </Col>
                    </Row>
                </Card>
            </>
        )
    }
}



export default function (props) {



    return <CardProveedores {...props} />
}