import * as RRD from 'react-router-dom'

const useSearchParams = () => {
    const [params, setParams] = RRD.useSearchParams()
    const obj = Object.fromEntries(new URLSearchParams(params))
    const types = {
        limit: 'number',
        page: 'number',
        filters: 'json'
    } 

    for(let key in obj) {
        switch(types[key]){
            case 'number':
                obj[key] = Number(obj[key])
                break
            case 'json':
                obj[key] = JSON.parse(obj[key])
                break
            default:
                break
        }
    }

    return [obj, setParams]
}

export default useSearchParams