import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const SelectAcreedor
 * @description Select para los acreedores registrados en el sistema
 */
const SelectAcreedor = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el acreedor", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        mode = null
    } = props


    const [ acreedores, setAcreddores ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getAcreedores
     * @description Obitiene los acreedores
     */
    const getAcreedores = ({page, limit, search} = acreedores) => {

        axios.get('/acreedores', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setAcreddores(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener los acreedores')
        })
    }

    //componentDidMount
    useEffect(() => {
        getAcreedores()
    }, [])

    useEffect(() => {
        onChange(value?.value ?? value)
    }, [value]) 

    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            mode={mode}
            onSearch={(search)=> getAcreedores({search})}
            onSelect={(acreedor)=> {
                if(mode === null){
                    onChange(acreedor.value)
                    onSelect(acreedor.value)
                }
            }}
            onChange={(values)=>{
                if(mode === "multiple"){
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {
                acreedores?.data?.map(({ _id, acreedor, monto_pendiente }) => <Option value={_id} >
                    <div className="flex-between"><span>{acreedor ?? ""}</span> <small>$ { monto_pendiente.toMoney(true)}</small></div>
                </Option>)
            }
        </Select>
    );
}



export default SelectAcreedor;