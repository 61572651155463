import React, { Component } from "react";
import { Route, Routes, Redirect } from "react-router-dom";
import { Layout } from "antd";
import '../Styles/Global/admin.css';


import Navbar from "../components/Header/Header";
import AdminSidebar from "../components/Sidebar/Sidebar.js"

// Routers
import RouterCRM from "./RouterCRM"
import RouterContabilidad from "./RouterContabilidad"
import RouterDashboard from "./RouterDashboard"
import RouterFinance from "./RouterFinance"
import RouterAreas from "./RouterAreas"
import RouterCuentas from "./RouterCuentas"
import RouterEmpresas from "./RouterEmpresas"
import RouterRoles from "./RouterRoles"
import RouterUsuarios from "./RouterUsuarios"
import RouterProveedores from "./RouterProveedores"
import RouterBudgeting from "./RouterBudgeting"
import RouterProyectos from "./RouterProyectos"

import RouterCategorias from "./RouterCategorias"
import RouterAlmacenes from "./RouterAlmacenes"
import RouterProductos from "./RouterProductos"
import RouterFinancialHealth from "./RouterFinancialHealth";
import RouterAcreedores from "./RouterAcreedores";
import RouterTP from "./RouterTP";
import RouterRequisiciones from "./RouterRequisiciones";

import RouterEmpleados from "./RouterEmpleados";

const { Content } = Layout;

class AdminRoutes extends Component {

	constructor(props){
		super(props)
		this.state = {
			search: null
		}
	}


	SetFilterSearch(e) {
        this.setState({ search: e })
    }

	render() {
		return (
			<Layout className="layout" >
				<Navbar 
					setFilterSearch={(e) => this.SetFilterSearch(e)}
				/>
				<Layout>
					<AdminSidebar />
					<Content className="admin-content">
						<Routes>
							<Route path="dashboard/*" 			element={<RouterDashboard search={this.state.search} />} />
							<Route path="finanzas/*" 	 		element={<RouterFinance search={this.state.search} />} />
							<Route path="contabilidad/*" 		element={<RouterContabilidad search={this.state.search} />} />
							<Route path="cuentas/*"  			element={<RouterCuentas search={this.state.search} />} />
							<Route path="budgeting/*"  			element={<RouterBudgeting search={this.state.search} />} />
							<Route path="empresas/*" 			element={<RouterEmpresas search={this.state.search} />} />
							<Route path="areas/*"  				element={<RouterAreas search={this.state.search} />} />
							<Route path="crm/*" 				element={<RouterCRM search={this.state.search} />} />
							<Route path="roles/*" 				element={<RouterRoles search={this.state.search} />} />
							<Route path="usuarios/*" 			element={<RouterUsuarios search={this.state.search} />} />
							<Route path="proveedores/*" 		element={<RouterProveedores search={this.state.search} />} />
							<Route path="proyectos/*" 			element={<RouterProyectos search={this.state.search} />} />
							<Route path="almacenes/*" 			element={<RouterAlmacenes search={this.state.search} />} />
							<Route path="productos/*" 			element={<RouterProductos search={this.state.search} />} />
							<Route path="categorias/*" 			element={<RouterCategorias search={this.state.search} />} />
							<Route path="financial-health/*" 	element={<RouterFinancialHealth search={this.state.search} />} />
							<Route path="acreedores/*" 			element={<RouterAcreedores search={this.state.search} />} />
							<Route path="transacciones-programadas/*" element={<RouterTP search={this.state.search} />} />
							<Route path="requisiciones/*" 		element={<RouterRequisiciones search={this.state.search} />} />
							<Route path="empleados/*" 			element={<RouterEmpleados search={this.state.search} />} />
						</Routes>
					</Content>
				</Layout>
			</Layout>
		)
	}
}

export default AdminRoutes