import React, { Component } from 'react';
import { Row, Col, Spin, PageHeader, Layout, Card, Typography, Space, Statistic, Tabs, Tooltip, Empty } from "antd";
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom";
import { FloatingMenu, MainButton, ChildButton } from 'react-floating-button-menu';
import { ClockCircleOutlined, CloseOutlined, DollarCircleFilled, PlusOutlined, SolutionOutlined, EditOutlined } from '@ant-design/icons';

//Componentes
import Logged from "../../../Hooks/Logged"
import FacturasTab from "./Secciones/FacturasTab";
import { IconArrowBack } from '../../Widgets/Iconos';
import CotizacionesTab from "./Secciones/CotizacionesTab";
import usePermissions from "../../../Hooks/usePermissions";
import TransaccionesTab from "./Secciones/TransaccionesTab";
import TransaccionesProgramadasTab from "./Secciones/TransaccionesProgramadasTab";

//Modal
import ModalProveedores from "./ModalProveedores";
import ModalCotizacion from "./Secciones/ModalCotizacion";
import ModalTransaccion from '../Finanzas/Transacciones/ModalTransaccion';
import ModalTransaccionProgramada from "../Finanzas/Transacciones/ModalTransaccionProgramada";

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography;
const { TabPane } = Tabs;

/**
 * @class ProveedorDetalle
 * @description Vista de detalle del Proveedor
 */
class ProveedorDetalle extends Component {

    volver = () => {
        window.history.back();
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            proveedor_id: this.props.params.proveedor_id,
            proveedor: {},
            tab: "transacciones",
        };
    }

    transaccionesRef = React.createRef()
    facturasRef = React.createRef()
    cotizacionesRef = React.createRef()
    programadasRef = React.createRef()

    componentDidMount() {
        console.clear()
        this.getProveedor()
        
    }


    /**
     * @memberOf ProveedorDetalle
     * @method getProveedor
     * @description Obtiene la información del proveedor
     * */
    getProveedor = () => {
        axios.get(`/proveedores/${this.props.params.proveedor_id}`,{

        }).then(({data}) => {
            this.setState({
                proveedor: data
            })

        }).catch(error => {
            console.log("error", error);

        })
    }

    /**
     * @memberOf ProveedorDetalle
     * @method updateAll
     * @description Actualiza la informacion del proveedor
     * */
    updateAll = () => {
        this.getProveedor()
        if(this.state.tab === "transacciones")
            this.transaccionesRef.current.getTransacciones()
        if(this.state.tab === "facturas")
            this.facturasRef.current.getFacturas()
        if(this.state.tab === "cotizaciones")
            this.cotizacionesRef.current.getFacturas()
        if(this.state.tab === "programadas")
            this.programadasRef.current.getTransacciones()
    }

    render() {

        const { proveedor } = this.state;

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        onBack={this.volver}
                        className="site-page-header custom-page-header"
                        title="Proveedor"
                        backIcon={<IconArrowBack />}
                    />
                    <Content className="pd-1">
                        <Card bordered loading={this.state.loading}>
                            <Row gutter={[24, 24]}>
                                <Col xs={24}>
                                    <Title level={4} >Información del proveedor</Title>
                                </Col>
                                <Col xs={24}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} lg={12} xl={6} className="">
                                            <Paragraph>Nombre</Paragraph>
                                            <Text style={{ fontSize: 16 }}>{proveedor.alias}</Text>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6} className="">
                                            <Paragraph>Razón Social</Paragraph>
                                            <Text style={{ fontSize: 16 }}>{proveedor.razon_social}</Text>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6} className="">
                                            <Paragraph>Línea de crédito</Paragraph>
                                            <Statistic
                                                valueStyle={{ fontSize: 16 }}
                                                value={proveedor.linea_credito}
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                        <Col xs={24} lg={12} xl={6} className="">
                                            <Paragraph>Plazo de crédito</Paragraph>
                                            <Text style={{ fontSize: 16 }}>{proveedor.plazo} días</Text>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6} className="">
                                            <Paragraph>Monto Facturado</Paragraph>
                                            <Statistic
                                                valueStyle={{ fontSize: 16, color: "#DC3545"}}
                                                value={proveedor.monto_facturado}
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                        <Col xs={24} lg={12} xl={6} className="">
                                            <Paragraph>Monto Actual Pagado</Paragraph>
                                            <Statistic
                                                valueStyle={{ fontSize: 16, color: "#28A745" }}
                                                value={proveedor.monto_pagado}
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                        <Col xs={24} lg={12} className="">
                                            <Paragraph>Saldo Total</Paragraph>
                                            <Statistic
                                                valueStyle={{ fontSize: 16 }}
                                                value={proveedor.monto_facturado - (proveedor.monto_pagado)}
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                        <Col xs={24} lg={12} xl={6} className="">
                                            <Paragraph>Monto Cotizado</Paragraph>
                                            <Statistic
                                                valueStyle={{ fontSize: 16, color: "#DC3545" }}
                                                value={proveedor.monto_cotizado}
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                        <Col xs={24} lg={12} xl={6} className="">
                                            <Paragraph>Monto Cotizado Pagado</Paragraph>
                                            <Statistic
                                                valueStyle={{ fontSize: 16, color: "#28A745" }}
                                                value={proveedor.monto_cotizado_pagado}
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                        <Col xs={24} lg={6} className="">
                                            <Paragraph>Saldo Total</Paragraph>
                                            <Statistic
                                                valueStyle={{ fontSize: 16 }}
                                                value={proveedor.monto_cotizado - proveedor.monto_cotizado_pagado}
                                                precision={2}
                                                prefix={'$'} suffix={"MXN"} />
                                        </Col>
                                        <Col xs={24} lg={6} className="">
                                            <Paragraph>Documentos <EditOutlined  
                                                className="hover" 
                                                style={{color: '#2c77f9', fontSize: '20px'}}
                                                onClick={()=>this.setState({modal_visible: true})}
                                            /></Paragraph>
                                            
                                        </Col>

                                        
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                        <Row>
                            <Col span={24}>
                                {!this.props.readTransacciones ? <Empty className='mt-1' description="No tiene permisos para ver transacciones, transacciones programadas o facturas" /> : null}
                                <Tabs className="tabs-proyectos tabs-crm-client"
                                    onChange={tab => this.setState({ tab })}
                                    destroyInactiveTabPane
                                    activeKey={this.state.tab}
                                >
                                    {this.props.readTransacciones ? <TabPane tab="Transacciones" key="transacciones">
                                        <TransaccionesTab
                                            ref={this.transaccionesRef}
                                            proveedor_id={this.state.proveedor_id}
                                            updateAll={() => {
                                                this.updateAll()
                                            }}
                                        />
                                    </TabPane> : null} 
                                    {this.props.readFacturas ? <TabPane tab="Facturas" key="facturas">
                                        <FacturasTab
                                            ref={this.facturasRef}
                                            proveedor_id={this.state.proveedor_id}
                                            updateAll={() => {
                                                this.updateAll()
                                            }}
                                        />
                                    </TabPane> : null}
                                    {this.props.readFacturas ? <TabPane tab="Cotizaciones" key="cotizaciones">
                                        <CotizacionesTab
                                            ref={this.cotizacionesRef}
                                            proveedor_id={this.state.proveedor_id}
                                            updateAll={() => {
                                                this.updateAll()
                                            }}
                                            editCotizacion={({factura_id}) => this.setState({factura_id, model_factura_visible:  true})}
                                        />
                                    </TabPane> : null} 
                                    {this.props.readTransaccionesProgramadas ? <TabPane tab="Transacciones Programadas" key="programadas">
                                        <TransaccionesProgramadasTab
                                            ref={this.programadasRef}
                                            proveedor_id={this.state.proveedor_id}
                                            updateAll={() => {
                                                this.updateAll()
                                            }}
                                            editTransaccion={({transaccion_programada_id}) => this.setState({transaccion_programada_id, modal_visible_programadas:  true})}
                                            
                                        />
                                    </TabPane> : null }
                                </Tabs> 
                            </Col>
                        </Row>
                    </Content>
                </Spin>
                <FloatingMenu
                    slideSpeed={500}
                    direction="left"
                    spacing={8}
                    isOpen={this.state.isOpen}
                    style={{

                        position: "fixed",
                        bottom: "1em",
                        right: "1.2em"

                    }}
                >
                    <MainButton
                        iconResting={<PlusOutlined style={{ fontSize: 30, color: "white" }} />}
                        iconActive={<CloseOutlined style={{ fontSize: 30, color: "white" }} />}
                        style={{ backgroundColor: "#2C77F9" }}
                        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                        size={70}
                        direction="right"
                    />
                    <ChildButton
                        icon={
                            <Tooltip mouseLeaveDelay={0} title="Agregar una Transacción" placement="top">
                                <DollarCircleFilled style={{ fontSize: 50, color: this.props.createTransacciones ? "#2C77F9" : "#2C77F980" }} nativeColor="black" />
                            </Tooltip>
                        }
                        background="white"
                        size={50}
                        onClick={this.props.crearTransacciones || true ? () => this.setState({ modalTransaccionVisible: true, tipo_pago: undefined, isOpen: false }) : undefined}
                    />

                    <ChildButton
                        icon={
                            <Tooltip mouseLeaveDelay={0} title="Agregar una Cotización" placement="top">
                                <SolutionOutlined style={{ fontSize: 30, color: "white" }} nativeColor="black" />
                            </Tooltip>
                        }
                        background={ this.props.createFacturas ? "#2C77F9" : "#2C77F980" }
                        size={50}
                        onClick={this.props.createFacturas ? () => this.setState({ model_factura_visible: true }) : undefined}
                    />
                    <ChildButton
                        icon={
                            <Tooltip mouseLeaveDelay={0} title="Agregar una Transacción Programada" placement="top">
                                <div style={{ width: "100%", height: "100%", textAlign: "center", position: "relative", top: 5 }}>
                                    <ClockCircleOutlined style={{ fontSize: 40, color: "white" }} nativeColor="black" />
                                </div>
                            </Tooltip>
                        }
                        background={this.props.createTransaccionesProgramadas ? "#2C77F9" : "#2C77F980"}
                        size={50}
                        onClick={this.props.createTransaccionesProgramadas ? () => this.setState({ modal_visible_programadas: true }) : null}
                    />
                </FloatingMenu>


                <ModalTransaccion
                    visible={this.state.modalTransaccionVisible}
                    onClose={() => {
                        this.setState({modalTransaccionVisible: false})
                        this.updateAll()
                    }}
                    proveedor_id={this.state.proveedor_id}
                    area
                    rubro
                    proyecto
                    cliente={false}
                    propiedad={false}
                    inversion={false}
                    acreedor={false}
                    transaccion_programada={true}
                    empresa
                    budgeting={false}
                    proveedor={true}
                    initialValues={{
                        tipo: 2,
                        proveedor_id: {
                            value: proveedor._id,
                            key: proveedor._id,
                            label: proveedor.alias
                        }
                    }}
                />
                <ModalCotizacion
                    visible={this.state.model_factura_visible}
                    onClose={() => {
                        this.setState({model_factura_visible: false, factura_id: undefined})
                        this.updateAll()
                    }}
                    proveedor_id={proveedor._id}
                    factura_id={this.state.factura_id}
                />

                <ModalTransaccionProgramada
                    visible={this.state.modal_visible_programadas}
                    onClose={() => {
                        this.setState({modal_visible_programadas: false, transaccion_programada_id: undefined})
                        this.updateAll()
                    }}
                    proveedor_id={{value: proveedor._id, label: proveedor.alias}}
                    transaccion_programada_id={this.state.transaccion_programada_id}
                />

                <ModalProveedores
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({modal_visible: false})
                        if(flag === true )
                            this.updateAll()
                    }}
                    proveedor_id={proveedor._id}
                />
            </>
        );
    }
}


export default function (props) {
    
    
    let user = React.useContext(Logged)

    
    let permissions = usePermissions(user?.rol_id?.permisos, {
        readTransacciones: ['proveedores','transacciones','read'],
        createTransacciones: ['proveedores','transacciones','create'],

        readTransaccionesProgramadas: ['proveedores','transacciones-programadas','read'],
        createTransaccionesProgramadas: ['proveedores','transacciones-programadas','create'],

        readFacturas: ['proveedores','facturas','read'],
        createFacturas: ['proveedores','facturas','create'],

        editProveedores: ["proveedores", "edit"],
        createProveedores: ["proveedores", "create"],
        deleteProveedores: ["proveedores", "delete"],

    })
        console.clear();
        console.log("permissions", permissions);

    return <ProveedorDetalle {...props} {...permissions} params={useParams()} navigate={useNavigate()}/>
}