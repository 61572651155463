import React from 'react'
import { Route, Routes } from "react-router-dom";

import Proyectos from '../components/Admin/Proyectos/Proyectos';
import Propiedades from '../components/Admin/Proyectos/Propiedades';
import Conceptos from '../components/Admin/Conceptos/Conceptos';
import ConceptoDetalle from '../components/Admin/Conceptos/ConceptoDetalle';

/**
 * 
 * @export
 * @function RouterUsuarios
 * @description Router for Usuarios routes 
 */
function RouterUsuarios(props) {
  return (
    <Routes>
      <Route path="" element={<Proyectos {...props} />} />
        <Route path="/conceptos/:proyecto_id" element={<Conceptos {...props} />} /> 
        <Route path="/transacciones/:subconcepto_id" element={<ConceptoDetalle {...props} />} /> 
        <Route path="/propiedades/:propiedad_id" element={<Propiedades {...props} />} />
    </Routes>
  )
}

export default RouterUsuarios