import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography, Collapse, Dropdown, Menu, Upload, Switch, InputNumber } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

import { BsFillFlagFill, BsCheckLg } from 'react-icons/bs';
import { IconCheckBtn, IconCheckMedalColor, IconPlusGroup, IconFlag } from '../../../Widgets/Iconos'
import FormRequisicionOpcion from "./FormRequisicionOpcion"


//Modales
import ModalProductoProveedor from "./ModalProductoProveedor"

import CustomAvatar from '../../../Widgets/Avatar/Avatar';






const { Text } = Typography;
const moment = require('moment')

const { Content } = Layout;
const { Panel } = Collapse;


let colors_flag = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}

/**
 * @export
 * @class ListaRequerido
 * @extends {Component}
 * @description Vista de ListaRequerido
 */
export default class ListaRequerido extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            requisiciones:{
                data: [],
                page: 1,
                limit: 10,
                total: 0,
            },
            requisicion_id: undefined,
            modal_visible: false,

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getRequisiciones()
    }

    /**
     * @methodOf ModalRequisiciones
     * @function getRequisiciones
     * @description Otiene el listado de requeridos
     */
    getRequisiciones = ({
        page,
        limit,
        search,
    } = this.state.requisiciones) => {

        this.setState({loading: true})
        axios.get("/requisiciones",{
            params:{
                page,
                limit,
                search,
                estatus: 1
            }
        }).then(({data}) => {
            let requisiciones = page === 1 ? [] : [...this.state.requisiciones.data]

            this.setState({
                requisiciones: {
                    data: requisiciones.concat(data.data),
                    page: data.page,
                    total: data.total,
                    limit: data.limit
                }
            })

        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener las requisiciones")

        }).finally(()=>this.setState({loading: false}))

    }

    next = () => {
        if(this.state.requisiciones.data.length < this.state.requisiciones.total){
            this.getRequisiciones({page: this.state.requisiciones.page + 1})
        }
    }


    /**
     * @methodOf ModalRequisiciones
     * @function updateRequisicionOpcion
     * @description Actualiza la informacion de una requisiscion opcion
     */
    updateRequisicionOpcion = (values) => {
        axios.put('/requisicion-opcion',{
            ...values
        }).then(response => {
            console.log("Actualizado")
        }).catch(error => {
            console.log("No Actualizado ")
        })
    }


    /***
     *
     * @methodOf ListasCotizacionRequerido
     * @function activate
     * @description Actualiza el marcador de activacion de la requisicion
     * 
     * @params requisicion_id id de la requisisicion a actualizar
     */
    activate = (requisicion_id, index) => {
        axios.put('/requisiciones',{
            requisicion_id,
            activado: true
        }).then(response => {
            let data = this.state.requisiciones.data
            data[index].activado = response.data.activado
            this.setState({
                requisiciones:{
                    ...this.state.requisiciones,
                    data
                }
            })
        }).catch(error => {
            message.error(error?.response?.data.message ?? "Error al actualizar")
        })
    }


    /***
     *
     * @methodOf ListasCotizacionRequerido
     * @function addOpcion
     * @description Al añadir una requisicion_opcion, la agrega al array de las opciones
     */
    addOpcion = (requisicion_opcion) => {
        let data = this.state.requisiciones.data;

        if(this.state.index_requisicion >= 0){
            data[this.state.index_requisicion].opciones.push(requisicion_opcion)

            this.setState({
                requisiciones:{
                    ...this.state.requisiciones,
                    data,
                },
                modal_visible: false, 
                requisicion_id: undefined, 
                index_requisicion: undefined
            })
        }
    }

    /***
     *
     * @methodOf ListasCotizacionRequerido
     * @function aceptarOpcion
     * @description Acepta una requisicion_opcion y actualiza el estatus de la requisicion
     */
    aceptarOpcion = ({requisicion_opcion_id}) => {
        this.setState({loading: true})
        axios.post('/requisicion-opcion/aceptar',{
            requisicion_opcion_id
        }).then(response => {
            this.props.getRequisiciones([1,2])
        }).catch(error=>{
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al aceptar al proveedor")
        }).finally(()=>{
            this.setState({loading: false})
        })
    }



    render() {

        let { label, name } = this.props

        return (
            <>
                <Collapse
                    bordered={false}
                    className="mb-1 w-100"
                    defaultActiveKey={['1']}
                    onChange={()=>this.props.panelChange(1)}
                >
                    <Panel
                        header={'Requerido'}
                        className={`requisiciones-tab tab-requerido`}
                        key="1"
                    >
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: this.state.loading ? "Cargando" : "Sin Requisiciones" }}
                            dataSource={this.state.requisiciones.data}
                            renderItem={({_id, producto_id, proyecto_id, volumen, prioridad, opciones, categoria_id, activado, unidad_id, createdAt}, index) => (
                                <List.Item className="component-list-item" key={_id}>
                                    <Collapse className='width-100'>
                                        <Panel
                                            header={
                                                <Row className="width-100 " gutter={[0, 12]}>
                                                    <Col xs={24} md={5} className="center" >
                                                        <Text ellipsis>{producto_id?.nombre}</Text>
                                                    </Col>
                                                    <Col xs={24} md={3} className="center" >
                                                        <Text ellipsis >{categoria_id.nombre}</Text>
                                                    </Col>
                                                    <Col xs={24} md={3} className="center" >
                                                        <Text ellipsis >{producto_id.sku}</Text>
                                                    </Col>
                                                    <Col xs={24} md={3} className="center" >
                                                        <Text ellipsis >{volumen?.toMoney(true)} {unidad_id?.nombre}</Text>
                                                    </Col>
                                                    <Col xs={24} md={4} className="center" >
                                                        <CustomAvatar 
                                                            name={proyecto_id.nombre}
                                                            color={proyecto_id.color}
                                                            url={{
                                                                url:`${axios.defaults.baseURL}/proyectos/logo/${proyecto_id?._id}`,
                                                                name: proyecto_id?.logo?.name
                                                            }}
                                                        />
                                                        <Text>{proyecto_id.nombre}</Text>
                                                    </Col>
                                                    <Col xs={24} md={1} className="center" >
                                                        <IconFlag fill={colors_flag[prioridad] ? colors_flag[prioridad] : '#A4A4A4'} />
                                                    </Col>
                                                    <Col xs={24} md={3} className="center" >
                                                        <Text ellipsis >{moment(createdAt).format("DD/MM/YYYY")}</Text>
                                                    </Col>
                                                    <Col xs={24} md={1} className="center">
                                                        <Button
                                                            className={`btn-permission ${activado ? 'active' : ''}`}
                                                            type="ghost"
                                                            icon={<IconCheckMedalColor/>}
                                                            onClick={(e)=>{
                                                                e.stopPropagation()
                                                                this.activate(_id, index)
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={24} md={1} className="center">
                                                        <Button 
                                                            type="primary" 
                                                            className="center" 
                                                            icon={<IconPlusGroup />} 
                                                            title="Añadir Proveedor"
                                                            onClick={(e)=>{
                                                                e.stopPropagation()
                                                                this.setState({modal_visible: true, requisicion_id: _id, index_requisicion:index}) 
                                                            }} 
                                                        />
                                                    </Col>
                                                </Row>
                                            }
                                            key={_id}
                                            className={`requisiciones-item-requerido`}
                                        >
                                            <List
                                                className='component-list'
                                                itemLayout="horizontal"
                                                locale={{ emptyText: "Sin proveedores" }}
                                                dataSource={opciones}
                                                header={
                                                    <Row className="width-100 ">
                                                        <Col span={5} className="center" >
                                                            <Text className="text-gray" ellipsis>Proveedor</Text>
                                                        </Col>
                                                        <Col span={4} className="center" >
                                                            <Text className="text-gray" ellipsis >Precio Unitario</Text>
                                                        </Col>
                                                        <Col span={3} className="center" >
                                                            <Text className="text-gray" ellipsis >Dias de entrega</Text>
                                                        </Col>
                                                        <Col span={3} className="center" >
                                                            <Text className="text-gray" ellipsis >Costo envio</Text>
                                                        </Col>
                                                        <Col span={4} className="center" >
                                                            <Text className="text-gray" ellipsis >Monto Total</Text>
                                                        </Col>
                                                        <Col span={4} className="center" >
                                                            <Text className="text-gray" ellipsis >Archivos</Text>
                                                        </Col>
                                                    </Row>
                                                }
                                                renderItem={item => (
                                                    <List.Item>
                                                        <FormRequisicionOpcion 
                                                            {...item} 
                                                            volumen={volumen} 
                                                            update={this.updateRequisicionOpcion}
                                                            aceptarOpcion={this.aceptarOpcion}
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        </Panel>
                                    </Collapse>
                                </List.Item>
                            )}
                        />

                    </Panel>
                </Collapse>

                <ModalProductoProveedor
                    visible={this.state.modal_visible}
                    onClose={()=>{
                        this.setState({modal_visible: false, requisicion_id: undefined, index_requisicion: undefined})
                    }}
                    requisicion_id={this.state.requisicion_id}
                    addOpcion={this.addOpcion}
                />
            </>
        )
    }
}
