import React, { Component } from 'react';
import { Avatar, Spin, PageHeader, Layout, message, List, Statistic, Row, Col, Card, Typography, Space, Button, Popconfirm, Tag, Empty, Popover } from 'antd'
import { useNavigate, useLocation, matchRoutes, Link } from "react-router-dom";

import {
    IconArrowBack, IconWalletBG,
    IconMoneyBG,
    IconHistorialBG,
    IconBilleteBG,
} from '../../Widgets/Iconos';
import FiltrosDrawer from '../../Widgets/DrawerFiltros/DrawerFiltros';
import { MoreOutlined, FileSearchOutlined, FilterOutlined } from "@ant-design/icons";
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import { color_transaccion_tipo } from "../../Utils"

import { CardIconoMoney } from '../../Widgets/Cards';

import '../../../Styles/Modules/CRM/Transacciones.scss'

import useSearchParams from '../../../Hooks/SearchParams';

import axios from 'axios';
import ModalTransaccionProgramadaDetalle from '../Finanzas/Transacciones/ModalTransaccionProgramadaDetalle';
const { Content } = Layout;
const { Text, } = Typography;


const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class TransaccionesProgramadasLista
 * @extends {Component}
 * @description Vista de dashboard de transacciones programadas
 */
class TransaccionesProgramadasLista extends Component {

    constructor(props) {
        super(props)
        let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            loading: false,
            transacciones: {

                page: page ?? 1,
                limit: limit ?? 10,

                total: 0,
                pages: 0,

                search: search ?? undefined,

                filters: filters ?? [],
                sort: sort ?? {},

                vencido: 0,
                proximo: 0,
                recibido: 0,
                por_recibir: 0
            }
        }
    }

    componentDidMount() {
        this.getTransacciones()
        this.getInfo()
        this.get()
    }

    /**
    *
    *
    * @memberof TransaccionesProgramadasLista
    * @description Obtiene todas todas las transacciones
    */
    getTransacciones = ({
        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,
        search = this.props.search,
        filters = this.state.transacciones.filters,
        sort = this.state.transacciones.sort,

    } = this.state.transacciones) => {

        const params = { page, limit, }
        if (search) params.search = search
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)
        this.props.setParams(params)

        this.setState({ transacciones: { ...this.state.transacciones, page, limit, search, filters, sort }, loading: true })
        axios.get('/transacciones-programadas', {
            params: {
                page,
                limit,
                search,
                anaitycs: true,
                filters,
            }
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ transacciones: { ...this.state.transacciones, ...data } });
            })
            .catch(error => {
                message.error('Error al traer los transacciones programadas')
                console.log(error.response)
            }
            ).finally(() => this.setState({ loading: false }))
    }


    getInfo = () => {
        console.log("getInfo");
        axios.get("/transacciones-programada/dashboard/data", {
            params: {}
        })
            .then(response => {
                console.log("response", response.data);
                this.setState({
                    ...response.data
                })

            }).catch(error => {
                console.log("error", error);

            })
    }

    get = () => {
        this.setState({ loading: true })
        axios.get('/transacciones-programadas/dashboard')
            .then(({ data }) => {
                console.log("data", data);
                this.setState({ ...data });
            })
            .catch(error => {
                message.error('Error al traer los transacciones programadas')
                console.log(error.response)
            }
            ).finally(() => this.setState({ loading: false }))
    }


    renderEstatus = (abono) => {
        if (!abono || abono == null) return;
        if (abono.monto == abono.monto_pagado)
            return <Tag color="#87d068">{moment(abono.fecha_corte).format('DD/MM/YYYY')}</Tag>

        if (moment(abono?.fecha_corte).isBefore(moment()))
            return <Tag color="#F50">{moment(abono?.fecha_corte).format('DD/MM/YYYY')}</Tag>
        else if (moment().isBetween(moment(abono?.fecha_corte).subtract(abono?.dias_limite, "days"), moment(abono?.fecha_corte)))
            return <Tag color="#FBBC30">{moment(abono?.fecha_corte).format('DD/MM/YYYY')}</Tag>
        else
            return <Tag color="#87d068">{moment(abono?.fecha_corte).format('DD/MM/YYYY')}</Tag>
    }


    render() {


        // vencido: 0,
        // proximo: 0,
        // recibido: 0,
        // por_recibir: 0,

        return (
            <Spin spinning={false}>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title="Cash Flow"
                    extra={
						<Button
							type="dashed"
							onClick={() => this.setState({ visibleFilters: true })}
							className="button-plus"
							icon={<FilterOutlined />} />
					}
                />
                <Content className="admin-content content-bg pd-1 ">
                    <Row gutter={[16, 16]} style={{ marginBottom: "2em" }}>
                        <Col span={6}>
                            <CardIconoMoney
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                title="Vencidos"
                                icon={<IconWalletBG />}

                                // ingreso_vencido: vencidos ? vencidos.ingreso : 0,
                                // egreso_vencido: vencidos ? vencidos.egreso : 0,
                                first_subtitle="Pagos Vencidos"
                                second_subtitle="Cobros Vencidos"
                                first={this.state.egreso_vencido}
                                second={this.state.ingreso_vencido}
                            />
                        </Col>
                        <Col span={6}>
                            <CardIconoMoney
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                title="Por Vencer"
                                // monto={1254.25}
                                first_subtitle="Pagos por Vencer"
                                second_subtitle="Cobros por Vencer"
                                first={this.state.egreso_por_vencer}
                                second={this.state.ingreso_por_vencer}

                                icon={<IconMoneyBG />}
                            />

                        </Col>
                        <Col span={6}>
                            <CardIconoMoney
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                // title="Pagos Recibidos"
                                // // monto={1254.25}
                                // monto={this.state.recibido?.toMoney()}

                                title="Recibidos"
                                // monto={1254.25}
                                first_subtitle="Pagos Recibidos"
                                second_subtitle="Cobros Recibidos"
                                first={this.state.ingreso_recibido}
                                second={this.state.egreso_recibido}


                                icon={<IconHistorialBG />}
                            />

                        </Col>
                        <Col span={6}>
                            <CardIconoMoney
                                extra={<MoreOutlined style={{ transform: 'rotate(90deg)' }} />}
                                
                                title="Por Recibir"
                                first_subtitle="Pagos por Recibir"
                                second_subtitle="Cobros por Recibir"
                                first={this.state.ingreso_por_recibir}
                                second={this.state.egreso_por_recibir}
                                icon={<IconBilleteBG />}
                            />
                        </Col>
                    </Row>
                    <Row className='width-100 '>
                        <List
                            loading={this.state.loading}
                            className="component-list width-100 "
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Transacciones" }}
                            dataSource={this.state.transacciones?.data}
                            pagination={{
                                current: this.state.transacciones.page,
                                pageSize: this.state.transacciones.limit,
                                total: this.state.transacciones.total,
                                position: 'bottom',
                                className: "flex-right",
                                onChange: (page, limit) => this.getTransacciones({ page, limit })
                            }}
                            header={<Row className="header-list width-100 ">
                                <Col xs={3} className=" center">
                                    <Text strong >PROXIMO ABONO</Text>
                                </Col>
                                <Col xs={3} className=" center">
                                    <Text strong >FECHA DE PAGO PROXIMA</Text>
                                </Col>
                                <Col xs={4} className="center">
                                    <Text strong >CONCEPTO</Text>
                                </Col>
                                <Col xs={3} className="center">
                                    <Text strong >ÁREA/RUBRO</Text>
                                </Col>
                                <Col xs={3} className="center">
                                    <Text strong >PROYECTO</Text>
                                </Col>
                                <Col xs={3} className=" center">
                                    <Text strong >CUENTA</Text>
                                </Col>
                                <Col xs={3} className="center">
                                    <Text strong >MONTO</Text>
                                </Col>
                                <Col span={2}></Col>
                            </Row>
                            }
                            renderItem={item => (
                                <List.Item className="component-list-item " style={{ position: "relative" }}>
                                    <Card className="card-list" bordered={false}>
                                        <span style={{ position: "absolute", height: "100%", width: 10, left: 0, top: 0, background: (item.tipo == 1) ? "#2DDA" : "#FF547D", borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}></span>
                                        <Row className="width-100" >
                                            <Col xs={3} className="center">
                                                {item?.proximo_abono ? <i>{item?.proximo_abono?.monto_pagado.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} / <strong>{item?.proximo_abono?.monto.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong></i> : item.monto_pendiente.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                            </Col>
                                            <Col xs={3} className="center">
                                                {item?.proximo_abono ?
                                                    <Popover
                                                        content={<div>
                                                            <span>Fecha de Pago Limite:</span> <strong>{moment(item?.proximo_abono?.fecha_corte).format("LL")}</strong> <br />
                                                            <span>Cantidad Pendiente:</span> <strong>{item?.proximo_abono?.monto_pendiente?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong> <br />
                                                            <span>Cantidad Pagada:</span> <strong>{item?.proximo_abono?.monto_pagado?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong> <br />
                                                            <span>Total Abono: </span><strong>{item?.proximo_abono?.monto?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</strong> <br />
                                                        </div>}
                                                    >
                                                        {this.renderEstatus(item?.proximo_abono)}
                                                    </Popover> : moment(item.fecha_limite).format("YYYY-MM-DD")}

                                            </Col>
                                            <Col xs={4} className="center">
                                                <Text ellipsis className="text-gray-dark">{item.concepto}</Text>
                                            </Col>
                                            <Col xs={3} className="center">
                                                <Space direction='vertical' size='small' className="space-small-vertical">
                                                    <Text ellipsis className="text-gray-dark">{item.area_id?.nombre ? item.area_id?.nombre : 'No Asignado'}</Text>
                                                    <Text ellipsis className="text-gray-dark">{item.rubro_id?.nombre ? item.rubro_id?.nombre : 'No Asignado'}</Text>
                                                </Space>
                                            </Col>
                                            <Col xs={3} className="center">
                                                <Space direction='horizontal' align='center' size='small'  >
                                                    <CustomAvatar
                                                        image={`${axios.defaults.baseURL}/upload/${item.proyecto_id?.logo}`}
                                                        color={item.proyecto_id?.color}
                                                        name={item.proyecto_id?.nombre}
                                                    />
                                                    <Text ellipsis>{item.proyecto_id.nombre ? item.proyecto_id.nombre : 'No Asignado'} </Text>
                                                </Space>
                                            </Col>
                                            <Col xs={3} className=" center">
                                                <Space direction='horizontal' align='center' size={0} >
                                                    <CustomAvatar
                                                        image={`${axios.defaults.baseURL}/upload/${item.cuenta_id?.logo}`}
                                                        color={item.cuenta_id?.color}
                                                        name={item.cuenta_id?.nombre}
                                                    />
                                                    <Text ellipsis className="text-gray-dark">{item.cuenta_id.nombre ? item.cuenta_id.nombre : 'No Asignado'}</Text>

                                                </Space>
                                            </Col>
                                            <Col xs={3} className="center">
                                                <Text className="text-gray-dark" strong>
                                                    $ {item.monto_pagado.toMoney(true)} <small>MXN</small> / $ {item.monto.toMoney(true)} <small>MXN</small>
                                                </Text>
                                            </Col>
                                            <Col span={2} className="center">
                                                <Space wrap>
                                                    <Button
                                                        icon={<FileSearchOutlined style={{ color: "currentcolor" }} />}
                                                        type="secondary"
                                                        title="Ver Detalle"
                                                        onClick={() => this.setState({ modal_visible: true, transaccion_id: item._id })}>
                                                    </Button>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Row>

                </Content>
                <ModalTransaccionProgramadaDetalle
                    visible={this.state.modal_visible}
                    transaccion_programada_id={this.state.transaccion_id}
                    onClose={() => {
                        this.setState({ modal_visible: false, transaccion_id: undefined })
                    }}
                />
                <FiltrosDrawer
					title="Filtrar Transacciones"
					ref={ref => this.drawerFiltros = ref}
					updateFilters={filters => this.getTransacciones({
						page: 1,
						filters
					})}
					// csv="transacciones"
					onClose={() => this.setState({ visibleFilters: false })}
					visible={this.state.visibleFilters}
					clientes={true}
					ordenes={false}
                    ordenes_compras={false}
					proveedores={false}
                    empresas={false}
					accredores={true}
					inversiones={true}
                    area_rubro={false}
                    cuentas={false}
				/>
            </Spin>
        )
    }
}

export default function () {
    const location = useLocation()
    const [params, setParams] = useSearchParams();

    return <TransaccionesProgramadasLista
        setParams={setParams}
        params={params}
        navigate={useNavigate()}
        location={location}
    />
}