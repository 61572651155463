import React from 'react'
import { Route, Routes } from "react-router-dom";

import Budgeting from '../components/Admin/Budgeting/Budgeting'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterBudgeting(props) {
    return (
        <Routes>
            <Route path="" element={<Budgeting {...props} />} />
        </Routes>
    )
}

export default RouterBudgeting;
