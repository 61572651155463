import React, { useState, useEffect } from 'react';
import { Avatar, Menu, Dropdown } from 'antd'
import { IconFlag } from '../Iconos'


let colors = {
    0: '#5151ec',
    1: '#ecea51',
    2: '#ec5151',
}

const PrioridadPicker = (props) => {

    const { value, onChange } = props

    const [prioridad, setPrioridad] = useState(value)

    const onChangePrioridad = (e) => {
        setPrioridad(e)
        onChange(e)
    }

    useEffect(() => {
        setPrioridad(value)
    }, [value])

    const menu = (
        <Menu onClick={value => onChangePrioridad(parseInt(value.key))}>
            <Menu.Item key={"0"}><IconFlag  fill={"#5151ec"}/> Sin Prioridad</Menu.Item>
            <Menu.Item key={"1"}><IconFlag  fill={"#ecea51"}/>Prioridad Media</Menu.Item>
            <Menu.Item key={"2"}><IconFlag  fill={"#ec5151"}/>Prioridad Maxima</Menu.Item>
        </Menu>
    )

    return (
        

        <Dropdown
            trigger={["click"]}
            overlay={menu}
         >
            <IconFlag className="hover" fill={colors[prioridad] ? colors[prioridad] : '#A4A4A4'}/>
        </Dropdown>

    );
}

export default PrioridadPicker;