import React, { Component } from "react";
import { Modal, Form, Input, message, Spin, InputNumber, Typography, Row, Col } from 'antd';
import { SelectProducto, SelectProyecto, SelectInmueble } from "../../Widgets/Inputs/Selects";
import PrioridadPicker from "../../Widgets/Inputs/PrioridadPicker";

const axios = require('axios').default;
const {Text} = Typography;
/**
 *
 *
 * @class ModalRequisiciones
 * @extends {Component}
 */
class ModalRequisiciones extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            requisicion: {}
        }
    }

    ModalRequisiciones = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if(this.props.requisicion_id){
            this.getRequisicion()
        }
    }

    onFinish = (values) => {
        this.entregaMaterieles(values)
    }


    /**
     *
     * @methodOf ModalRequisiciones
     * @function getRequisicion
     * @description Añade una nueva requisicion
     */
    getRequisicion = () => {
        this.setState({loading: true})
        axios.get('/requisiciones/'+this.props.requisicion_id,{
        }).then(response => {
                console.log("response.data", response.data);
            this.setState({
                requisicion:  response.data
            })
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener la requisición")
        }).finally(()=>{
            this.setState({loading: false})
        })
    }


    /**
     *
     * @methodOf ModalRequisiciones
     * @function entregaMaterieles
     * @description Entrega o Recolecta materiales de la requisicion
     */
    entregaMaterieles = (values) => {
        this.setState({loading: true})
        axios.post('/requisicion-opcion/entraga',{
            ...values,
            requisicion_id: this.props.requisicion_id
        }).then(response => {
            message.success(`Material ${this.props.estatus === 4 ? "Entregado" : "Recolectado"}`)
            this.props.onClose(true)
        }).catch(error => {
            console.log("error", error);
            
            message.error(error?.response?.data?.message ?? "Error al registrar el material")
        }).finally(()=>{
            this.setState({loading: false})
        })
    }


    render() {

        let { requisicion } = this.state
        let { estatus } = this.props

        return (
            <Form
                layout="vertical"
                name="form-requisiciones"
                ref={this.ModalRequisiciones}
                onFinish={this.onFinish}
                className="pd-1"
                initialValues={{
                    cantidad: 1,
                }}
            >
                <Spin spinning={this.state.loading}>
                    
                    <Row>
                        <Col span={12} className="mb-1">
                            <Text>Volumen Solicitado</Text>
                        </Col>
                        <Col span={12} className="flex-right mb-1">
                            <Text>{requisicion.volumen} {requisicion?.producto_id?.unidad?.nombre ?? "Unidades"}</Text>
                        </Col>
                        <Col span={12} className="mb-1">
                            <Text>Volumen {estatus === 4 ? "Entregado" : "Recolectado"}</Text>
                        </Col>
                        <Col span={12} className="flex-right mb-1">
                            <Text>{requisicion.volumen_entregado} {requisicion?.producto_id?.unidad?.nombre ?? "Unidades"}</Text>
                        </Col>
                    </Row>

                    <Form.Item
                        label="Cantidad"
                        name="cantidad"
                        rules={[
                            {
                                required: true,
                                message: "Por favor, ingrese la cantidad"
                            }
                        ]}
                    >
                        <InputNumber 
                            className="width-100"
                            max={requisicion.volumen - requisicion.volumen_entregado}
                            precision={0}
                            min={1}
                        />
                    </Form.Item>

                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, estatus } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={estatus === 4 ? "Entrega de Materiales" : "Recolección de Materiales"}
        closable={true}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Aceptar"
        okButtonProps={{ form: 'form-requisiciones', key: 'submit', htmlType: 'submit' }}
    >
        <ModalRequisiciones {...props} />
    </Modal>

}