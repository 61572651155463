import React, { Component, useContext } from "react";
import { Button, Col, Row, Typography, Card, List, Space, message, Popconfirm, Modal, Statistic, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, FilePdfOutlined, LinkOutlined } from "@ant-design/icons"
import axios from 'axios'


import { BiEnvelope } from "react-icons/bi"
import User from "../../../../Hooks/Logged";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import { IconCheckMedalColor } from '../../../Widgets/Iconos';
import usePermissions from "../../../../Hooks/usePermissions";
import ModalTransaccionProgramada from "../../Finanzas/Transacciones/ModalTransaccionProgramada";



const { Text } = Typography;
const moment = require('moment');

/**
 * @export
 * @class TransaccionesProgramadasTab
 * @extends {Component}
 * @description Lista de transacciones del cliente
 */
class TransaccionesProgramadasTab extends Component {

	constructor(props) {
		super(props)
		this.state = {
			proyecto: undefined,
			transacciones: {
				data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
			},
			acreedor_id: this.props.acreedor_id,
			transaccion_programada_id: undefined
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
		this.get()
	}

	componentDidUpdate(prevProps){
		if(prevProps.acreedor_id !== this.props.acreedor_id) this.get()
	}

	/**
	 * @memberof TransaccionesProgramadasTab
	 * @method get
	 * @description Obtiene los transacciones del cliente
	 *
	 */
	get = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
	} = this.state.transacciones, { acreedor_id } = this.props) => {
		this.setState({ loading: true })
		axios.get('/transacciones-programadas', {
			params: {
				acreedor_id: this.props.acreedor_id,
				page,
				limit,
			}
		})
			.then(({ data }) => {
				console.log("data", data);
				this.setState({ transacciones: { ...this.state.transacciones, ...data } });
			})
			.catch(error => {
				message.error('Error al traer los transacciones programadas')
				console.log(error.response)
			}
			).finally(() => this.setState({ loading: false }))
	}


	render() {

		const { data } = this.state.transacciones

		return (
			<>
				<Row gutter={[8, 8]}>
					<List
						itemLayout="horizontal"
						dataSource={data}
						loading={this.state.loading}
						key={(item => item._id)}
						className="component-list width-100"
						pagination={{
							current: this.state.transacciones.page,
							pageSize: this.state.transacciones.limit,
							total: this.state.transacciones.total,
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.get({ page, limit })
						}}
						renderItem={item => (
							<List.Item className="component-list-item transacciones">
								<Card className="ant-card-list width-100">
									<Row className="width-100" align={"middle"} gutter={[16, 16]}>
										<Col xs={12} md={2} className="center">
											<Text className="text-date-format fecha">{moment(item.fecha).format('DD-MM-YYYY')}</Text>
										</Col>
										<Col xs={12} md={4} className="center">
											<Text ellipsis className="text-gray-dark">{item.concepto}</Text>
										</Col>
										<Col xs={12} md={4}>
											<Space direction='vertical' size='small' className="space-small-vertical">
												<Text ellipsis className="text-gray-dark">{item.area_id?.nombre ? item.area_id?.nombre : 'No Asignado'}</Text>
												<Text ellipsis className="text-gray-dark">{item.rubro_id?.nombre ? item.rubro_id?.nombre : 'No Asignado'}</Text>
												<Text ellipsis className="text-gray-dark">{item.sub_rubro_id?.nombre ? item.sub_rubro_id?.nombre : 'No Asignado'}</Text>
											</Space>
										</Col>
										<Col xs={12} md={4} className="center">
											<Space direction='horizontal' align='center' size='small'  >
												<CustomAvatar
													image={item.proyecto_id?.logo}
													color={item.proyecto_id?.color}
													name={item.proyecto_id?.nombre}
												/>
												<Text ellipsis>{item?.proyecto_id?.nombre ? item?.proyecto_id?.nombre : 'No Asignado'} </Text>
											</Space>
										</Col>
										<Col xs={12} md={4} className=" center">
											<Space direction='horizontal' align='center' size={0} >
												<CustomAvatar
													image={item.cuenta_id?.logo}
													color={item.cuenta_id?.color}
													name={item.cuenta_id?.nombre}
												/>
												<Text ellipsis className="text-gray-dark">{item.cuenta_id.nombre ? item.cuenta_id.nombre : 'No Asignado'}</Text>

											</Space>
										</Col>
										<Col xs={12} md={3} className="center">
											<Text className="text-gray-dark" strong>
												$ {item.monto.toMoney(true)} <small>MXN</small></Text>
										</Col>
										<Col xs={12} md={1} className="center">
											<Button
                                                className={`btn-permission ${item.monto === item.monto_pagado ? 'active' : ''}`}
                                                type="ghost"
                                                icon={<IconCheckMedalColor/>}
                                            />
										</Col>
										<Col xs={24} md={2} align="center" className="">
											<Space direction="horizontal" size={5}>
												<Tooltip mouseEnterDelay={0} title="Editar la transacción programada" placement="rightTop">
													<Button
														size="small"
														type="primary"
														disabled={!this.props.editarTransaccionesProgramadasAcreedores}
														icon={<EditOutlined style={{ color: "currentcolor" }} />}
														onClick={() => this.setState({modalVisible: true, transaccion_programada_id: item._id})}
														/>
												</Tooltip>

												<Popconfirm
													placement="topRight"
													title="¿Deseas eliminar esta transacción programda?"
													onConfirm={() => axios.delete(`/transacciones-programadas`,
														{ params: { id: item._id } }).then(() => {
															this.get()
															message.success('Transacción programda eliminada')
															this.props.update()
														}).catch(error => console.log(error))}
													okText="Si"
													cancelText="No"
												>												
													<Button
														size="small"
														type="primary"
														disabled={!this.props.eliminarTransaccionesProgramadasAcreedores}
														icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
														title="Eliminar"
														danger
													/>
												</Popconfirm>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Row>

				<ModalTransaccionProgramada
					visible={this.state.modalVisible}
					onClose={() => {
						this.setState({modalVisible: false,  transaccion_programada_id: undefined})
						this.props.updateAll()
					}}
					acreedor_id={this.props.acreedor_id}
					transaccion_programada_id={this.state.transaccion_programada_id}
					area={true}
					rubro={true}
				/>
			</>
		)
	}
}

export default React.forwardRef((props, ref) => {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarTransaccionesProgramadasAcreedores: ["acreedores", "transacciones-programadas", "edit"],
        eliminarTransaccionesProgramadasAcreedores: ["acreedores", "transacciones-programadas", "delete"]
    });

	return <TransaccionesProgramadasTab ref={ref} {...props} user={user} {...permisos}/>
})