import React, { Component } from 'react';
import { Layout, Button, Col, Row, message, Card, PageHeader, List, Spin, Typography, Space, Popconfirm } from 'antd'
import { EyeOutlined, CheckOutlined, FilePdfOutlined } from "@ant-design/icons";
import Logged from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions'

//componentes
import axios from 'axios'
import FloatingButton from '../../Widgets/Floating Button/FloatingButton';
import { IconEdit, IconDelete } from '../../Widgets/Iconos';

//modales
//import ModalXML from './../Syncfy/ModalXML';
import ModalFacturas from './ModalFacturas';
//import ModalTimbrado from './ModalTimbrado';

import useSearchParams from '../../../Hooks/SearchParams';

const moment = require('moment');
const { Text } = Typography;
const { Content } = Layout;


/**
 *
 *
 * @export
 * @class FacturasSC
 * @extends {Component}
 */
class FacturasSC extends Component {

    constructor(props) {
        super(props)
        console.log("Params", this.props.params)
        let { page, limit, filters, search, sort } = this.props.params
        this.state = {

            facturas: {

				page: page ?? 1,
                limit: limit ?? 10,

                total: 0,
                pages: 0,

                search: search ?? undefined,

                filters: filters ?? [],
                sort: sort ?? {},

			},

            filtroSearch: '',
            searching: true,
        }
    }

    /**
     *
     *
     * @memberof FacturasSC
     * 
     * @method componentDidMount 
     * @description Cargamos la informacion de las facturas
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.log(this.props)
        this.getFacturas()
    }

    

    /**
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getFacturas();
            this.state.searching = false;
        }
    }


    /**
    * @memberof FacturasSC

    * @description  Lista las facturas de una cuenta, en un rango de fechas
    **/
    getFacturas = ({
		page = this.state.facturas.page,
		limit = this.state.facturas.limit,
		search = this.props.search,
		filters = this.state.facturas.filters,
		sort = this.state.facturas.sort,

	} = this.state.facturas) => {

        const params = { page, limit,  }
        if (search) params.search = search
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)
        this.props.setParams(params)

        this.setState({ transacciones: { ...this.state.facturas, page, limit, search, filters, sort }, loading: true })
        
        axios.get('/facturas', {
            params: {
                search,
                page,
            }
        })
            .then(response => {
                console.log('response getFacturas', response)

                this.setState({
					facturas: {
						...this.state.facturas,
						data: response.data.data,
						total: response.data.total,
						pages: response.data.pages,
						limit,
						page,
						loading: false
					},
					loading: false
				});

                
            })
            .catch(error => {
                message.error('Error al Cargar las facturas')
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }

    renderTipo = (value) => {
        switch (value) {
            case 1:
                return "Ingreso"
            case 0:
                return "Sin cambios"
            case 2:
                return "Egreso"
        }
    }


    /**
     *
     *
     * @param {*} item
     * @memberof FacturasSC
     * 
     * @description 
     */
    renderViews = (item) => {


        return <Space>
            <Button
                type="primary"
                icon={<IconEdit />}
                disabled={!this.props.editFacturas}
                title="Editar"
                onClick={() => { this.setState({ modalFacturasVisible: true, factura_id: item._id }) }}
            />
            <Button
                type="primary"
                icon={<EyeOutlined style={{ color: "currentColor" }} />}
                onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
                title="Ver XML"
                disabled={!item.factura_xml}
            ></Button>
            <Popconfirm
				placement="topRight"
				title="¿Deseas eliminar esta Factura?"
                
				onConfirm={() => axios.delete('/facturas', {params:{ id: item._id }}).then((response) => {
				    message.success('Factura Eliminada')
					this.getFacturas()
				}).catch((error) => {
					message.success('Factura NO Eliminada')
					this.getFacturas();
				    })
				}
				okText="Si"
				cancelText="No"
			>
				<Button type="primary" disabled={!this.props.deleteFacturas} danger icon={<IconDelete style={{ color: "currentcolor" }} />} title="Eliminar" />
			</Popconfirm>
            {/* <Button
                type="primary"
                icon={<FilePdfOutlined style={{ color: "currentColor" }} />}
                onClick={() => window.open(`${axios.defaults.baseURL}/facturas/cfdi?factura_id=${item._id}&Authorization=${sessionStorage.getItem("token")}`, '_blank')}
                title="Ver PDF"
                disabled={!(item.tipo_facturacion === 1)}
            ></Button>
            <Button
                disabled={typeof item?.factura_xml !== "string"}
                icon={<GoFileCode style={{ color: "currentColor", fontSize: 25, position: "relative", top: 5 }} />}
                type="primary"
                target={"_blank"}
                download={true}
                href={`${axios.defaults.baseURL}/facturas/cfdi/xml?factura_id=${item._id}&Authorization=${sessionStorage.getItem("token")}`} /> */}
        </Space>
        //     return <> 
        // </Button>
        // </>

        //     if (item.factura_xml) {
        //         return <> <Button
        //             type="primary"
        //             icon={<EyeOutlined style={{ color: "#fff" }} />}
        //             onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
        //             title="Ver XML" >
        //         </Button>
        //         </>
        //     } else {
        //         return <><Button
        //             type="primary"
        //             icon={<EyeOutlined />}
        //             disabled={true}
        //             title="Ver XML" />
        //         </>
        //     }
    }

    render() {
        const contentMenuPlus = (
            <div className="flex-column">
                <Button type="primary" className="width-100 mb-05" onClick={() => this.setState({ modalFacturasVisible: true })}>
                    Añadir Factura
                </Button>
                <Button type="primary" className="width-100" onClick={() => this.setState({ modalTimbradoVisible: true })}>
                    Timbrar Factura
                </Button>
            </div>
        );
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Facturas"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Facturas " }}
                            dataSource={this.state.facturas.data}
                            pagination={{
                                current: this.state.facturas.page,
                                pageSize: 10,
                                total: this.state.facturas.total,
                                position: 'bottom',
                                className: "flex-left",
                                onChange: (page) => this.getFacturas({page})
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >
                                <Col span={3} className="center">
                                    <Text ellipsis strong>Tipo</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text ellipsis strong>Descripción</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text ellipsis strong>Concepto</Text>
                                </Col>
                                <Col span={4} className="center">
                                    <Text ellipsis strong>Proveedor / Cliente</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text ellipsis strong>Fecha</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text ellipsis strong>Cantidad</Text>
                                </Col>
                                <Col span={3} className="center">
                                    <Text ellipsis strong>Acciones</Text>
                                </Col>
                            </Row>}
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <div className={`badge-card tipo-${item.tipo === 0 ? 3 : item.tipo === 1 ? 1 : 2}`}></div>
                                        <Row className="width-100 ">
                                            <Col span={3} className="center">
                                                <Text ellipsis>{this.renderTipo(item.tipo)}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                            <Text strong ellipsis>{item.descripcion}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                            <Text strong ellipsis>{item.concepto}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                            <Text strong ellipsis>{item.proveedor_id ? item.proveedor_id.alias : (item.cliente_id ? item.cliente_id.nombre : "")}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text ellipsis strong>{moment(item.fecha).format('DD-MM-YYYY')}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text ellipsis>$ {item.monto ? item.monto.toMoney(true) : '0.00'} <small>{item.moneda}</small></Text>
                                            </Col>
                                            <Col span={3} className="center">

                                                {this.renderViews(item)}

                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                        {this.props.createFacturas ? <FloatingButton
                            background="linear-gradient(126.63deg,#61f5c0 11.98%,#00ff38 83.35%)"
                            onClick={() => this.setState({ modalFacturasVisible: true })}
                            icon={<CheckOutlined style={{ fontSize: 50, color: "white" }} />}
                            title="Nuevo registro"
                            /* content={contentMenuPlus} */
                        /> : null}
                        
                    </Content>
                    {/* <ModalXML
                        visible={this.state.ModalXMLVisible}
                        onCancel={() => this.setState({ ModalXMLVisible: false, factura_xml_storage: undefined, xml: undefined })}
                        factura_xml_storage={this.state.factura_xml_storage}
                    //factura_xml = {this.state.factura_xml_storage}
                    /> */}
                    <ModalFacturas
                        visible={this.state.modalFacturasVisible}
                        onClose={() => {
                            this.setState({ modalFacturasVisible: false, factura_id: undefined })
                            this.getFacturas()
                        }}
                        factura_id={this.state.factura_id}
                    />
                    {/* <ModalTimbrado
                        visible={this.state.modalTimbradoVisible}
                        onClose={() => {
                            this.setState({ modalTimbradoVisible: false, factura_id: undefined })
                            this.getFacturas()
                        }}
                    /> */}
                </Spin>
            </>

        )
    }
}

export default function (props) {

	let user = React.useContext(Logged)

	console.log("user", user)
	let permissions = usePermissions(user?.rol_id?.permisos, {
		createFacturas: ["facturas", "create"],
		editFacturas: ["facturas", "edit"],
		readFacturas: ["facturas", "read"],
		deleteFacturas: ["facturas", "delete"],
	})

    const [params, setParams] = useSearchParams();


	return (<FacturasSC {...props} {...permissions} setParams={setParams} params={params}/>)
}