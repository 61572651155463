import React, { Component, useContext } from "react";
import { Button, Col, Row, Typography, Card, List, Space, message, Popconfirm, Modal, Tooltip } from "antd";
import axios from 'axios'
import { MdOutlineEmail, MdOutlineMarkEmailRead } from "react-icons/md"
import { DeleteOutlined, EditOutlined, EyeOutlined, FilePdfOutlined, LinkOutlined } from "@ant-design/icons"


//compoenentes
import User from "../../../../Hooks/Logged";
import { renderMontoTransaccion } from "../../../Utils";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import usePermissions from "../../../../Hooks/usePermissions";

//Modales
import ModalTransaccion from "../../Finanzas/Transacciones/ModalTransaccion";
import ModalTransaccionDetalle from "../../Finanzas/Transacciones/ModalTransaccionDetalle";
import ModalTransaccionProgramada from "../../Finanzas/Transacciones/ModalTransaccionProgramada";


const { Text } = Typography;
const moment = require('moment');

/**
 * @export
 * @class TransaccionesProgramadasTab
 * @extends {Component}
 * @description Lista de transacciones del cliente
 */
class TransaccionesProgramadasTab extends Component {

	constructor(props) {
		super(props)
		this.state = {
			transacciones: {
				data: [],
				limit: 10,
				page: 1,
				total: 0,
				pages: 0,
			},
			proveedor_id: this.props.proveedor_id,
			transaccion_id: undefined
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
		if(this.props.proveedor_id){
			this.getTransacciones()	
		} 
	}

	componentDidUpdate(prevProps){
		if(this.props.proveedor_id && prevProps.proveedor_id !== this.props.proveedor_id){
			this.getTransacciones()	
		} 
	}

	/**
	 * @memberof TransaccionesProgramadasTab
	 * @method getTransacciones
	 * @description Obtiene los transacciones realcionadas al proveedor
	 *
	 */
	getTransacciones = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
		proveedor_id = this.props.proveedor_id,
	} = this.state.transacciones) => {

		this.setState({ loading: true })
		axios.get('/transacciones-programadas', {
			params: {
				proveedor_id,
				page,
				limit
			}
		})
			.then(({ data }) => {
				this.setState({ 
					transacciones: {
						...data 
					} 
				});
			})
			.catch(error => {
				message.error(error.response.data.message + "(transacciones programadas)" ?? 'Error al traer las transacciones programadas')
				console.log(error.response)
			}
			).finally(() => this.setState({ loading: false }))
	}


	render() {

		const { data } = this.state.transacciones

		return (
			<>
				<Row gutter={[8, 8]}>
					<List
						itemLayout="horizontal"
						dataSource={data}
						loading={this.state.loading}
						key={(item => item._id)}
						className="component-list width-100"
						pagination={{
							current: this.state.transacciones.page,
							pageSize: this.state.transacciones.limit,
							total: this.state.transacciones.total,
							position: 'bottom',
							className: "flex-left",
							onChange: (page, limit) => this.get({ page, limit })
						}}
						renderItem={item => (
							<List.Item className="component-list-item transacciones">
								<Card className="ant-card-list width-100">
									<Row className="width-100" gutter={[16, 16]}>
										<Col xs={24} lg={2} className="center ">
											<Text className="text-date-format fecha">{moment(item.fecha).format('DD-MM-YYYY')}</Text>
										</Col>
										<Col xs={24} lg={4} className="center ">
											<Text className="concepto">{item.folio}</Text>
										</Col>
										<Col xs={24} lg={4} className="center ">
											<Text className="concepto">{item.concepto}</Text>
										</Col>
										<Col xs={24} lg={3} className="center ">
											<CustomAvatar
												image={axios.defaults.baseURL+"/upload/"+ item.cuenta_id?.logo}
												color={item.cuenta_id?.color}
												name={item.cuenta_id?.nombre}
											/>
											<Text ellipsis className="concepto">{item.cuenta_id?.nombre ?? ((item.tipo_pago == 2) ? "Especie" : null)}</Text>
										</Col>
										<Col xs={24} lg={3} className="center ">
											<CustomAvatar
												image={axios.defaults.baseURL+"/upload/"+ item.proyecto_id?.logo}
												color={item.proyecto_id?.color}
												name={item.proyecto_id?.nombre}
											/>
											<Text ellipsis className="concepto">{item.proyecto_id?.nombre}</Text>
										</Col>
										<Col xs={24} lg={4} className="center ">
											{renderMontoTransaccion({tipo: item.tipo, monto: item.monto})}
										</Col>
										<Col xs={24} lg={4} className="center">
											<Space direction="horizontal" size={5}>
												
												<Tooltip mouseEnterDelay={0} title="Editar la transacción programada" placement="rightTop">
													<Button
														size="small"
														type="primary"
														disabled={!this.props.editarTransaccionesProgramadas}
														icon={<EditOutlined style={{ color: "currentcolor" }} />}
														onClick={() => this.props.editTransaccion({transaccion_programada_id: item._id})}
														/>
												</Tooltip>

												<Popconfirm
													placement="topRight"
													title="¿Deseas eliminar esta transacción programda?"
													onConfirm={() => axios.delete(`/transacciones-programadas`,
														{ params: { id: item._id } }).then(() => {

															message.success('Transacción programda eliminada')
															this.props.updateAll()
														}).catch(error => {
															message.error(error?.response?.data.message ?? "Error al eliminar la transacción programada")
															console.log(error)
														})}
													okText="Si"
													cancelText="No"
												>												
													<Button
														size="small"
														type="primary"
														disabled={!this.props.eliminarTransaccionesProgramadas}
														icon={<DeleteOutlined style={{ color: "currentcolor" }} />}
														title="Eliminar"
														danger
													/>
												</Popconfirm>
											</Space>
										</Col>
									</Row>
								</Card>
							</List.Item>
						)}
					/>
				</Row>

				{/*Modal solo para editar*/}
				<ModalTransaccionProgramada
					visible={this.state.modalVisible}
					onClose={() => {
						this.setState({modalVisible: false, transaccion_id: undefined})
						this.props.updateAll()
					}}
					transaccion_id={this.state.transaccion_id}
					proveedor_id={this.props.proveedor_id}
					area={false}
					rubro={false}
					cliente={false}
					propiedad={false}
					factura={null}
					proyecto={false}
					tipo={false}
					inversion={false}
					acreedor={false}
					transaccion_programada={false}
				/>
				<ModalTransaccionDetalle
					visible={this.state.modalDetalleVisible}
					onClose={() => this.setState({modalDetalleVisible: false, transaccion_id: undefined})}
					transaccion_id={this.state.transaccion_id}
				/>
			</>
		)
	}
}

export default React.forwardRef((props, ref) => {

    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {

		desvincularTransaccionesInversiones: ["proveedores", "transacciones-programadas", "unlink"],
        editarTransaccionesProgramadas: ["proveedores", "transacciones-programadas", "edit"],
        eliminarTransaccionesProgramadas: ["proveedores", "transacciones-programadas", "delete"]
    });

	return <TransaccionesProgramadasTab ref={ref} {...props} user={user} {...permisos}/>
})