import React, { Component } from 'react';
import { Card, Layout, Modal, Form, Input, message, Spin, Row, Col, Typography, Tag, Space, Tooltip, InputNumber, Avatar } from 'antd'
import axios from 'axios';
import CustomAvatar from '../../../Widgets/Avatar/Avatar';


const { Content } = Layout;
const { Title, Text } = Typography;

export class CardAlmacen extends Component {

    constructor(props) {
        super(props);
        this.state={
            loading: false
        }
    }


    render() {
        return (
            <>
                <Card className={`card-shadow ${this.props.className}`} loading={this.state.loading}>
                    <Row align='middle' className='mb-2'>
                        <Text className='card-title'>Almacén</Text>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6} className="flex-left-column">
                            <Text className='sub-title'>Nombre</Text>
                            <Text className='title'>{this.props.almacen?.nombre}</Text>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6} className="flex-left-column">
                            <Text className='sub-title'>Proyecto</Text>
                            
                            <Space direction='horizontal' align='center' size='small'  >
                                {
                                    this.props.almacen?.proyecto_id && (
                                        <CustomAvatar
                                            url={{
                                                url: `${axios.defaults.baseURL}/proyectos/logo/${this.props.almacen?.proyecto_id?._id}`,
                                                name: this.props.almacen?.proyecto_id?.logo?.name
                                            }}
                                           
                                            name={this.props.almacen?.proyecto_id?.nombre}
                                            color={this.props.almacen?.proyecto_id?.color}
                                        />
                                    )
                                }
                                <Text className='title ml-1'>{this.props.almacen?.proyecto_id?.nombre ? this.props.almacen?.proyecto_id.nombre : 'No Asignado'}</Text>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6} className="flex-left-column">
                            <Text className='sub-title'> Actual en almacen</Text>
                            <Text className='title'>$ {this.props.almacen?.total?.toMoney(true)} MXN</Text>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6} className="flex-left-column">
                            <Text className='sub-title'>Cantidad de producto</Text>
                            <Text className='title'>{this.props.almacen?.productos_total?.toMoney(true)}</Text>
                        </Col>
                    </Row>
                </Card>
            </>
        )
    }
}



export default function (props) {

    return <CardAlmacen {...props} />
}