import React, { Component } from "react";
import { Row, Col, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Card, Typography, Collapse, Divider, } from "antd";
import axios from "axios";
import { IconDelete, IconEdit, IconArrowBack } from "../../Widgets/Iconos";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from "react-router-dom";
//Componentes
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import Logged from '../../../Hooks/Logged'
import usePermissions from "../../../Hooks/usePermissions";
//Modales
import ModalConcepto from './ModalConcepto'
import ModalSubConcepto from './ModalSubConcepto'

const { Content } = Layout;
const { Text } = Typography;
const { Panel } = Collapse;

/**
 * @export
 * @class Conceptos
 * @extends {Component}
 * @description Vista de Conceptos
 */
class Conceptos extends Component {
    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            modalVisible: false,
            concepto_id: undefined,
            subconcepto_id: undefined,
            proyecto_id: this.props.params.proyecto_id,
            conceptos: {
                data: [],
                limit: 20,
                page: 1,
                total: 0,
                pages: 0,
            },
        };
    }

    componentDidMount() {

        console.log(this.props)

        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem("token");

        this.getConceptos()
    }

    /**
     * @memberof Conceptos
     * @method getConceptos
     * @description Obtiene la informacion de los Usuarios y actualiza los valores locales de la lista.
     */
    getConceptos = async ({
        page = this.state.conceptos.page,
        limit = this.state.conceptos.limit,
        search = this.props.search
    } = this.state.conceptos) => {

        this.setState({ loading: true})

        axios.get('/conceptos', {
            params: { 
                limit, 
                page, 
                search,
                conceptos: true,
                proyecto_id: this.props.params.proyecto_id,
            }
        })
            .then(({ data }) => {
                this.setState({ conceptos: data })
            })
            .catch(error => {
                console.log("error", error);
                message.error(error.response?.data?.message)
            })
            .finally(() => this.setState({ loading: false }))
    }



    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Conceptos"
                        backIcon={<IconArrowBack/>}
                        onBack={(e) => this.props.navigate(-1)}
                    />

                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            dataSource={this.state.conceptos.data}
                            locale={{ emptyText: "Sin Conceptos" }}
                            pagination={{
                                onChange: (page, limit) => {
                                    this.getConceptos({ page, limit })
                                },
                                className: 'flex-left',
                                showSizeChanger: true,
                                total: this.state.conceptos.total,
                                pageSize: this.state.conceptos.limit,
                                current: this.state.conceptos.page,
                            }}
                            renderItem={item => {

                                return <Collapse
                                    bordered={false}
                                    defaultActiveKey={['1']}
                                    expandIcon={({ isActive }) => isActive ? <MinusOutlined style={{ marginTop: 8, color: '#ffffff', fontSize: 16 }} /> :
                                        <PlusOutlined style={{ marginTop: 8, color: '#ffffff', fontSize: 16 }} />}
                                    className=""
                                >
                                    <Panel
                                        className="collapse-list"
                                        extra={
                                            <Row className="header-collapse">

                                                <Col xs={24} md={10} className="flex-left">
                                                    <Text ellipsis>{item.nombre}</Text>
                                                </Col>

                                                <Col xs={24} md={10} className="center">
                                                    <Text ellipsis>$ {item.presupuesto ? item.presupuesto.toMoney(true) : 0.00} MXN</Text>
                                                </Col>

                                        
                                                <Col xs={24} md={4} className="flex-right">
                                                    <Space align="end">
                                                        <Button
                                                            className="btn btn-edit"
                                                            disabled = {!this.props.createConceptos}
                                                            title="Añadir Sub-concepto"
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                this.setState({ concepto_id: item._id, modalsubConcepto: true })}
                                                            }
                                                            icon={<PlusOutlined/>}
                                                        />
                                                        <Button 
                                                            title="Editar"
                                                            disabled = {!this.props.editConceptos} 
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                this.setState({ concepto_id: item._id, modalVisible: true })}
                                                            }
                                                            icon={<IconEdit />}
                                                        />
                                                        <Popconfirm
                                                            placement="topRight"
                                                            disabled = {!this.props.deleteConceptos}
                                                            title="¿Deseas eliminar este Concepto?"
                                                            onConfirm={() => axios.delete(`/concepto/${item._id}`).then((response) => {
                                                                    message.success('¡Concepto eliminado!')
                                                                    this.getConceptos()
                                                                }).catch((error) => {
                                                                    console.log("error", error);
                                                                    if(error.response?.status === 403)
                                                                        message.error(error.response.data.message)
                                                                    else
                                                                        message.error('Concepto no eliminado')
                                                                })
                                                            }
                                                            okText="Si"
                                                            cancelText="No"
                                                        >
                                                            <Button
                                                                type="primary"
                                                                disabled = {!this.props.deleteConceptos}
                                                                danger
                                                                icon={<IconDelete />} 
                                                                title="Eliminar"
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                }}
                                                            />
                                                        </Popconfirm>
                                                    </Space>
                                                </Col>

                                            </Row>
                                        }
                                    >



                                        {
                                            item.subconceptos.map((subconcepto) => {

                                                const { nombre, presupuesto, concepto_id, _id } = subconcepto;

                                                return (
                                                    <Row className="mb-1 border-bottom">
                                                        <Col xs={24} md={10} className="flex-left">
                                                            <Text ellipsis>{nombre}</Text>
                                                        </Col>

                                                        <Col xs={24} md={10} className="center">
                                                            <Text ellipsis>$ {presupuesto ? presupuesto.toMoney(true) : 0.00} MXN</Text>
                                                        </Col>
                                                        <Col xs={24} md={4} className="flex-right" >
                                                            <Space align="center">
                                                                <Button
                                                                    type="primary"
                                                                    disabled = {!this.props.editConceptos}
                                                                    title="Editar"
                                                                    onClick={(e) => this.setState({ subconcepto_id: _id, concepto_id: item._id, modalsubConcepto: true })}
                                                                    icon={<IconEdit />}
                                                                />
                                                                <Popconfirm
                                                                    placement="topRight"
                                                                    disabled = {!this.props.deleteConceptos}
                                                                    title="¿Deseas eliminar este Subconcepto?"
                                                                    onConfirm={() => axios.delete(`/subconcepto/${_id}`).then((response) => {
                                                                            message.success('¡Subconcepto eliminado!')
                                                                            this.getConceptos()
                                                                        }).catch((error) => {
                                                                            console.log("error", error);
                                                                            if(error.response?.status === 403)
                                                                                message.error(error.response.data.message)
                                                                            else
                                                                                message.error('Suboncepto no eliminado')
                                                                        })
                                                                    }
                                                                    okText="Si"
                                                                    cancelText="No"
                                                                >
                                                                    <Button
                                                                        type="primary"
                                                                        danger
                                                                        disabled = {!this.props.deleteConceptos}
                                                                        icon={<IconDelete />} 
                                                                        title="Eliminar"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                        }}
                                                                    />
                                                                </Popconfirm>
                                                            </Space>
                                                                
                                                        </Col>

                                                    </Row>
                                                )
                                            })
                                        }
                                    </Panel>
                                </Collapse>

                            }}
                        />
                    </Content>

                    {this.props.createConceptos ? <FloatingButton
                        title="Nuevo registro"
                        onClick={() => this.setState({ modalVisible: true })}
                    /> : null}
                    <ModalConcepto
                        visible={this.state.modalVisible}
                        onClose={(flag)=>{
                            this.setState({modalVisible: false, concepto_id: undefined})
                            if(flag === true)
                                this.getConceptos()
                        }}
                        concepto_id={this.state.concepto_id}
                        proyecto_id={this.state.proyecto_id}
                    />

                    <ModalSubConcepto
                        visible={this.state.modalsubConcepto}
                        onClose={(flag)=>{
                            this.setState({modalsubConcepto: false, concepto_id: undefined, subconcepto_id: undefined})
                            if(flag === true)
                                this.getConceptos()
                        }}
                        concepto_id={this.state.concepto_id}
                        subconcepto_id={this.state.subconcepto_id}
                        proyecto_id={this.state.proyecto_id}
                    />
                </Spin>
            </>
        );
    }
}

export default function Vista(props) {

    let user = React.useContext(Logged)

    
	let permissions = usePermissions(user?.rol_id?.permisos, {
		createConceptos: ["proyectos","conceptos","create"],
		editConceptos: ["proyectos","conceptos","edit"],		
		deleteConceptos: ["proyectos","conceptos" ,"delete"],
	})

    console.log(permissions)

    return <Conceptos {...props} params={useParams()} {...permissions} navigate={useNavigate()} />

}
